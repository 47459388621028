import React, { useState, useEffect } from 'react';
import { TextField, Grid, Box, Typography, Container, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import ContactUsModal from '@/components/contact-us-modal/ContactUsModal';
import { useAPI } from '@/api/APIContext';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';

export default function PhoneAuthentication() {
  const navigate = useNavigate();
  const theme = useTheme();
  const api = useAPI();
  const activeUser = useActiveUser();

  const [digits, setDigits] = useState(['', '', '', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const handleContactImportal = () => {
    setOpen(true);
  };

  const handleContactImportalClose = () => {
    setOpen(false);
  };

  const authenticateUserCode = (code: string) => {
    // Quick validity check
    if (isNaN(Number(code))) {
      alert('Please enter a valid code (digits only)!');
      console.error(`${code} is not a number!`);
      return;
    }

    handleLoading(true);

    api
      .submitAuthenticationCode({ authCode: code })
      .then(async (response) => {
        datadogRum.addAction('validate-authentication-code', {});
        api.setToken(response.data.token);

        await activeUser.refreshUser();
        handleLoading(false);
        navigate('/dashboard');
      })
      .catch((error) => {
        console.error('Error submitting auth code:', error);
        handleLoading(false);
      });
  };

  const autoTab = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, nextId: string) => {
    if (e.target.value.length === 1) {
      const nextInput = document.getElementById(nextId) as HTMLInputElement | null;
      nextInput?.focus();
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // gather digits from form
    const code = Array.from({ length: 5 }, (_, i) => data.get(`digit-${i}`) || '').join('');
    authenticateUserCode(code);
  };

  // Paste listener
  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      event.preventDefault();
      const pasteCode = event.clipboardData ? event.clipboardData.getData('text') : '';
      const newDigits = pasteCode.split('').slice(0, 5);

      setDigits(newDigits);

      newDigits.forEach((digit, index) => {
        const inputElement = document.getElementById(`digit-${index}`) as HTMLInputElement | null;
        if (inputElement) {
          inputElement.value = digit;
        }
      });
    };

    window.addEventListener('paste', handlePaste as EventListener);
    return () => {
      window.removeEventListener('paste', handlePaste as EventListener);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: 'black',
      }}
    >
      {/* LEFT SECTION (Form) */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src="IMPORTAL-FUTURE.png" width="218" height="38" alt="logo" />
        </Container>

        <Box
          sx={{
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            // match the 22% padding in your original code, but shrink for smaller screens:
            pr: '22%',
            pl: '22%',
            color: 'white',
            [theme.breakpoints.down('md')]: {
              pr: '8%',
              pl: '8%',
            },
            [theme.breakpoints.down('sm')]: {
              pr: '5%',
              pl: '5%',
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              mb: '20px',
              textAlign: 'center',
              lineHeight: 1.4,
            }}
          >
            An authentication code has been sent to your email.
          </Typography>

          {/* The form takes full width */}
          <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%', mb: 2 }}>
              {Array.from({ length: 5 }).map((_, index) => (
                <Grid
                  item
                  key={index}
                  sx={{
                    maxWidth: '65px',
                    width: '100%',
                  }}
                >
                  <TextField
                    fullWidth
                    required
                    name={`digit-${index}`}
                    id={`digit-${index}`}
                    type="text"
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: 'center', fontSize: '18px' },
                    }}
                    onChange={(e) => autoTab(e, `digit-${index + 1}`)}
                    autoFocus={index === 0}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '8px',
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <ImportalPrimaryButton
                text={isLoading ? '' : 'Submit'}
                type="submit"
                disabled={isLoading}
                style={{ width: '100%' }} // Full-width button
                onClick={() => handleSubmit}
              />
            </Box>
          </Box>

          <Box sx={{ mb: '20px', textAlign: 'center' }}>
            <Typography
              variant="body2"
              onClick={handleContactImportal}
              sx={{
                color: 'white',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Need Help? Give us a shout
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* RIGHT SECTION (Image) 
          Hides on smaller screens (xs or sm). */}
      <Box
        sx={{
          flex: 1,
          overflow: 'hidden',
          display: { xs: 'none', md: 'block' }, // Hide for xs/sm
        }}
      >
        <img
          src="cargo_in_ocean.png"
          alt="Cargo in ocean"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
          }}
        />
      </Box>

      <ContactUsModal open={open} onClose={handleContactImportalClose} />
    </div>
  );
}
