import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Fab, IconButton, useTheme } from '@mui/material';
import './FullOnboarding.css';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Person3Icon from '@mui/icons-material/Person3';
import BusinessInfo from '../BusinessInfo/BusinessInfo';
import BusinessCustomsInfo from '../business-customs-info/BusinessCustomsInfo';
import AuthorizedSigner from '../authorized-signer/AuthorizedSigner';
import DocumentsAndSignature from '../documents-and-signature/DocumentsAndSignature';
import { useAPI } from '@/api/APIContext';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { CustomsOnboarding, CustomsOnboardingSteps } from 'common/interfaces/customsOnboarding';
import { useActiveCustomsOnboarding } from '@/custom-hooks/customs-onboarding/CustomsOnboardingProvider';
import { ImportalStepper, ImportalStepperHandle } from '@/shared-components/ImportalStepper/ImportalStepper';
import { AppContext } from '@/App';

interface FullOnboardingProps {
  isShowContactUs?: boolean;
  handleClose?: any;
}

export default function FullOnboarding({ isShowContactUs = true, handleClose }: FullOnboardingProps) {
  const { setOpenContactUs } = useContext(AppContext);

  const theme = useTheme();
  const api = useAPI();
  const activeCustomsOnboarding = useActiveCustomsOnboarding();

  const [isLoading, setIsLoading] = useState(false);
  const [customsOnboardingInfo, setCustomsOnboardingInfo] = useState<CustomsOnboarding>();
  const activeBusiness = useActiveBusiness();

  const stepperRef = useRef<ImportalStepperHandle>(null);

  useEffect(() => {
    // only make call to get customs onboarding if we have business and the customs onboarding hasn't loaded!
    if (activeBusiness.business?._id && !customsOnboardingInfo) {
      getCustomsOnboardingInfoForBusiness(activeBusiness.business?._id.toString());
    }
  }, [activeBusiness.business]);

  const mapCustomsOnboardingSteps = (customsOnboardingInfo) => {
    switch (customsOnboardingInfo.step) {
      case CustomsOnboardingSteps.BUSINESS_INFO:
        stepperRef.current?.setActive(0);
        break;
      case CustomsOnboardingSteps.AUTHORIZED_SIGNER:
        stepperRef.current?.setCompletedUpTo(1);
        stepperRef.current?.setActive(1);
        break;
      case CustomsOnboardingSteps.PHOTO_ID:
        stepperRef.current?.setCompletedUpTo(2);
        stepperRef.current?.setActive(2);
        break;
      case CustomsOnboardingSteps.SIGN_POA:
        stepperRef.current?.setCompletedUpTo(2);
        stepperRef.current?.setActive(2);
        break;
      case CustomsOnboardingSteps.CUSTOMS_INFO:
        stepperRef.current?.setCompletedUpTo(3);
        stepperRef.current?.setActive(3);
        break;
      default:
        break;
    }
  };

  const updateCustomsOnboarding = (businessId: string, customsOnboarding: any) => {
    api
      .updateCustomsOnboarding(businessId, customsOnboarding)
      .then((response) => {
        setCustomsOnboardingInfo(response.data);
        activeCustomsOnboarding.refreshCustomsOnboarding();
      })
      .catch((error) => {
        console.error('error updating customs onboarding for business');
        console.error(error);
      });
  };

  const getCustomsOnboardingInfoForBusiness = (businessId: string) => {
    api
      .getCustomsOnboardingForBusiness(businessId)
      .then((response) => {
        mapCustomsOnboardingSteps(response.data);
        setCustomsOnboardingInfo(response.data);
      })
      .catch((error) => {
        console.error('error getting customs onboarding for business');
        console.error(error);
      });
  };

  return (
    <div className="onboarding-container">
      <div>
        <IconButton
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>
        <div className="my-shipments-header">Customs Onboarding</div>
        <div className="my-shipments-subheader" style={{ marginBottom: '20px' }}>
          Fill out these four quick steps and we'll get to clearing your customs business.
        </div>
        <ImportalStepper
          ref={stepperRef}
          showLoadingSpinner={isLoading}
          isClickable={true}
          steps={[
            {
              icon: <BusinessIcon />,
              label: 'Business Info',
              element: (activeStep, next, back) => (
                <div className="step-component-bi-container">
                  <BusinessInfo
                    handleEvent={next}
                    handleLoading={setIsLoading}
                    updateCustomsOnboarding={updateCustomsOnboarding}
                    customsOnboarding={customsOnboardingInfo}
                  />
                </div>
              ),
            },
            {
              icon: <Person3Icon />,
              label: 'Authorized Signer',
              element: (activeStep, next, back) => (
                <div className="step-component-bi-container">
                  <AuthorizedSigner
                    handleEvent={next}
                    handleBack={back}
                    handleClose={handleClose}
                    handleLoading={setIsLoading}
                    updateCustomsOnboarding={updateCustomsOnboarding}
                    customsOnboarding={customsOnboardingInfo}
                  />
                </div>
              ),
            },
            {
              icon: <HistoryEduIcon />,
              label: 'Photo ID & Signature',
              element: (activeStep, next, back) => (
                <div className="step-component-bi-container">
                  <DocumentsAndSignature
                    handleEvent={next}
                    handleBack={back}
                    handleLoading={setIsLoading}
                    updateCustomsOnboarding={updateCustomsOnboarding}
                    customsOnboarding={customsOnboardingInfo}
                  />
                </div>
              ),
            },
            {
              icon: <AssuredWorkloadIcon />,
              label: 'Customs Info',
              element: (activeStep, next, back) => (
                <div className="step-component-bi-container">
                  <BusinessCustomsInfo
                    handleEvent={next}
                    handleBack={back}
                    handleLoading={setIsLoading}
                    updateCustomsOnboarding={updateCustomsOnboarding}
                    customsOnboarding={customsOnboardingInfo}
                  />
                  ,
                </div>
              ),
            },
          ]}
          stepperCompletedElement={
            <>
              <div className="customs-onboarding-confirmation">
                <div className="confirmation-title">You're all set!</div>
                <div className="confirmation-subtitle">
                  Thanks for customs onboarding with us. Our brokers will reach out with next steps.
                </div>
              </div>
              <div className="next-button-container">
                <Button
                  type="submit"
                  color="info"
                  size="medium"
                  onClick={handleClose}
                  variant="contained"
                  className="next-button-green"
                >
                  Close
                </Button>
              </div>
            </>
          }
        />
      </div>

      <div className="need-help">
        {isShowContactUs && (
          <Fab
            onClick={() => {
              setOpenContactUs(true);
            }}
            sx={{
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.contrastText,
              textTransform: 'none',
            }}
            variant="extended"
          >
            <ContactSupportIcon sx={{ mr: 1 }} />
            Need help? Reach out whenever
          </Fab>
        )}
      </div>
    </div>
  );
}
