import { ChartUnitType } from 'common/interfaces/reports';
import * as d3 from 'd3';
import { useState } from 'react';
import { ImportalChartTooltip } from './ImportalChartTooltip';

interface HoverState {
  index: number;
  x: number;
  y: number;
  label: string;
  value: number;
}

interface ImportalHorizontalBarsProps {
  data: any[];
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleBand<string>;
  seriesNameKey: string;
  seriesValueKey: string;
  barColor?: string;
  rounded?: boolean;
  unit: ChartUnitType;
}

/**
 * Generate a path for a rectangle with only the **right** corners rounded
 * (top-right, bottom-right).
 *
 * @param x The left x-coordinate of the bar
 * @param y The top y-coordinate of the bar
 * @param width The total width of the bar
 * @param height The total height of the bar
 * @param radius The radius for the right corners
 */
function getRoundedRightRectPath(x: number, y: number, width: number, height: number, radius: number): string {
  // Clamp the radius to at most half the bar's height
  const r = Math.min(radius, height / 2);
  return `
    M ${x} ${y}
    H ${x + width - r}
    Q ${x + width} ${y} ${x + width} ${y + r}
    V ${y + height - r}
    Q ${x + width} ${y + height} ${x + width - r} ${y + height}
    H ${x}
    Z
  `;
}

export function ImportalHorizontalBars({
  data,
  xScale,
  yScale,
  seriesNameKey,
  seriesValueKey,
  barColor = '#FFC107',
  rounded = false,
  unit,
}: ImportalHorizontalBarsProps) {
  const [hoverState, setHoverState] = useState<HoverState | null>(null);

  return (
    <g>
      {data.map((d: any, i: number) => {
        // Determine category (for the Y scale) and bar value
        const category = d[seriesNameKey];
        const value = d?.[0] !== undefined && d?.[1] !== undefined ? d[1] - d[0] : +d[seriesValueKey];

        // Compute the bar's position/size
        const barY = yScale(category) ?? 0;
        const barX = xScale(d[0] ?? 0);
        const barWidth = xScale(d[1] ?? value) - xScale(d[0] ?? 0);
        const barHeight = yScale.bandwidth();

        // Handle hover
        const handleMouseEnter = () => {
          // Position the tooltip slightly to the right of the bar, centered vertically
          setHoverState({
            index: i,
            x: barX + barWidth + 8,
            y: barY + barHeight / 2,
            label: category,
            value,
          });
        };
        const handleMouseLeave = () => setHoverState(null);

        return (
          <g key={i} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {rounded ? (
              // Use a <path> to round only the right corners
              <path d={getRoundedRightRectPath(barX, barY, barWidth, barHeight, 8)} fill={barColor} />
            ) : (
              // Otherwise, a normal <rect>
              <rect x={barX} y={barY} width={barWidth} height={barHeight} fill={barColor} />
            )}
          </g>
        );
      })}

      {/* Render the tooltip last so it appears on top of the bars */}
      {hoverState && (
        <ImportalChartTooltip
          x={hoverState.x}
          y={hoverState.y}
          label={hoverState.label}
          value={hoverState.value}
          unit={unit}
        />
      )}
    </g>
  );
}
