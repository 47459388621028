import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Card, CardContent, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { GoodsTotals } from 'common/interfaces/complianceSummary';
import { UnitOfMeasureForQuantityAllowedValues } from 'common/interfaces/productfields';

export type QuantityTotals = Omit<
  GoodsTotals,
  'totalValue' | 'totalValueCurrency' | 'totalWeight' | 'totalWeightUnits'
>;

interface Props {
  quantityInfo: QuantityTotals;
  onQuantityInfoSelected: (quantityInfo?: QuantityTotals) => void;
  onNext: () => void;
  onBack: () => void;
}

export default function QuantityInfo({ quantityInfo, onQuantityInfoSelected, onNext, onBack }: Props) {
  const theme = useTheme();

  const handleVariableAmount = (e) => {
    onQuantityInfoSelected({ ...quantityInfo, totalUnits: e.target.value });
  };

  const isNumber = (str) => /^[0-9]*$/.test(str);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputIsValid()) {
        onNext();
      }
    }
  };

  const inputIsValid = (): boolean => {
    return (
      !!(quantityInfo && quantityInfo.totalUnits && quantityInfo.totalUnitsMeasureOfQuantity) &&
      isNumber(quantityInfo.totalUnits)
    );
  };

  const toSentenceCase = (
    unitOfMeasureForQuantityAllowedValue: UnitOfMeasureForQuantityAllowedValues | undefined
  ): string => {
    if (!unitOfMeasureForQuantityAllowedValue) {
      return '';
    }
    return (
      unitOfMeasureForQuantityAllowedValue.charAt(0).toLowerCase() +
      unitOfMeasureForQuantityAllowedValue.slice(1).toLowerCase()
    );
  };

  return (
    <Card className="card-container">
      <CardContent>
        <div className="card-container">
          <div className="header-question">
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: '36px',
                marginTop: '15px',
              }}
            >
              What is the total quantity of your units?
            </Typography>
          </div>
          <div className="unlading-descriptive-text">
            Please enter the quantity of{' '}
            <b style={{ paddingLeft: '3px', paddingRight: '3px' }}>
              {toSentenceCase(quantityInfo?.totalUnitsMeasureOfQuantity)}
            </b>{' '}
            of your product.
          </div>
          <div className="variable-value-text-field">
            <TextField
              required
              fullWidth
              id="quantity-value"
              label=""
              onChange={handleVariableAmount}
              value={quantityInfo?.totalUnits || ''}
              name="quantity-value"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {toSentenceCase(quantityInfo?.totalUnitsMeasureOfQuantity)}
                  </InputAdornment>
                ),
              }}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          </div>
          <div className="next-button-container">
            <Button
              fullWidth
              className="back-button-text"
              startIcon={<KeyboardArrowLeftIcon />}
              variant="text"
              onClick={onBack}
            >
              Back
            </Button>
            <Button
              fullWidth
              onClick={onNext}
              className="next-button-green"
              endIcon={<KeyboardArrowRightIcon />}
              variant="contained"
              disabled={!inputIsValid()}
            >
              Next
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
