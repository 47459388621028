import { ChartConfig, ChartUnitType } from 'common/interfaces/reports';

export function renderChartLabel(value: number, unit: ChartUnitType): string {
  switch (unit) {
    case ChartUnitType.Currency:
      return `$${value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    case ChartUnitType.Percentage:
      return `${value.toFixed(2)}%`;
    case ChartUnitType.Number:
      return value.toLocaleString('en-US');
    default:
      return value.toString();
  }
}
