import EntryPrepDocumentsView, { DocumentReviewMode } from '@/broker-app/pages/shipments/EntryPrepDocumentsView';
import { useEventBus } from '@/custom-hooks/event-bus/EventBus';
import React, { useCallback, useEffect, useState } from 'react';
import { DataImportChangedEvent, EventType } from 'common/eventbus/eventBus';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { useAPI } from '@/api/APIContext';
import { EntryPrepDocForDisplay } from 'common/interfaces/documentParsing';
import { useMatch } from 'react-router';
import { ShipmentWithDocuments } from 'common/interfaces/shipment';
import ImportalSecondaryButton from '@/shared-components/ImportalSecondaryButton/ImportalSecondaryButton';

export default function ReviewParsedShipmentDocuments() {
  const api = useAPI();
  const [entryPrepDocs, setEntryPrepDocs] = useState<Array<EntryPrepDocForDisplay>>([]);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  const standaloneMatch = useMatch('/shipments/:shipmentId/entry-document-prep');
  const shipmentPageMatch = useMatch('/broker/dashboard/shipments/:shipmentId');
  const [shipment, setShipment] = useState<ShipmentWithDocuments | undefined>();

  useEffect(() => {
    if (standaloneMatch?.params.shipmentId) {
      api
        .getShipmentById(standaloneMatch?.params.shipmentId)
        .then(({ data }) => {
          setShipment(data);
        })
        .catch((err) => {
          console.error('error getting shipment for document review', err);
        });
    } else if (shipmentPageMatch?.params.shipmentId) {
      api
        .getShipmentById(shipmentPageMatch?.params.shipmentId)
        .then(({ data }) => {
          setShipment(data);
        })
        .catch((err) => {
          console.error('error getting shipment for document review', err);
        });
    }
  }, [standaloneMatch?.params.shipmentId]);

  const onClickParseAll = () => {
    if (!shipment) {
      console.error('somehow, shipment is not available via context');
      return;
    }
    api
      .parseAllShipmentDocuments(shipment._id!.toString())
      .then(refreshEntryPrepDocs)
      .catch((err) => {
        console.error('error sending all documents to be parsed');
      });
  };

  const eventBus = useEventBus();
  const handleEntryPrepDocChanged = useCallback(
    async (event: DataImportChangedEvent) => {
      // Check if the shipment already exists in the current list
      const entryPrepDocWithUpdate = entryPrepDocs.find(
        (entryPrepDoc) => entryPrepDoc.dataImport?._id.toString() === event.data.dataImportId
      );

      if (!entryPrepDocWithUpdate) return; // the data import changed event doesn't apply to any of the entry prep docs that we are looking at

      try {
        // Fetch the updated shipment data from the API
        const { data: updatedEntryPrepDoc } = await api.getEntryPrepDocById(entryPrepDocWithUpdate._id.toString());

        // Update the specific shipment in the state
        setEntryPrepDocs((prevEntryPrepDocs) => {
          return prevEntryPrepDocs.map((entryPrepDoc) =>
            entryPrepDoc._id.toString() === entryPrepDocWithUpdate._id.toString() ? updatedEntryPrepDoc : entryPrepDoc
          );
        });

        setLoadingStatus(LoadingStatus.SUCCESS);
        setSuccessText(`Parse for ${updatedEntryPrepDoc.document.fileName} was Updated`);
      } catch (error) {
        console.error('Error fetching updated entryPrepDoc:', error);
      }
    },
    [entryPrepDocs, api]
  );

  useEffect(() => {
    // Subscribe to the event
    // return the result for automatic cleanup the subscription when the component unmounts
    return eventBus.on(EventType.DATA_IMPORT_CHANGED, handleEntryPrepDocChanged);
  }, [eventBus, handleEntryPrepDocChanged]);

  const refreshEntryPrepDocs = (shipmentId) => {
    setLoadingText('Loading entry prep docs...');
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getEntryPrepDocsForShipment(shipmentId)
      .then(({ data }) => {
        setSuccessText('Successfully loaded entry prep docs');
        setLoadingStatus(LoadingStatus.SUCCESS);
        setEntryPrepDocs(data);
        // setEntryPrepDocSelections(data?.map((_) => false));
        setLoadingStatus(LoadingStatus.NOT_LOADING);
      })
      .catch((err) => {
        setLoadingStatus(LoadingStatus.ERROR);
        setErrorText('Error getting entry prep docs');
      });
  };

  useEffect(() => {
    if (!shipment) return;
    refreshEntryPrepDocs(shipment!._id!.toString());
  }, [shipment, api]);

  const onEntryPrepDocUpdated = async (updatedDocument: EntryPrepDocForDisplay): Promise<any> => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      const { data } = await api.updateEntryPrepDoc(updatedDocument._id.toString(), updatedDocument);
      setLoadingStatus(LoadingStatus.SUCCESS);
      const index = entryPrepDocs.findIndex(
        (entryPrepDoc) => updatedDocument._id.toString() === entryPrepDoc._id.toString()
      );
      const updatedEntryPrepDocs = [...entryPrepDocs];
      updatedEntryPrepDocs[index] = data;
      setEntryPrepDocs(updatedEntryPrepDocs);
      setLoadingStatus(LoadingStatus.NOT_LOADING);
    } catch (err) {
      console.error('error updating entry prep doc');
      console.error(err);
      setLoadingStatus(LoadingStatus.ERROR);
    }
  };

  return (
    <>
      {loadingStatus === LoadingStatus.LOADING && <span>LOADING</span>}
      {shipment && (
        <div>
          <span>Viewing Documents for Shipment: {shipment.referenceNumber}</span>
          <div className="next-button-container">
            <ImportalSecondaryButton onClick={onClickParseAll} text={'Parse All'} />
          </div>
          <div style={{ padding: '8px' }}>
            <EntryPrepDocumentsView
              entryPrepDocs={entryPrepDocs}
              onEntryPrepDocUpdated={onEntryPrepDocUpdated}
              documentReviewMode={DocumentReviewMode.NO_SELECTION}
            />
          </div>
        </div>
      )}
    </>
  );
}
