import { useChartDimensions } from '@/custom-hooks/reporting/useChartDimensions';
import { renderChartLabel } from '@/utils/reportingUtils';
import { ChartConfig, ChartUnitType, LegendPositionType } from 'common/interfaces/reports';
import * as d3 from 'd3';
import { useMemo, useState } from 'react';
import { ImportalChartLegend } from './ImportalChartLegend';
import { ImportalChartTooltip } from './ImportalChartTooltip';

export interface ImportalPieChartProps {
  chartConfig: ChartConfig;
  data: Array<{
    [key: string]: any;
    color?: string;
  }>;
}

const chartSettings = {
  marginLeft: 100,
  marginRight: 40,
  marginTop: 20,
  marginBottom: 60,
};

// Custom greens palette (darkest to lightest)
const defaultGreens = ['#388E3C', '#4CB150', '#84C889', '#AFDCB2', '##D3EFD5'];

export default function ImportalPieChart({ chartConfig, data }: ImportalPieChartProps) {
  const [ref, dms] = useChartDimensions(chartSettings);

  // Track the hovered slice index, plus the arc data & centroid
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [hoveredArcData, setHoveredArcData] = useState<d3.PieArcDatum<{ [key: string]: any }> | null>(null);
  const [hoveredArcCenter, setHoveredArcCenter] = useState<[number, number] | null>(null);

  const { seriesNameKey, seriesValueKey } = chartConfig.pieConfig ?? {};

  /**
   * Sort data in descending order (largest slice first).
   */
  const sortedData = useMemo(() => {
    if (!data || !data.length || !seriesValueKey) return [];
    if (!Array.isArray(data)) {
      console.warn('Expected data to be an array, but got:', data);
      return [];
    }
    return [...data].sort((a, b) => b[seriesValueKey] - a[seriesValueKey]);
  }, [data, seriesValueKey]);

  /**
   * Legend keys should match sortedData order.
   */
  const legendKeys = useMemo(() => {
    if (!sortedData.length || !seriesNameKey) return [];
    return sortedData.map((s) => s[seriesNameKey]);
  }, [sortedData, seriesNameKey]);

  /**
   * Use custom greens as the default color range.
   */
  const colorScale = useMemo(() => {
    if (!sortedData.length || !seriesNameKey) return null;
    return d3
      .scaleOrdinal<string>()
      .domain(sortedData.map((d) => d[seriesNameKey]))
      .range(defaultGreens)
      .unknown('#ccc');
  }, [sortedData, seriesNameKey]);

  /**
   * Generate the pie arcs from sortedData.
   */
  const arcs = useMemo(() => {
    if (!sortedData.length || !seriesValueKey) return [];
    const pie = d3
      .pie<{ [key: string]: any }>()
      .sort(null)
      .value((d) => +d[seriesValueKey]);
    return pie(sortedData);
  }, [sortedData, seriesValueKey]);

  const width = dms.width;
  const height = dms.height;
  const outerR = Math.min(width, height) / 3;

  const arcGen = useMemo(() => {
    return d3.arc<d3.PieArcDatum<{ [key: string]: any }>>().innerRadius(0).outerRadius(outerR);
  }, [outerR]);

  const labelArcGen = useMemo(() => {
    return d3
      .arc<d3.PieArcDatum<{ [key: string]: any }>>()
      .innerRadius(outerR * 0.5)
      .outerRadius(outerR * 0.5);
  }, [outerR]);

  const isColumnLayout =
    chartConfig.legendPosition === LegendPositionType.TOP || chartConfig.legendPosition === LegendPositionType.BOTTOM;
  return (
    <div ref={ref} style={{ height: '100%', width: '100%' }}>
      <figure style={{ margin: 0, width: dms.width, height: dms.height }}>
        {/* If your chart config calls for legend on the TOP, for example */}
        <ImportalChartLegend keys={legendKeys} colorScale={colorScale} legendPosition={LegendPositionType.TOP} />
        <svg viewBox={`0 0 ${dms.width} ${dms.height}`} preserveAspectRatio="xMidYMid meet">
          <rect fill="#fff" />
          {/* Center the chart in the middle of the viewBox */}
          <g transform={`translate(${dms.width / 2}, ${dms.height / 2})`}>
            <g stroke="none">
              {arcs.map((arcData, i) => {
                const pathFill = arcData.data.color
                  ? arcData.data.color
                  : colorScale?.(arcData.data[seriesNameKey as string]);
                const [arcCX, arcCY] = arcGen.centroid(arcData);

                return (
                  <g
                    key={`arc-${i}`}
                    onMouseEnter={() => {
                      setHoveredIndex(i);
                      setHoveredArcData(arcData);
                      setHoveredArcCenter([arcCX, arcCY]);
                    }}
                    onMouseLeave={() => {
                      setHoveredIndex(null);
                      setHoveredArcData(null);
                      setHoveredArcCenter(null);
                    }}
                  >
                    <path fill={pathFill} d={arcGen(arcData) ?? undefined} />
                  </g>
                );
              })}
            </g>

            {chartConfig.showLabelInChart && (
              <g>
                {arcs.map((arcData, i) => {
                  const [labelX, labelY] = labelArcGen.centroid(arcData);
                  return (
                    <text
                      key={`label-${i}`}
                      transform={`translate(${labelX}, ${labelY})`}
                      fill="#fff"
                      fontWeight="bold"
                      textAnchor="middle"
                      style={{ pointerEvents: 'none', fontSize: '3px' }}
                    >
                      {renderChartLabel(
                        +arcData.data[seriesValueKey as string],
                        chartConfig.pieConfig?.seriesValueUnits ?? ChartUnitType.Number
                      )}
                    </text>
                  );
                })}
              </g>
            )}

            {/* Tooltip AFTER the slices, so it renders on top */}
            {hoveredIndex !== null && hoveredArcData && hoveredArcCenter && (
              <g>
                <ImportalChartTooltip
                  x={hoveredArcCenter[0]}
                  y={hoveredArcCenter[1] - 8} // shift tooltip slightly above the slice center
                  label={hoveredArcData.data[seriesNameKey as string]}
                  value={+hoveredArcData.data[seriesValueKey as string]}
                  unit={chartConfig.pieConfig?.seriesValueUnits ?? ChartUnitType.Number}
                />
              </g>
            )}
          </g>
        </svg>
      </figure>

      {/* Render the legend in other positions if configured */}
      {chartConfig.legendPosition === LegendPositionType.BOTTOM && (
        <ImportalChartLegend keys={legendKeys} colorScale={colorScale} legendPosition={chartConfig.legendPosition} />
      )}
      {chartConfig.legendPosition === LegendPositionType.LEFT && (
        <ImportalChartLegend
          keys={legendKeys}
          colorScale={colorScale}
          legendPosition={chartConfig.legendPosition}
          styles={{ marginRight: '24px' }}
        />
      )}
      {chartConfig.legendPosition === LegendPositionType.RIGHT && (
        <ImportalChartLegend
          keys={legendKeys}
          colorScale={colorScale}
          legendPosition={chartConfig.legendPosition}
          styles={{ marginLeft: '24px' }}
        />
      )}
    </div>
  );
}
