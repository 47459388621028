import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface NumericYAxisProps {
  axisLabel?: string;
  range: [number, number]; // e.g. [0, maxValue]
  height: number;
  tickValues?: number[];
}

export function NumericYAxis({ axisLabel, range, height, tickValues }: NumericYAxisProps) {
  const ref = useRef<SVGGElement | null>(null);

  useEffect(() => {
    if (!ref.current) return;

    // Create the scale
    const axisScale = d3.scaleLinear().domain(range).range([height, 0]).nice();

    // Build the axis generator
    const axis = d3.axisLeft(axisScale).tickFormat(d3.format('d'));

    // If we have explicit tick values, apply them
    if (tickValues && tickValues.length) {
      axis.tickValues(tickValues);
    }

    // Render the axis into our <g ref={ref}> node
    d3.select(ref.current)
      .call(axis)
      // Remove the thick domain line (“axis line”)
      .call((g) => g.select('.domain').remove())
      // Style tick lines or remove them if you like
      .call((g) => g.selectAll('.tick line').attr('stroke', '#ccc').attr('stroke-dasharray', '2,2'))
      // Style tick text
      .call((g) =>
        g
          .selectAll('.tick text')
          .attr('fill', '#666')
          .style('font-family', 'Inter, sans-serif')
          .style('font-size', '12px')
      );
  }, [range, height, tickValues]);

  return (
    <g ref={ref}>
      {axisLabel && (
        <text
          transform="rotate(-90)"
          x={-height / 2}
          y={-65}
          textAnchor="middle"
          fill="#666"
          style={{
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          {axisLabel}
        </text>
      )}
    </g>
  );
}
