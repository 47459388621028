import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Paper, Typography } from '@mui/material';
import React from 'react';
import ImportalMoreOptionsButton from '../ImportalMoreOptionsButton/ImportalMoreOptionsButton';

interface ImportalReportCardBaseProps {
  title: string;
  menuOptions?: { label: string; onClick: () => void }[];
  style?: React.CSSProperties;
  children: React.ReactNode;
}
interface WithFullReport extends ImportalReportCardBaseProps {
  viewFullReport: true;
  handleViewFullReport: () => void;
}
interface WithoutFullReport extends ImportalReportCardBaseProps {
  viewFullReport?: false;
  handleViewFullReport?: never;
}
type ImportalReportCardProps = WithFullReport | WithoutFullReport;

export default function ImportalReportCard({
  title,
  menuOptions = [],
  viewFullReport,
  handleViewFullReport,
  style = {},
  children,
}: ImportalReportCardProps) {
  const cardStyle: React.CSSProperties = {
    borderRadius: '20px',
    minHeight: '300px',
    position: 'relative', // Enables absolute positioning inside the card
    ...style,
  };

  const viewReportContainer: React.CSSProperties = {
    color: '#525256',
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute', // Fixes the container to the bottom right
    bottom: '16px',
    right: '16px',
    cursor: 'pointer', // Ensures pointer on hover
  };

  const viewReportIconContainer: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
  };

  const reportCardTitle: React.CSSProperties = {
    color: '#525256',
    marginBottom: 0,
    marginTop: 0,
  };

  return (
    <Paper
      style={{
        height: '600px',
        padding: '32px',
        borderRadius: '8px',
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: `
          0px 4px 8px 0px rgba(76, 103, 100, 0.02),
          0px -4px 8px 0px rgba(76, 103, 100, 0.02),
          4px 0px 8px 0px rgba(76, 103, 100, 0.02),
          -4px 0px 8px 0px rgba(76, 103, 100, 0.02)`,
        ...cardStyle,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <h2 style={reportCardTitle}>{title}</h2>
        {menuOptions.length > 0 && <ImportalMoreOptionsButton menuOptions={menuOptions} />}
      </div>

      {children}

      {viewFullReport && (
        <div style={viewReportContainer} onClick={() => handleViewFullReport?.()}>
          <div style={{ cursor: 'pointer' }}>View Full Report</div>
          <div style={viewReportIconContainer}>
            <ChevronRightIcon />
          </div>
        </div>
      )}
    </Paper>
  );
}
