import './App.css';
import SignUp from '@/pages/signup/SignUp';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { blue, green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import PhoneAuthentication from '@/pages/signup/PhoneAuthentication';
import Dashboard from '@/pages/home/Dashboard';
import BrokerDashboard from '@/broker-app/pages/broker-dashboard/Dashboard';
import NotFoundPage from '@/pages/not-found-page/NotFoundPage';
import Login from '@/pages/login/Login';
import ProtectedRoute from './ProtectedRoute';
import EsignSuccess from './components/esign-Modal/EsignSuccess';
import ForgotPassword from '@/pages/forgot-password/ForgotPassword';
import ResetEmailConfirmation from '@/pages/reset-email-confirmation/ResetEmailConfirmation';
import PasswordReset from '@/pages/password-reset/PasswordReset';
import DataImports from './components/data-imports/DataImports';
import ProductLibrary from '@/pages/product-library/ProductLibrary';
import { APIProvider } from './api/APIContext';
import { ImportalAPI } from './api';
import React, { createContext, useRef, useState } from 'react';
import { BetaFeaturesProvider } from './custom-hooks/beta-feature/BetaFeatureContext';
import BetaFeatureRoute from './custom-hooks/beta-feature/BetaFeatureRoute';
import * as ls from 'local-storage';
import { ActiveUserProvider } from '@/custom-hooks/user/UserProvider';
import { ActiveBusinessProvider } from '@/custom-hooks/business/BusinessProvider';
import { ActivePlanProvider } from '@/custom-hooks/plan/PlanProvider';
import CustomsOnboardingPage from './pages/customs-onboarding/CustomsOnboardingPage';
import { ActiveCustomsOnboardingProvider } from './custom-hooks/customs-onboarding/CustomsOnboardingProvider';
import LoadSession from '@/shared-components/LoadSession';
import { LoaderProvider } from '@/custom-hooks/loader/LoaderProvider';
import ContactUsModal from '@/components/contact-us-modal/ContactUsModal';
import ViewDocumentPage from './broker-app/pages/documents/ViewDocumentPage';
import { EventBusProvider } from '@/custom-hooks/event-bus/EventBus';
import BusinessSignUp from './pages/signup/BusinessSignUp';
import HtsInfoPage from './broker-app/pages/upload-hts-info/UploadHtsInfoPage';
import ReviewParsedShipmentDocuments from '@/broker-app/pages/prepare-entry-documents/ReviewParsedShipmentDocuments';
import FeatureWalkthroughModal from './shared-components/FeatureWalkthroughModal/FeatureWalkthroughModal';

export const appTheme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Inter';
      }
      `,
    },
  },
  palette: {
    primary: {
      light: green[700],
      main: green[700],
      dark: blue[800],
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: blue[900],
      main: blue[900],
      dark: blue[900],
      contrastText: '#FFFFFF',
    },
    common: {
      chillBlue: '#77A8F6',
    },
  },
});

export interface IAppContext {
  openPlansModal: boolean;
  setOpenPlansModal: (open: boolean) => void;
  handleClosePlansModal: () => void;
  handleOpenPlansModal: () => void;
  clearSession: () => void;
  openContactUs: boolean;
  setOpenContactUs: (open: boolean) => void;
  openWalkthroughModal: boolean;
  setOpenWalkthroughModal: (open: boolean) => void;
  // New handler functions for walkthrough modal
  handleOpenWalkthroughModal: () => void;
  handleCloseWalkthroughModal: () => void;
}

export const AppContext = createContext<IAppContext>({
  openPlansModal: false,
  setOpenPlansModal: () => {},
  handleClosePlansModal: () => {},
  handleOpenPlansModal: () => {},
  clearSession: () => {},
  openContactUs: false,
  setOpenContactUs: () => {},
  openWalkthroughModal: false,
  setOpenWalkthroughModal: () => {},
  handleOpenWalkthroughModal: () => {},
  handleCloseWalkthroughModal: () => {},
});

const { Provider } = AppContext;

function App() {
  const navigate = useNavigate();
  const [openPlansModal, setOpenPlansModal] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openWalkthroughModal, setOpenWalkthroughModal] = useState(false);

  const handleClosePlansModal = () => {
    setOpenPlansModal(false);
  };

  const handleOpenPlansModal = () => {
    setOpenPlansModal(true);
  };

  // Handler functions for WalkthroughModal
  const handleOpenWalkthroughModal = () => {
    setOpenWalkthroughModal(true);
  };

  const handleCloseWalkthroughModal = () => {
    setOpenWalkthroughModal(false);
  };

  const clearSession = () => {
    ls.remove('token');
    ls.remove('user');
    navigate('/login');
  };

  const apiInstanceRef = useRef(new ImportalAPI({ onTokenExpired: clearSession }));

  return (
    <APIProvider apiInstance={apiInstanceRef.current}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline enableColorScheme />

        <ActiveUserProvider>
          <BetaFeaturesProvider>
            <ActiveBusinessProvider>
              <ActiveCustomsOnboardingProvider>
                <ActivePlanProvider>
                  <Provider
                    value={{
                      openPlansModal,
                      setOpenPlansModal,
                      handleClosePlansModal,
                      handleOpenPlansModal,
                      clearSession,
                      openContactUs,
                      setOpenContactUs,
                      openWalkthroughModal,
                      setOpenWalkthroughModal,
                      handleOpenWalkthroughModal,
                      handleCloseWalkthroughModal,
                    }}
                  >
                    <LoaderProvider>
                      <Routes>
                        <Route path={'/session/*'} element={<LoadSession />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/business-signup" element={<BusinessSignUp />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/password-reset" element={<PasswordReset />} />
                        <Route path="/reset-email-confirmation" element={<ResetEmailConfirmation />} />
                        <Route path="/" element={<Navigate to="/login" />} />
                        <Route path="/authenticate-phone" element={<PhoneAuthentication />} />
                        <Route
                          path="/dashboard/*"
                          element={
                            <ProtectedRoute>
                              <Dashboard />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="broker/dashboard/*"
                          element={
                            <ProtectedRoute>
                              <BetaFeatureRoute betaFeature={'broker-dashboard'} fallback={<NotFoundPage />}>
                                <EventBusProvider>
                                  <BrokerDashboard />
                                </EventBusProvider>
                              </BetaFeatureRoute>
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="shipments/:id/entry-document-prep"
                          element={
                            <ProtectedRoute>
                              <BetaFeatureRoute betaFeature={'broker-dashboard'} fallback={<NotFoundPage />}>
                                <EventBusProvider>
                                  <ReviewParsedShipmentDocuments />
                                </EventBusProvider>
                              </BetaFeatureRoute>
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/data-imports"
                          element={
                            <ProtectedRoute>
                              <DataImports />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/product-library"
                          element={
                            <ProtectedRoute>
                              <ProductLibrary />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="/documents/*" element={<ViewDocumentPage />} />
                        <Route path="*" element={<NotFoundPage />} />
                        <Route path="/EsignSuccess" element={<EsignSuccess />}></Route>
                        <Route path="/customs-onboarding" element={<CustomsOnboardingPage />} />
                        <Route
                          path="/hts-info"
                          element={
                            <ProtectedRoute>
                              <HtsInfoPage />
                            </ProtectedRoute>
                          }
                        />
                      </Routes>
                      <ContactUsModal open={openContactUs} onClose={() => setOpenContactUs(false)} />
                      <FeatureWalkthroughModal open={openWalkthroughModal} handleClose={handleCloseWalkthroughModal} />
                    </LoaderProvider>
                  </Provider>
                </ActivePlanProvider>
              </ActiveCustomsOnboardingProvider>
            </ActiveBusinessProvider>
          </BetaFeaturesProvider>
        </ActiveUserProvider>
      </ThemeProvider>
    </APIProvider>
  );
}

export default App;
