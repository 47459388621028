import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ImportalBreadCrumbs } from '@/shared-components/ImportalBreadCrumbs';
import ReportDefault from '@/pages/reports/ReportDefault';
import ReportAdvanced from '@/pages/reports/ReportAdvanced';
import { ReportsPageContext } from '@/pages/reports/ReportsPage';
import { Report, ReportType } from 'common/interfaces/reports';
import { useBetaFeatures } from '@/custom-hooks/beta-feature/BetaFeatureContext';
import { useAPI } from '@/api/APIContext';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import { Add } from '@mui/icons-material';
import ImportalCard from '@/shared-components/ImportalCard';
import moment from 'moment';

export default function GeneratedReports() {
  const api = useAPI();
  const { reports, refreshReports } = useContext(ReportsPageContext);
  const betaFeatures = useBetaFeatures();

  // Merged list of both Generated and Custom reports
  const [mergedReports, setMergedReports] = useState<Report[]>([]);

  useEffect(() => {
    const merged = reports
      .filter((report) => report.reportType === ReportType.GENERATED || report.reportType === ReportType.CUSTOM)
      .sort((a, b) => new Date(b.lastRun).getTime() - new Date(a.lastRun).getTime());
    setMergedReports(merged);
  }, [reports]);

  const onClickAddReport = () => {
    api
      .createReport(ReportType.GENERATED)
      .then(() => {
        refreshReports();
      })
      .catch((err) => {
        console.error('Error creating new Report', err);
      });
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <div>
        {betaFeatures.includes('reporting-advanced') && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
            <ImportalPrimaryButton
              style={{ width: '200px', borderRadius: '10px', fontSize: '14px' }}
              onClick={onClickAddReport}
              text="Create New Report"
              endIcon={<Add />}
            />
          </div>
        )}
        <ImportalBreadCrumbs
          elements={[
            {
              render: ({ pushItem }) => (
                <ImportalCard title="Reports">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Last Run</TableCell>
                        <TableCell>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mergedReports.map((report, idx) => (
                        <TableRow key={idx} hover style={{ cursor: 'pointer' }} onClick={() => pushItem(report)}>
                          <TableCell>{report.name}</TableCell>
                          <TableCell>{moment(report.lastRun).format('MM/DD/YY')}</TableCell>
                          <TableCell>
                            {report.reportType === ReportType.GENERATED ? 'Importal Generated' : 'Custom'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </ImportalCard>
              ),
              label: 'Reports',
            },
            {
              render: ({ pushItem, item }) => (
                <ReportDefault
                  report={item}
                  onAdvancedClicked={(report: Report) => {
                    pushItem(report);
                  }}
                />
              ),
              label: (report: Report) => report.name,
            },
            {
              render: ({ item }) => <ReportAdvanced report={item} />,
              label: 'Advanced',
            },
          ]}
        />
      </div>
    </Container>
  );
}
