import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  /**
   * Toggle loading spinner
   */
  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  /**
   * Handle form submission
   */
  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();

    if (!email) {
      setErrorText('Please enter your email.');
      return;
    }

    forgotPasswordRequest({ email });
  };

  /**
   * Make API call to request a password reset
   */
  const forgotPasswordRequest = (user: { email: string }) => {
    handleLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/auth/forgot-password`, user)
      .then(() => {
        handleLoading(false);
        // Navigate to your confirmation route:
        navigate('/reset-email-confirmation');
      })
      .catch((error) => {
        handleLoading(false);
        console.error('Error requesting password reset', error);
        setErrorText(error.response?.data?.error?.message || 'Unable to process request');
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: 'black',
      }}
    >
      {/* LEFT SECTION: LOGO + FORM */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src="IMPORTAL-FUTURE.png" width="218" height="38" alt="logo" />
        </Container>

        <Box
          sx={{
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            pr: '22%',
            pl: '22%',
            color: 'white',
            // Adjust padding for smaller screens
            '@media (max-width: 900px)': {
              pr: '8%',
              pl: '8%',
            },
            '@media (max-width: 600px)': {
              pr: '5%',
              pl: '5%',
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 2,
              fontSize: '24px',
              fontWeight: 600,
              color: 'white',
              textAlign: 'center',
            }}
          >
            Forgot your password?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 2,
              fontSize: '14px',
              color: 'white',
              textAlign: 'center',
            }}
          >
            No problem. Enter your email below, and we'll send you a link to reset it.
          </Typography>

          {/* FORM */}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    mb: '7px',
                    color: 'white',
                    fontWeight: 700,
                  }}
                >
                  Email
                </Box>
                <TextField
                  fullWidth
                  id="email"
                  size="small"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    style: { backgroundColor: 'white', fontSize: '14px' },
                  }}
                />
              </Grid>
            </Grid>

            {/* Error Text */}
            {errorText && (
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Box sx={{ color: 'red' }}>{errorText}</Box>
              </Grid>
            )}

            {/* Submit Button */}
            <Box
              sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ImportalPrimaryButton
                onClick={() => handleSubmit()}
                text={isLoading ? 'Submitting...' : 'Submit'}
                disabled={isLoading}
                style={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* RIGHT SECTION: Cargo Image (hidden on xs/sm) */}
      <Box
        sx={{
          flex: 1,
          overflow: 'hidden',
          display: { xs: 'none', sm: 'none', md: 'block' },
        }}
      >
        <Box
          component="img"
          src="cargo_in_ocean.png"
          alt="Cargo in ocean"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
          }}
        />
      </Box>
    </Box>
  );
}
