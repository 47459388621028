import ImportalBarChart from '@/components/reporting/ImportalBarChart';
import ImportalHistogram from '@/components/reporting/ImportalHistogram';
import ImportalPieChart from '@/components/reporting/ImportalPieChart';
import ImportalStackedBarChart from '@/components/reporting/ImportalStackedBarChart';
import { ChartConfig } from 'common/interfaces/reports';

export interface Props {
  chartConfig: ChartConfig;
  data: any[];
}

export default function ReportChart({ chartConfig, data }: Props) {
  const getChart = (chartConfig: ChartConfig) => {
    if (chartConfig.chartType === 'PIE') {
      return <ImportalPieChart chartConfig={chartConfig} data={data}></ImportalPieChart>;
    } else if (chartConfig.chartType === 'HISTOGRAM') {
      return <ImportalHistogram chartConfig={chartConfig} data={data}></ImportalHistogram>;
    } else if (chartConfig.chartType === 'BAR') {
      return <ImportalBarChart chartConfig={chartConfig} data={data}></ImportalBarChart>;
    } else if (chartConfig.chartType === 'STACKED_BAR') {
      return <ImportalStackedBarChart chartConfig={chartConfig} data={data}></ImportalStackedBarChart>;
    } else {
      return <div>Unimplemented Chart Type: {chartConfig.chartType}</div>;
    }
  };

  return <div style={{ height: '100%', width: '100%' }}>{getChart(chartConfig)}</div>;
}
