import { CategoricalXAxis } from '@/components/reporting/CategoricalXAxis';
import { ImportalHistogramBars } from '@/components/reporting/ImportalHistogramBars';
import { NumericYAxis } from '@/components/reporting/NumericYAxis';
import { useChartDimensions } from '@/custom-hooks/reporting/useChartDimensions';
import { ChartConfig, ChartUnitType } from 'common/interfaces/reports';
import { useMemo } from 'react';

export interface Props {
  chartConfig: ChartConfig;
  data: any[];
}

export default function ImportalHistogram({ chartConfig, data }: Props) {
  const [ref, dms] = useChartDimensions({
    marginLeft: 100,
    marginRight: 40,
    marginTop: 20,
    marginBottom: 60,
  });

  const { seriesNameKey, seriesDisplayNameKey, seriesValueKey, xAxisName, yAxisName } =
    chartConfig.histogramConfig ?? {};

  // Convert raw data to {category, value}
  const seriesData = useMemo(() => {
    if (!seriesNameKey || !seriesValueKey) return [];
    if (!Array.isArray(data)) {
      console.warn('Expected data to be an array, but got:', data);
      return [];
    }
    return data.map((datum) => ({
      category: datum[seriesNameKey],
      value: +datum[seriesValueKey],
    }));
  }, [data, seriesNameKey, seriesValueKey]);

  // All category names
  const categories: string[] = useMemo(() => {
    if (!seriesNameKey) return [];
    return data.map((d) => d[seriesNameKey]);
  }, [data, seriesNameKey]);

  const displayCategories: string[] = useMemo(() => {
    if (!seriesDisplayNameKey) return categories;
    return data.map((d) => d[seriesDisplayNameKey]);
  }, [data, seriesDisplayNameKey]);

  /**
   * Strongly type `range` as a *two-element tuple*: [min, max].
   * Make sure you actually return exactly 2 elements.
   */
  const range: [number, number] = useMemo(() => {
    if (!seriesValueKey) {
      return [0, 0];
    }
    // Convert values to number
    const values = data.map((d) => +d[seriesValueKey]);
    // Fallback if data is empty, or use Math.max if not
    const maxVal = values.length ? Math.max(...values) : 0;
    return [0, maxVal];
  }, [data, seriesValueKey]);

  return (
    <div ref={ref} style={{ height: '100%', width: '100%' }}>
      <figure style={{ margin: 0, width: dms.width, height: dms.height }}>
        <svg width={dms.width} height={dms.height}>
          {/* Chart area transform */}
          <g transform={`translate(${dms.marginLeft}, ${dms.marginTop})`}>
            {/* Background */}
            <rect width={dms.boundedWidth} height={dms.boundedHeight} fill="#fff" rx={8} />
            <ImportalHistogramBars
              width={dms.boundedWidth}
              height={dms.boundedHeight}
              categories={seriesData}
              range={range}
              unit={chartConfig.histogramConfig?.yAxisUnits || ChartUnitType.Number}
              customGridLines={chartConfig.yAxisTickValues}
              rounded={true}
            />
            <NumericYAxis
              axisLabel={yAxisName || ''}
              height={dms.boundedHeight}
              range={range} // <-- Now a 2-element tuple
              tickValues={chartConfig.yAxisTickValues} // if you have ticks to pass
            />
          </g>
          <g transform={`translate(${dms.marginLeft}, ${dms.marginTop + dms.boundedHeight})`}>
            <CategoricalXAxis
              axisLabel={xAxisName || ''}
              categories={categories}
              displayCategories={displayCategories}
              width={dms.boundedWidth}
            />
          </g>
        </svg>
      </figure>
    </div>
  );
}
