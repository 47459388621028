import { ChartUnitType } from 'common/interfaces/reports';
import * as d3 from 'd3';
import { useState } from 'react';
import { ImportalChartTooltip } from './ImportalChartTooltip';

/**
 * Helper to generate a path for a rectangle with only the top corners rounded.
 *
 * @param x - The x position of the bar.
 * @param y - The y position (top) of the bar.
 * @param width - The width of the bar.
 * @param height - The height of the bar.
 * @param radius - The radius for the top corners.
 */
function getRoundedTopRectPath(x: number, y: number, width: number, height: number, radius: number): string {
  // Clamp the radius to at most half the width
  const r = Math.min(radius, width / 2);
  return `
    M ${x} ${y + r}
    Q ${x} ${y} ${x + r} ${y}
    H ${x + width - r}
    Q ${x + width} ${y} ${x + width} ${y + r}
    V ${y + height}
    H ${x}
    Z
  `;
}

interface HoverState {
  index: number;
  x: number;
  y: number;
  label: string;
  value: number;
}

interface ImportalVerticalBarsProps {
  data: any[];
  xScale: d3.ScaleBand<string>;
  yScale: d3.ScaleLinear<number, number>;
  seriesNameKey: string;
  seriesValueKey: string;
  barColor?: string;
  /** Enable rounded top corners */
  rounded?: boolean;
  unit: ChartUnitType;
}

export function ImportalVerticalBars({
  data,
  xScale,
  yScale,
  seriesNameKey,
  seriesValueKey,
  barColor = '#FFC107',
  rounded = false,
  unit,
}: ImportalVerticalBarsProps) {
  // Track hover state for tooltip
  const [hoverState, setHoverState] = useState<HoverState | null>(null);

  return (
    <g>
      {data.map((d, i) => {
        const category = d[seriesNameKey];
        const value = +d[seriesValueKey];

        // Position and dimensions for the bar
        const barX = xScale(category) ?? 0;
        const barY = yScale(value);
        const barHeight = yScale(0) - barY; // or if stacked, adjust accordingly

        const handleMouseEnter = () => {
          // Position the tooltip at the center top of the bar
          const tooltipX = barX + xScale.bandwidth() / 2;
          const tooltipY = barY - 8;

          setHoverState({
            index: i,
            x: tooltipX,
            y: tooltipY,
            label: category,
            value,
          });
        };

        const handleMouseLeave = () => {
          setHoverState(null);
        };

        return (
          <g key={i} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {rounded ? (
              <path
                d={getRoundedTopRectPath(
                  barX,
                  barY,
                  xScale.bandwidth(),
                  barHeight,
                  8 // corner radius
                )}
                fill={barColor}
              />
            ) : (
              <rect x={barX} y={barY} width={xScale.bandwidth()} height={barHeight} fill={barColor} />
            )}
          </g>
        );
      })}

      {/* Render the tooltip last so it's above the bars */}
      {hoverState && <ImportalChartTooltip x={hoverState.x} y={hoverState.y} value={hoverState.value} unit={unit} />}
    </g>
  );
}
