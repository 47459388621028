// BusinessSignUp.tsx
import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Link, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '@/api/APIContext';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import SignUpFormFields from './SignUpFormFields';
import { formatPhoneNumber } from '@/utils/formatPhoneNumber';
import { formatBusinessSignUpWelcomeString } from '@/utils/formatBusinessSignUpWelcomeString';
import { handleFormChange, handlePhoneNumberChange, handleTermsConditionsChange, registerUser } from './formHandlers';

export default function BusinessSignUp() {
  const theme = useTheme();
  const navigate = useNavigate();
  const api = useAPI();
  const { business, users, refreshBusiness, refreshUsers } = useActiveBusiness();

  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [termsConditions, setTermsConditions] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailError, setEmailError] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [businessSignupWelcomeMessage, setBusinessSignupWelcomeMessage] = useState<string>('');

  useEffect(() => {
    if (!business) {
      console.error('BusinessSignUp rendered with no business');
      return;
    }
    setBusinessSignupWelcomeMessage(formatBusinessSignUpWelcomeString(users || [], business.name));
  }, [business, users]);

  useEffect(() => {
    if (!business) {
      refreshBusiness();
    } else if (!users) {
      refreshUsers();
    } else {
      setIsLoading(false);
    }
  }, [business, users, refreshBusiness, refreshUsers]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userToSubmit = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      phone: formatPhoneNumber(data.get('phone')),
      password: data.get('password'),
    };

    registerUser(api, userToSubmit, setIsLoading, setErrorText, navigate);
  };

  const handleExistingAccount = () => {
    navigate('/login');
  };

  // Inline style for the right-side overlay container (same as in SignupPage)
  const overlayContainerStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: 'white',
    padding: '32px',
    maxWidth: '80%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '12px',
    boxSizing: 'border-box',
  };

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: 'black',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src="IMPORTAL-FUTURE.png" width="218" height="38" alt="Importal Logo" />
        </Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            pr: '22%',
            pl: '22%',
            color: 'white',
            // Adjust padding for smaller screens
            [theme.breakpoints.down('md')]: {
              pr: '5%',
              pl: '5%',
            },
          }}
        >
          <Box sx={{ width: '100%', textAlign: 'center', mb: 1 }}>
            <Typography variant="body2">
              <Link
                onClick={handleExistingAccount}
                sx={{
                  cursor: 'pointer',
                  color: '#fff',
                  textDecoration: 'underline',
                  fontWeight: 700,
                  fontSize: '14px',
                  ':hover': {
                    color: '#f5f5f5',
                    textDecoration: 'none',
                  },
                }}
              >
                Already have an account? Sign in
              </Link>
            </Typography>
          </Box>
          <SignUpFormFields
            handleFormChange={(event) => handleFormChange(event, setEmailError)}
            handleSubmit={handleSubmit}
            handlePhoneNumberChange={(event) => handlePhoneNumberChange(event, setPhoneNumber)}
            handleTermsConditionsChange={(event) => handleTermsConditionsChange(event, setTermsConditions)}
            setHidePassword={setHidePassword}
            hidePassword={hidePassword}
            phoneNumber={phoneNumber}
            emailError={emailError}
            isLoading={isLoading}
            termsConditions={termsConditions}
            errorText={errorText}
          />
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          position: 'relative',
          overflow: 'hidden',
          display: { xs: 'none', md: 'block' }, // Hide on smaller screens
        }}
      >
        <img
          src="cargo_in_ocean.png"
          alt="Cargo in ocean"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
          }}
        />
        <div style={overlayContainerStyle}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
            }}
          >
            {businessSignupWelcomeMessage}
          </Typography>
        </div>
      </Box>
    </div>
  );
}
