import ProductTable from '@/pages/product-library/ProductTable';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import React, { useContext, useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Product, ProductAction } from 'common/interfaces/product';
import DataImportLoader, { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { useAPI } from '@/api/APIContext';
import { ImportalTableHandles } from '@/shared-components/ImportalTable/ImportalTable';
import { ImportalRequiredFieldNames, ProductFieldsConfig } from 'common/interfaces/productfields';
import ConfirmModal from '@/pages/product-library/ConfirmModal';
import EditProductFieldsModal from '@/pages/product-library/EditProductFieldsModal';
import AddOrEditAProductModal from '@/pages/product-library/AddOrEditAProductModal';
import Menu from '@mui/material/Menu';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import ImportalCard from '@/shared-components/ImportalCard';
import ActiveJobsTable, { ActiveJob } from '@/pages/product-library/ActiveJobsTable';
import { DataImport, DataImportStatus } from 'common/interfaces/dataimport';
import { ComplianceNotification } from 'common/interfaces/complianceSummary';
import complianceNotifications from '@/shared-components/renderProductLibraryNotifications';
import { BusinessPageContext } from '@/broker-app/pages/businesses/BusinessPage';
import Dropzone from 'react-dropzone';
import Settings from '@mui/icons-material/Settings';
import UploadProductsDataModal from '@/pages/product-library/UploadProductsDataModal';
import ExportProductDataModal from '@/pages/product-library/ExportProductDataModal';
import { DataExportProductCSV, DataExportStatus } from 'common/interfaces/dataExport';
import { TableConfig } from 'common/interfaces/importalTable';

export function ProductsTab() {
  const { business } = useContext(BusinessPageContext);
  const api = useAPI();

  const [products, setProducts] = useState<Product[]>([]);
  const [activeJobs, setActiveJobs] = useState<ActiveJob[]>([]);
  const [productLists, setProductLists] = useState<File[]>([]);
  const [dataImportForViewingInModal, setDataImportForViewingInModal] = useState<DataImport<any> | undefined>();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [uploadProductsOpen, setUploadProductsOpen] = useState(false);

  const [productFieldsConfig, setProductFieldsConfig] = useState<ProductFieldsConfig | undefined>(undefined);
  const [editProductFieldsOpen, setEditProductFieldsOpen] = useState(false);
  const [editTableSettingsOpen, setEditTableSettingsOpen] = useState(false);
  const [exportProductDataOpen, setExportProductDataOpen] = useState(false);

  // Two separate state variables for notifications:
  const [notifications, setNotifications] = useState<ComplianceNotification[]>([]);
  const [closeInfoAlert, setCloseInfoAlert] = useState(true);
  const [closeWarningAlert, setCloseWarningAlert] = useState(true);

  // TODO: add the type for the importalTable so it has the method i want
  const importalTableRef = React.createRef<ImportalTableHandles>();

  const [addOrEditAProductOpen, setAddOrEditAProductOpen] = useState(false);
  const [productIDForViewingInModal, setProductIDForViewingInModal] = useState<string | undefined>();
  const [productIdForDeleteModal, setProductIdForDeleteModal] = useState<string | undefined>();
  const [showingDeleteConfirmation, setShowingDeleteConfirmation] = useState(false);
  const [confirmDeleteAllOpen, setConfirmDeleteAllOpen] = useState(false);

  const createTableConfig = () => {
    return {
      columns: productFieldsConfig?.fields.filter((field) => field.showInUI) || [],
      sortConfig: [],
      filterConfig: {},
      subKeyForData: 'properties',
    };
  };

  const [productTableConfig, setProductTableConfig] = useState<TableConfig>(createTableConfig);

  useEffect(() => {
    setProductTableConfig(createTableConfig);
  }, [productFieldsConfig]);

  // Menu Component boilerplate
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onClickEditProduct = (productID: string) => {
    setProductIDForViewingInModal(productID);
    setAddOrEditAProductOpen(true);
  };

  const onClickDeleteProduct = (productID: string) => {
    setProductIdForDeleteModal(productID);
    setShowingDeleteConfirmation(true);
  };

  const getBusinessAndNotifications = async () => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      if (!business) {
        console.error('somehow business is undefined');
        return;
      }
      let businessId = business._id!.toString();
      const { data: notifications } = await api.getComplianceNotifications(businessId);
      setLoadingStatus(LoadingStatus.SUCCESS);
      setSuccessText('Successfully retrieved Compliance Notifications');
      setNotifications(notifications);
    } catch (error) {
      setErrorText('Error getting compliance notifications');
      setLoadingStatus(LoadingStatus.ERROR);
    }
  };

  const getProducts = () => {
    if (!business) {
      console.error('somehow business is undefined');
      return;
    }
    let businessId = business._id!.toString();
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getProducts(businessId)
      .then(({ data }) => {
        setProducts(data);
        setSuccessText('Successfully retrieved Products');
        setLoadingStatus(LoadingStatus.SUCCESS);
      })
      .catch((err) => {
        console.error('error getting all products for user', err);
        setErrorText('Error getting product');
        setLoadingStatus(LoadingStatus.ERROR);
      });
  };

  useEffect(() => {
    getProducts();
    getBusinessAndNotifications();
  }, [api]);

  const handleFilesDropped = async (files: File[]) => {
    if (!business) {
      console.error('somehow business is undefined');
      return;
    }

    if (!(dataImportForViewingInModal && dataImportForViewingInModal?.status === DataImportStatus.DRAFT)) {
      const { data: dataImport } = await api.createNewDataImport(business._id!.toString());
      setDataImportForViewingInModal(dataImport);
    }
    setProductLists(files);
    setUploadProductsOpen(true);
  };

  const refreshProductFieldsConfig = () => {
    if (!business) {
      console.error('somehow business is undefined');
      return;
    }
    let businessId = business._id!.toString();
    api
      .getProductFields(businessId)
      .then(({ data: productFieldsConfig }) => {
        setProductFieldsConfig(productFieldsConfig);
      })
      .catch((err) => {
        console.error('error getting product fields config', err);
      });
  };

  useEffect(refreshProductFieldsConfig, [api]);

  const onConfirmDeleteProduct = () => {
    if (!productIdForDeleteModal) return;
    api
      .deleteProduct(productIdForDeleteModal)
      .then(() => {
        setShowingDeleteConfirmation(false);
        onProductCreatedModifiedOrDeleted(productIdForDeleteModal, ProductAction.DELETED);
      })
      .catch((err) => {
        console.error('error deleting product');
        console.error(err);
      });
  };

  const productUploadSubmitted = (dataImport: DataImport<any>) => {
    api
      .submitDataImport(dataImport._id.toString())
      .then(() => {
        checkForActiveJobs();
      })
      .catch((err) => {
        console.error('error submitting data import for processing');
        console.error(err);
      });
  };

  const handleOpenProductFieldsModal = () => {
    setEditProductFieldsOpen(true);
  };

  const checkForActiveJobs = async () => {
    if (!business) {
      console.error('somehow business is undefined');
      return;
    }
    setActiveJobs([]);
    let jobsToMonitor: ActiveJob[] = [];
    do {
      try {
        const { data: productUploads } = await api.getActiveProductUploads(business._id!.toString());
        jobsToMonitor = productUploads.map((productUpload) => ({
          type: 'Products Upload',
          status: productUpload.status,
        }));
      } catch (err) {
        console.error('error getting active product uploads', err);
      }
      try {
        const { data: calculationsInProgress } = await api.getActiveCalculations(business._id!.toString());
        const activeCalculationJobs = calculationsInProgress.map((calculation) => ({
          type: 'Products Compliance Summary',
          status: 'PROCESSING',
        }));
        jobsToMonitor = [...jobsToMonitor, ...activeCalculationJobs];
      } catch (err) {
        console.error('error getting active product compliance summary calculations', err);
      }
      setActiveJobs(jobsToMonitor);
      await new Promise((resolve) => setTimeout(resolve, 3000));
    } while (
      jobsToMonitor &&
      jobsToMonitor.length > 0 &&
      jobsToMonitor.some((job) => job.status === 'PROCESSING' || job.status === DataImportStatus.SUBMITTED_PENDING)
    );
    getProducts();
  };

  useEffect(() => {
    checkForActiveJobs();
    if (activeJobs && activeJobs.length > 0 && activeJobs.some((job) => job.status === 'PROCESSING')) {
      setTimeout(checkForActiveJobs, 4000);
    } else {
      getProducts();
    }
  }, [api]);

  const onProductCreatedModifiedOrDeleted = (productId: string, action: ProductAction) => {
    switch (action) {
      case ProductAction.CREATED:
        api
          .getProductByID(productId)
          .then(({ data: newProduct }) => {
            setProducts([...products, newProduct]);
          })
          .catch((err) => {
            console.error('error getting product to update table after creation', err);
          });
        break;
      case ProductAction.MODIFIED:
        api
          .getProductByID(productId)
          .then(({ data: updatedProduct }) => {
            const index = products.findIndex((product: Product) => product._id!.toString() === productId);
            if (index !== -1) {
              let newProducts = [...products];
              newProducts[index] = updatedProduct; // Splice in the new product at the same index
              setProducts(newProducts);
            }
          })
          .catch((err) => {
            console.error('error getting product to update table after modification', err);
          });
        break;
      case ProductAction.DELETED:
        setProducts((prevProducts) => prevProducts.filter((product) => product._id!.toString() !== productId));
        break;
      default:
        console.log('no op');
    }
  };

  const onClickSeeAdditionalChinaTariffAffectedProducts: () => void = () => {
    console.log(productTableConfig);
    setProductTableConfig(
      (prevConfig: TableConfig): TableConfig => ({
        ...prevConfig,
        filterConfig: {
          [ImportalRequiredFieldNames.COUNTRY_OF_ORIGIN]: {
            columnName: ImportalRequiredFieldNames.COUNTRY_OF_ORIGIN,
            allowedValues: ['CN', 'HK'],
          },
        },
      })
    );
  };

  const onClickCalculateComplianceSummaries = async () => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      setLoadingText('Calculating compliance summaries...');
      await api.calculateComplianceSummaries(business?._id?.toString());
      setLoadingText('Checking for active jobs...');
      await checkForActiveJobs();
      setLoadingText('Fetching products...');
      await getProducts();
      setLoadingText('Fetching business and notifications...');
      await getBusinessAndNotifications();
      setLoadingStatus(LoadingStatus.SUCCESS);
      setSuccessText('Successfully completed all tasks!');
    } catch (err) {
      setErrorText('Error getting compliance notifications');
      setLoadingStatus(LoadingStatus.ERROR);
    }
  };

  const onDataExportSubmitted = async (exportName: string) => {
    if (importalTableRef.current) {
      try {
        const { data: createdProductDataExport } = await api.createProductExport({
          exportName: exportName,
          tableState: importalTableRef.current.getTableConfig(),
        });
        // @ts-ignore
        let dataExport: DataExportProductCSV = {};
        while (dataExport.status !== DataExportStatus.COMPLETED) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const { data } = await api.getDataExport(createdProductDataExport._id.toString());
          if (data.status === DataExportStatus.ERROR) {
            throw new Error('Data Export Failed During Processing');
          }
          dataExport = data;
        }
        return dataExport;
      } catch (err) {
        console.error('error creating product data export', err);
        throw err;
      }
    } else {
      console.error('no ref for ImportalTable');
    }
  };

  return (
    <>
      {activeJobs && activeJobs.length ? (
        <ImportalCard title="Active Jobs" style={{ marginBottom: '32px' }}>
          <ActiveJobsTable activeJobs={activeJobs} />
        </ImportalCard>
      ) : null}

      <div id={'action-menu'} className="right-button-container">
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem
            onClick={() => {
              setExportProductDataOpen(true);
              handleMenuClose();
            }}
          >
            Export Products
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClickCalculateComplianceSummaries();
              handleMenuClose();
            }}
          >
            Recalculate Compliance Summaries
          </MenuItem>
          <MenuItem disabled={true}>
            Upload Products:
            <br />
            Drag a File onto the Library
          </MenuItem>
          <MenuItem
            onClick={() => {
              setConfirmDeleteAllOpen(true);
            }}
          >
            Delete Products
          </MenuItem>
        </Menu>
      </div>

      <AddOrEditAProductModal
        open={addOrEditAProductOpen}
        productID={productIDForViewingInModal}
        productFieldsConfig={productFieldsConfig}
        onProductCreatedOrModified={onProductCreatedModifiedOrDeleted}
        onClose={() => setAddOrEditAProductOpen(false)}
      />

      <UploadProductsDataModal
        open={uploadProductsOpen}
        onClose={() => setUploadProductsOpen(false)}
        onSubmit={productUploadSubmitted}
        dataImport={dataImportForViewingInModal}
        productFieldsConfig={productFieldsConfig}
        files={productLists}
      />

      <ExportProductDataModal
        open={exportProductDataOpen}
        onClose={() => setExportProductDataOpen(false)}
        onDataExportSubmitted={onDataExportSubmitted}
      />

      <EditProductFieldsModal
        open={editProductFieldsOpen}
        onClose={() => setEditProductFieldsOpen(false)}
        onFieldsEdited={refreshProductFieldsConfig}
        refreshProducts={getProducts}
        businessId={business?._id?.toString()}
      />

      <ConfirmModal
        open={showingDeleteConfirmation}
        onClose={() => setShowingDeleteConfirmation(false)}
        onConfirm={onConfirmDeleteProduct}
        message="Are you sure you want to delete this product?"
        confirmButtonText={'Delete Product'}
        cancelButtonText={'Cancel'}
      />

      <Dropzone onDrop={(acceptedFiles) => handleFilesDropped(acceptedFiles)} noClick>
        {({ getRootProps, getInputProps }) => (
          <>
            <input {...getInputProps()} />
            <div {...getRootProps()}>
              {products.length === 0 ? (
                <ImportalCard
                  title={`Upload Products For ${business?.name}`}
                  topRightActionButton={
                    <Tooltip title="Configure Columns">
                      <IconButton onClick={() => setEditProductFieldsOpen(true)}>
                        <Settings />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <div className="products-list-upload-container">
                    <div>
                      <div>
                        Looks like {business?.name} doesn't have any products yet. Drag and drop the product list
                        anywhere on the card and we'll do the rest.
                      </div>
                    </div>
                  </div>
                </ImportalCard>
              ) : (
                <>
                  {notifications.length > 0 &&
                    complianceNotifications(
                      notifications,
                      setCloseInfoAlert,
                      setCloseWarningAlert,
                      closeInfoAlert,
                      closeWarningAlert,
                      onClickSeeAdditionalChinaTariffAffectedProducts
                    )}
                  <ProductTable
                    ref={importalTableRef}
                    products={products}
                    embeddedStyling={false}
                    productFieldsConfig={productFieldsConfig}
                    onClickEditProduct={onClickEditProduct}
                    onClickDeleteProduct={onClickDeleteProduct}
                    onClickSettings={() => setEditTableSettingsOpen(true)}
                    productActionsButton={
                      <ImportalPrimaryButton
                        style={{
                          width: '120px',
                          borderRadius: '8px',
                          height: '40px',
                          fontSize: '12px',
                        }}
                        onClick={(e) => handleMenuClick(e as React.MouseEvent<HTMLElement>)}
                        text="Actions"
                        endIcon={<ArrowDropDownIcon />}
                      />
                    }
                    openModal={handleOpenProductFieldsModal}
                    isProductModal={true}
                    productTableConfig={productTableConfig}
                    setProductTableConfig={setProductTableConfig}
                  />
                </>
              )}
            </div>
          </>
        )}
      </Dropzone>

      <DataImportLoader
        loadingState={{ loadingStatus }}
        loadingText={loadingText}
        successText={successText}
        errorText={errorText}
      />
    </>
  );
}
