import { useAPI } from '@/api/APIContext';
import { useQuery } from '@/custom-hooks/UseQuery';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardPage from './DashboardPage';

export default function Summary() {
  const api = useAPI();
  const navigate = useNavigate();
  // const { reports } = useContext(ReportsPageContext);

  const fetchSummaryDashboard = useCallback(async () => {
    try {
      // really just fetches default dashboard for now
      const response = await api.getDashboardForCurrentUser();
      return response.data ?? [];
    } catch (err) {
      console.error('Error fetching dashboard:', err);
      return [];
    }
  }, [api]);

  const {
    data: dashboard,
    isLoading: isDashboardLoading,
    isError: isDashboardError,
  } = useQuery({ queryKey: ['summaryDashboard'], queryFn: fetchSummaryDashboard, initialData: [] });

  const memoizedDashboard = useMemo(() => dashboard, [dashboard]);

  return <DashboardPage dashboardData={memoizedDashboard} />;
}
