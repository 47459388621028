import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import './BusinessInfo.css';
import StatesDropdown from '../states-dropdown/StatesDropdown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Autocomplete from '@mui/material/Autocomplete';
import { useAPI } from '@/api/APIContext';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { BusinessStructure, BusinessWithDocuments } from 'common/interfaces/business';
import { useActiveCustomsOnboarding } from '@/custom-hooks/customs-onboarding/CustomsOnboardingProvider';

export default function BusinessInfo({ handleEvent, handleLoading, updateCustomsOnboarding, customsOnboarding }) {
  const [businessStructure, setBusinessStructure] = useState<string | undefined>('');
  const [businessStructureError, setBusinessStructureError] = useState('');
  const [ein, setEIN]: any = useState('');
  const [einError, setEINError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [address, setAddress] = useState<string | undefined>('');
  const [signeeTitle, setSigneeTitle] = useState<string | undefined>('');
  const [state, setState] = useState<String>('');
  const [stateError, setStateError] = useState<String>('');
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [currentBusiness, setCurrentBusiness] = useState<BusinessWithDocuments>();
  const [addressPredictions, setAddressPredictions] = useState<any>([]);
  const [googleAddress, setGoogleAddress] = useState<any>('');
  const api = useAPI();
  const activeBusiness = useActiveBusiness();
  const activeCustomsOnboarding = useActiveCustomsOnboarding();

  const handleSelectEvent = (value) => {
    setState(value);
  };

  const handleCheckedEvent = (e) => {
    if (e.target.checked) {
      setShowStateDropdown(true);
    } else {
      setShowStateDropdown(false);
    }
  };

  const renderStatesDropdown = (showStateDropdown) => {
    if (showStateDropdown) {
      return (
        <div className="states-dropdown-container">
          <StatesDropdown stateError={stateError} handleEvent={handleSelectEvent} />
        </div>
      );
    }
  };

  function createBusiness(business) {
    handleLoading(true);
    api
      .createBusinessViaOnboarding(business)
      .then(({ data }) => {
        activeBusiness.refreshBusiness();
        activeCustomsOnboarding.refreshCustomsOnboarding();
        handleLoading(false);
      })
      .catch(function (error) {
        console.error('error creating business via onboarding');
        console.error(error);
        handleLoading(false);
      });
  }

  function updateBusiness(business) {
    handleLoading(true);
    api
      .updateBusinessViaOnboarding(currentBusiness!._id?.toString() as string, business)
      .then(function (response) {
        activeBusiness.refreshBusiness();
        activeCustomsOnboarding.refreshCustomsOnboarding();
        handleLoading(false);
      })
      .catch(function (error) {
        console.error('error updating business via onboarding');
        console.error(error);
        handleLoading(false);
      });
  }

  const validateBusinessInfo = () => {
    let isValid = true;
    if (!ein) {
      isValid = false;
      setEINError('EIN is required.');
    } else {
      setEINError('');
    }
    if (!name) {
      isValid = false;
      setNameError('Legal Business Name is required.');
    } else {
      setNameError('');
    }
    if (!businessStructure || !(businessStructure in BusinessStructure)) {
      isValid = false;
      setBusinessStructureError('Business Structure is required.');
    } else {
      setBusinessStructureError('');
    }

    if (showStateDropdown) {
      if (!state) {
        isValid = false;
        setStateError('If the checkbox above is selected, State is required.');
      } else {
        setStateError('');
      }
    } else {
      setStateError('');
    }

    return isValid;
  };

  const handleNext = (e) => {
    const validInfo = validateBusinessInfo();
    if (validInfo) {
      let businessInfo = {
        _id: currentBusiness?._id,
        name: name,
        ein: ein,
        businessStructure: businessStructure,
        signeeTitle: signeeTitle,
        address: address,
        additionalState: state,
      };
      if (currentBusiness?._id) {
        updateBusiness(businessInfo);
      } else {
        createBusiness(businessInfo);
      }
      handleEvent();
    }
  };

  const handleAddressChange = (e, newValue) => {
    if (newValue !== null) {
      setAddress(newValue.description);
    }
  };

  useEffect(() => {
    if (googleAddress !== '') {
      verifyAddress({ query: googleAddress });
    }
  }, [googleAddress]);

  const verifyAddress = (query) => {
    handleLoading(true);
    api
      .addressVerification(query)
      .then(function (response) {
        handleLoading(false);
        setAddressPredictions(response.data.results);
      })
      .catch(function (error) {
        console.error('error verifying address');
        console.error(error);
        handleLoading(false);
      });
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleBusinessStructureChange = (e) => {
    setBusinessStructure(e.target.value);
  };
  const handleEINChange = (e) => {
    let updatedEin = e.target.value;
    if (!updatedEin.includes('-') && updatedEin.length > 2) {
      const addDashes = (str) => str.replace(/^(.{2})/, '$1-');
      updatedEin = addDashes(e.target.value);
    }
    setEIN(updatedEin);
  };

  useEffect(() => {
    if (!currentBusiness) {
      api
        .getActiveBusiness()
        .then(({ data }) => {
          setCurrentBusiness(data[0]);
        })
        .catch(function (error) {
          console.error('error getting active business in customs onboarding');
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    if (currentBusiness !== undefined) {
      setEIN(currentBusiness.ein || '');
      setName(currentBusiness.name);
      setBusinessStructure(currentBusiness.businessStructure);
      setAddress(currentBusiness.address);
      setSigneeTitle(currentBusiness.signeeTitle);
    }
  }, [currentBusiness]);

  return (
    <div>
      <div className="business-info-header">
        <Typography>Business Info</Typography>
      </div>
      <div className="business-form-field-container">
        <div className="business-form-field-label">
          <Typography>Name</Typography>
        </div>
        <TextField
          fullWidth
          id="cargo-value"
          value={
            name
              ? name
                  .toLowerCase()
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')
              : ''
          }
          onChange={handleNameChange}
          error={!!nameError}
          helperText={nameError}
          name="cargo-value"
          size="small"
        />
      </div>
      <div className="ein-structure-section">
        <div className="business-form-field-container">
          <div className="business-form-field-label">
            <Typography>Employer Identification Number (EIN)</Typography>
          </div>
          <TextField
            fullWidth
            id="cargo-value"
            value={ein}
            onChange={handleEINChange}
            error={!!einError}
            helperText={einError}
            inputProps={{ maxLength: 10 }}
            name="cargo-value"
            size="small"
            autoComplete="off"
          />
        </div>
        <div className="business-form-field-container">
          <div className="business-form-field-label">
            <Typography>Structure</Typography>
          </div>
          <FormControl fullWidth>
            <Select
              id="demo-simple-select"
              value={businessStructure}
              fullWidth
              onChange={handleBusinessStructureChange}
              error={!!businessStructureError}
              size="small"
              autoComplete="off"
            >
              {Object.keys(BusinessStructure).map((structure, index) => {
                return (
                  <MenuItem key={index} value={BusinessStructure[structure]}>
                    {BusinessStructure[structure]}
                  </MenuItem>
                );
              })}
            </Select>
            {businessStructureError && <FormHelperText error>{businessStructureError}</FormHelperText>}
          </FormControl>
        </div>
      </div>
      <div className="business-form-field-container">
        <div className="business-form-field-label">
          <Typography>Address</Typography>
        </div>
        <Autocomplete
          fullWidth
          id="country-select-demo"
          size="small"
          options={addressPredictions}
          filterOptions={(options) => options}
          disablePortal={true}
          value={address}
          onChange={(event: any, newValue: any) => {
            handleAddressChange(event, newValue);
          }}
          inputValue={googleAddress}
          onInputChange={(event, newInputValue) => {
            setGoogleAddress(newInputValue);
          }}
          autoHighlight
          getOptionLabel={(option: any) => `${option}`}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {option.description}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
            />
          )}
        />
      </div>
      <div className="business-form-field-container">
        <div className="checkbox-container-address">
          Was your business formed in a different state than your business address?
          <Checkbox onChange={handleCheckedEvent} />
        </div>
      </div>
      {renderStatesDropdown(showStateDropdown)}
      <div className="next-button-container">
        <Button
          type="submit"
          color="info"
          size="medium"
          onClick={handleNext}
          variant="contained"
          className="next-button-green"
          endIcon={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
