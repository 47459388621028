import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControl, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import * as ls from 'local-storage';
import './BusinessCustomsInfo.css';
import { useAPI } from '@/api/APIContext';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { Business } from 'common/interfaces/business';
import { CustomsOnboardingSteps } from 'common/interfaces/customsOnboarding';
import LightTooltip from '../tooltip-component/TooltipComponent';
import InfoIcon from '@mui/icons-material/Info';

export default function BusinessCustomsInfo({
  handleEvent,
  handleBack,
  handleLoading,
  updateCustomsOnboarding,
  customsOnboarding,
}) {
  const api = useAPI();
  const theme = useTheme();
  const activeBusiness = useActiveBusiness();

  // Local states
  const [hasImporterBond, setHasImporterBond] = useState(undefined);
  const [paymentApproach, setPaymentApproach] = useState('');
  const [payerUnitNumber, setPayerUnitNumber] = useState(undefined);
  const [paymentCadence, setPaymentCadence] = useState(undefined);
  const [knowsPayerUnitNumber, setKnowsPayerUnitNumber] = useState(true);

  const paymentCadences = ['Daily Statement', 'Periodic Monthly Statement'];

  // -- Handlers --
  const handleImporterBondClick = (value) => {
    setHasImporterBond(value);
  };

  const handlePaymentApproachChange = (e) => {
    setPaymentApproach(e.target.value);
  };

  const handlePaymentCadenceChange = (e) => {
    setPaymentCadence(e.target.value);
  };

  const handlePayerUnitNumberChange = (e) => {
    setPayerUnitNumber(e.target.value);
  };

  // Reset payerUnitNumber if user toggles "Don't know it?"
  useEffect(() => {
    if (!knowsPayerUnitNumber) {
      setPayerUnitNumber(undefined);
    }
  }, [knowsPayerUnitNumber]);

  const handleNext = () => {
    const businessId = activeBusiness.business?._id?.toString();

    updateCustomsOnboarding(businessId, {
      step: CustomsOnboardingSteps.COMPLETE,
      hasImporterBond: hasImporterBond,
      paymentType: paymentApproach,
      knowsPayerUnitNumber: knowsPayerUnitNumber,
      ...(payerUnitNumber !== undefined && { payerUnitNumber }),
      ...(paymentCadence !== undefined && { paymentCadence }),
    });
    handleEvent();
  };

  // -- Tooltip Renderers --
  const renderCustomsBondToolTip = () => (
    <div className="payment-cadences-container">
      <div className="payment-cadences-header">What is a Customs Bond?</div>
      <div className="payment-cadence-type-header"></div>
      <div>
        <div>
          A Customs Bond is a legal contract that guarantees an importer will comply with customs regulations and pay
          any import duties, taxes, fines, or penalties. Importers cannot submit customs entries without one on file.
        </div>
      </div>
    </div>
  );

  const renderPunToolTip = () => (
    <div className="payment-cadences-container">
      <div className="payment-cadences-header">Payer Unit Number</div>
      <div className="payment-cadence-type-header"></div>
      <div>
        <div>This is a 6-digit number sent by U.S. CBP that designates your account as ACH approved.</div>
      </div>
    </div>
  );

  const renderPaymentCadenceToolTip = () => (
    <div className="payment-cadences-container">
      <div className="payment-cadences-header">Payment Cadences Breakdown</div>
      <div>
        <div className="payment-cadence-type-header">Daily Statement</div>
        <div>Duties, taxes, and fees are owed to CBP on the 10th business day after the entry is released.</div>
        <div className="payment-cadence-type-header">Periodic Monthly Statement</div>
        <div>
          Duties, taxes, and fees are owed to CBP on the 15th business day of the month after the entry is released.
        </div>
      </div>
    </div>
  );

  const renderBrokerOutlayAchToolTip = () => (
    <div className="payment-cadences-container">
      <div className="payment-cadences-header">Broker Outlay vs Direct Pay via ACH</div>
      <div>
        <div className="payment-cadence-type-header">Broker Outlay</div>
        <div>
          Importal can outlay duties, taxes and fees to U.S. CBP on your behalf, but please note there is a service
          charge of 3.5% assessed on the total cost of the outlay.
        </div>
        <div className="payment-cadence-type-header">Direct Pay via ACH</div>
        <div>
          Importers may apply to pay duties, taxes, and fees to CBP directly from their bank account via an ACH
          transaction. The application process takes 7-10 days to be processed.
        </div>
      </div>
    </div>
  );

  // -- Subheader logic --
  /**
   * This helper returns the proper subheader text depending on:
   *   1) The top-level "customsOnboarding?.hasImporterBond"
   *   2) The user’s local "hasImporterBond" selection.
   */
  const renderBondSubheaderText = (globalValue, localValue) => {
    // If from the server we know they have a bond:
    if (globalValue === true) {
      return (
        <Typography sx={{ color: theme.palette.primary.main, fontSize: '16px' }}>
          We queried Customs and saw that you have an active bond on file.
        </Typography>
      );
    }

    // If user selects "Yes"
    if (localValue === true) {
      return (
        <Typography sx={{ color: theme.palette.primary.main, fontSize: '16px' }}>
          Great, our brokers will confirm with U.S. Customs that your bond is active.
        </Typography>
      );
    }

    // If user selects "No"
    if (localValue === false) {
      return (
        <Typography sx={{ color: theme.palette.primary.main, fontSize: '16px' }}>
          Got it, one of our experts will be in touch with you.
        </Typography>
      );
    }

    // Default subheader (no selection yet)
    return (
      <Typography sx={{ fontSize: '14px' }}>
        If you do, we'll confirm with Customs on our end that it is still active. If not, no worries we'll help you get
        one.
      </Typography>
    );
  };

  // -- Bond Section --
  const renderCustomsBondSection = () => {
    const bondSubheader = renderBondSubheaderText(customsOnboarding?.hasImporterBond, hasImporterBond);

    // If the back end says they already have a bond, skip the checkbox flow entirely
    if (customsOnboarding?.hasImporterBond === true) {
      return (
        <div className="customs-question-container">
          <div className="title-question-header-container">
            <div className="customs-info-title-question-header">
              <div>Do you have a Customs Bond?</div>
              <LightTooltip title={renderCustomsBondToolTip()}>
                <div className="tooltip-summary-container">
                  <InfoIcon sx={{ fontSize: '13px' }} />
                </div>
              </LightTooltip>
            </div>
            <div className="title-question-subheader">{bondSubheader}</div>
          </div>
        </div>
      );
    }

    // Otherwise, show the original checkboxes + dynamic subheader
    return (
      <div className="customs-question-container">
        <div className="title-question-header-container">
          <div className="customs-info-title-question-header">
            <div>Do you have a Customs bond?</div>
            <LightTooltip title={renderCustomsBondToolTip()}>
              <div className="tooltip-summary-container">
                <InfoIcon sx={{ fontSize: '13px' }} />
              </div>
            </LightTooltip>
          </div>
          <div className="title-question-subheader">{bondSubheader}</div>
        </div>
        <div className="title-question-body">
          <div className="checkbox-container-title">
            Yes
            <Checkbox checked={hasImporterBond === true} onChange={() => handleImporterBondClick(true)} />
            No
            <Checkbox checked={hasImporterBond === false} onChange={() => handleImporterBondClick(false)} />
          </div>
        </div>
      </div>
    );
  };

  // -- Payer Unit Number Field --
  const renderPayerUnitNumberField = () => {
    if (knowsPayerUnitNumber) {
      return (
        <>
          <TextField
            fullWidth
            name="fullName"
            size="small"
            onChange={handlePayerUnitNumberChange}
            value={payerUnitNumber}
            inputProps={{ maxLength: 6 }}
          />
          <div className="dont-know-pun">
            <Button sx={{ textTransform: 'none' }} onClick={() => setKnowsPayerUnitNumber(false)} variant="text">
              Don't know it?
            </Button>
          </div>
        </>
      );
    }
    return (
      <div>
        <div className="pun-brokers-text">
          <Typography sx={{ fontSize: '14px', color: '#525256' }}>
            Our brokers will reach out to CBP to get that sent to you.
          </Typography>
        </div>
        <div className="dont-know-pun">
          <Button sx={{ textTransform: 'none' }} onClick={() => setKnowsPayerUnitNumber(true)} variant="text">
            I know my PUN
          </Button>
        </div>
      </div>
    );
  };

  const renderPayerUnitNumberSection = () => {
    if (paymentApproach === 'Direct Pay') {
      return (
        <>
          <div className="authorized-signer-email-header">Payer Unit Number (PUN) Information</div>
          <div className="pun-email-container">
            <div className="pun-form-field-container">
              <div className="business-form-field-label">
                <div className="business-form-field-label-content">
                  <Typography>Number</Typography>
                  <LightTooltip title={renderPunToolTip()}>
                    <div className="tooltip-summary-container">
                      <InfoIcon sx={{ fontSize: '13px' }} />
                    </div>
                  </LightTooltip>
                </div>
              </div>
              {renderPayerUnitNumberField()}
            </div>
            <div className="pun-form-field-container">
              <div className="customs-info-form-field-label">
                <Typography>Payment Cadence</Typography>
                <LightTooltip title={renderPaymentCadenceToolTip()}>
                  <div className="tooltip-summary-container">
                    <InfoIcon sx={{ fontSize: '13px' }} />
                  </div>
                </LightTooltip>
              </div>
              <FormControl fullWidth>
                <Select
                  id="demo-simple-select"
                  fullWidth
                  name="title"
                  onChange={handlePaymentCadenceChange}
                  value={paymentCadence}
                  size="small"
                >
                  {paymentCadences.map((title, index) => (
                    <MenuItem key={index} value={title}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </>
      );
    }
  };

  // -- RENDER / RETURN --
  return (
    <div>
      {/* 1) Render the Customs Bond Section */}
      {renderCustomsBondSection()}

      {/* 2) Payment Approach Section */}
      <div className="customs-question-container">
        <div className="title-question-header-container">
          <div className="customs-info-title-question-header">
            <div>How do you want to handle payment for duties and fees owed to U.S. CBP?</div>
            <LightTooltip sx={{ maxWidth: '500px!important' }} title={renderBrokerOutlayAchToolTip()}>
              <div className="tooltip-summary-container">
                <InfoIcon sx={{ fontSize: '13px' }} />
              </div>
            </LightTooltip>
          </div>
          <div className="title-question-subheader">With broker outlay, there is a disbursement fee associated.</div>
        </div>
        <div className="title-question-body">
          <div className="checkbox-container-title">
            Broker outlay
            <Checkbox
              value="Broker Outlay"
              checked={paymentApproach === 'Broker Outlay'}
              onChange={handlePaymentApproachChange}
            />
            Direct pay via ACH
            <Checkbox
              value="Direct Pay"
              checked={paymentApproach === 'Direct Pay'}
              onChange={handlePaymentApproachChange}
            />
          </div>
        </div>
        {renderPayerUnitNumberSection()}
      </div>

      {/* 3) Navigation Buttons */}
      <div className="next-button-container">
        <Button fullWidth className="back-button-text" variant="text" onClick={handleBack}>
          Back
        </Button>
        <Button
          type="submit"
          color="info"
          size="medium"
          onClick={handleNext}
          variant="contained"
          className="next-button-green"
        >
          Finish
        </Button>
      </div>
    </div>
  );
}
