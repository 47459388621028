import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, TextField, Tooltip, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Check, Edit, Link as LinkIcon, LinkOff } from '@mui/icons-material';
import { DenormalizedTradeEntityWithConnection, EmptyTradeEntity, TradeEntity } from 'common/interfaces/tradeEntity';
import { Mode } from 'common/interfaces/business';
import AddOrEditTradeEntityModal, {
  TradeEntityAction,
} from '@/shared-components/TradeEntities/AddOrEditTradeEntityModal';
import Autocomplete from '@mui/material/Autocomplete';
import { useAPI } from '@/api/APIContext';
import HoverState from '@/shared-components/HoverState'; // import your Mode enum (VIEW/EDIT/etc.)

export interface EntryInvoiceTradeEntityProps {
  tradeEntityTitle: string;
  tradeEntity: DenormalizedTradeEntityWithConnection;
  onTradeEntityChanged: (updated: DenormalizedTradeEntityWithConnection) => void;
}

export function EntryInvoiceTradeEntity({
  tradeEntityTitle,
  tradeEntity,
  onTradeEntityChanged,
}: EntryInvoiceTradeEntityProps) {
  const theme = useTheme();

  const [componentMode, setComponentMode] = useState<Mode>(Mode.VIEW);

  const [addOrEditTradeEntityOpen, setAddOrEditTradeEntityOpen] = useState(false);
  const [tradeEntityIDForViewingInModal, setTradeEntityIdForViewInModal] = useState<string | undefined>();

  // For convenience, figure out whether or not we have a "linked" trade entity
  // const isLinked = !!tradeEntityUnderEdit.tradeEntityConnection;
  const [showSelectTradeEntity, setShowSelectTradeEntity] = useState(false);
  useEffect(() => {
    setShowSelectTradeEntity(!!tradeEntityUnderEdit.tradeEntityConnection);
  }, []);

  // Local copy: changes are stored here until user clicks "Save"
  const [tradeEntityUnderEdit, setTradeEntityUnderEdit] = useState<DenormalizedTradeEntityWithConnection>(tradeEntity);

  // Keep local copy in sync if parent tradeEntity changes
  useEffect(() => {
    setTradeEntityUnderEdit(tradeEntity);
    setShowSelectTradeEntity(!!tradeEntityUnderEdit.tradeEntityConnection);
  }, [tradeEntity]);

  // Cancel editing: revert local changes back to original prop
  const handleCancel = () => {
    setTradeEntityUnderEdit(tradeEntity);
    setComponentMode(Mode.VIEW);
  };

  // Save editing: push local changes to parent
  const handleSave = () => {
    onTradeEntityChanged(tradeEntityUnderEdit);
    setComponentMode(Mode.VIEW);
  };

  return (
    <HoverState>
      {(isHovered, bindHover) => (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} {...bindHover}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ height: '32px', marginBottom: 1 }}>
              <b>{tradeEntityTitle}</b>
            </div>
            {componentMode === Mode.VIEW && isHovered && (
              <div>
                <Tooltip title={'Edit'}>
                  <IconButton size="small" onClick={() => setComponentMode(Mode.EDIT)}>
                    <Edit sx={{ fontSize: '14px' }} />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            {componentMode === Mode.EDIT && (
              <div>
                <Tooltip title={'Discard Changes'}>
                  <IconButton size="small" sx={{ marginBottom: 1 }} onClick={handleCancel}>
                    <CloseIcon sx={{ fontSize: '14px' }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title={'Save Changes'}>
                  <IconButton size="small" sx={{ marginBottom: 1 }} onClick={handleSave}>
                    <Check sx={{ fontSize: '14px' }} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <Box
            sx={{
              width: '100%',
              mb: 2,
              border: '1px solid #ccc',
              p: 2,
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              {componentMode === Mode.VIEW && (
                <>
                  {showSelectTradeEntity && (
                    <Tooltip title={'Linked to Trade Entity'}>
                      <LinkIcon sx={{ color: theme.palette.primary.main, fontSize: '14px' }} />
                    </Tooltip>
                  )}
                  {!showSelectTradeEntity && (
                    <Tooltip title={'Not Linked to a Trade Entity'}>
                      <LinkOff sx={{ fontSize: '14px' }} />
                    </Tooltip>
                  )}
                </>
              )}
              {componentMode === Mode.EDIT && (
                <>
                  {showSelectTradeEntity && (
                    <SmallGreenTextButton
                      text={'Show Text Fields'}
                      onClick={() => {
                        setShowSelectTradeEntity(false);
                      }}
                    />
                  )}
                  {!showSelectTradeEntity && (
                    <SmallGreenTextButton
                      text={'Show Trade Entity Selection'}
                      onClick={() => {
                        setShowSelectTradeEntity(true);
                      }}
                    />
                  )}
                </>
              )}
            </div>
            <div style={{ minWidth: '320px', maxWidth: '430px' }} className={'trade-entity-datapoints'}>
              {showSelectTradeEntity && (
                <>
                  {componentMode === Mode.VIEW && (
                    <>
                      <div>
                        <b>Name:</b> {tradeEntityUnderEdit.name}
                      </div>
                      <div>
                        <b>Address Line 1:</b> {tradeEntityUnderEdit.address.line1}
                      </div>
                      <div>
                        <b>City:</b> {tradeEntityUnderEdit.address.city}
                      </div>
                      <div>
                        <b>State:</b> {tradeEntityUnderEdit.address.stateProvince}
                      </div>
                      <div>
                        <b>Postal:</b> {tradeEntityUnderEdit.address.postalCode}
                      </div>
                      <div>
                        <b>Country:</b> {tradeEntityUnderEdit.address.country}
                      </div>
                    </>
                  )}
                  {componentMode === Mode.EDIT && (
                    <div>
                      <TradeEntityAutocomplete
                        tradeEntity={tradeEntityUnderEdit}
                        setTradeEntity={(tradeEntity) => {
                          if (tradeEntity) {
                            setTradeEntityUnderEdit({
                              ...tradeEntity,
                              tradeEntityConnection: tradeEntity._id,
                            });
                          } else {
                            setTradeEntityUnderEdit(EmptyTradeEntity);
                          }
                        }}
                      />

                      {/*<div style={{*/}
                      {/*    display: 'flex',*/}
                      {/*    justifyContent: 'end',*/}
                      {/*    gap: '16px',*/}
                      {/*    marginRight: 4*/}
                      {/*}}>*/}
                      {/*    <SmallGreenTextButton*/}
                      {/*        text={`Edit`}*/}
                      {/*        onClick={() => {*/}
                      {/*            setTradeEntityIdForViewInModal(tradeEntity.tradeEntityConnection?.toString());*/}
                      {/*            setAddOrEditTradeEntityOpen(true);*/}
                      {/*        }}*/}
                      {/*    />*/}

                      {/*    <SmallGreenTextButton*/}
                      {/*        text={'Clear'}*/}
                      {/*        onClick={() => {*/}
                      {/*            setTradeEntityUnderEdit(EmptyTradeEntity);*/}
                      {/*        }}*/}
                      {/*    />*/}
                      {/*</div>*/}
                    </div>
                  )}
                </>
              )}
              {!showSelectTradeEntity && (
                <>
                  {componentMode === Mode.VIEW && (
                    <>
                      <div>
                        <b>Name:</b> {tradeEntityUnderEdit.name}
                      </div>
                      <div>
                        <b>Address Line 1:</b> {tradeEntityUnderEdit.address.line1}
                      </div>
                      <div>
                        <b>City:</b> {tradeEntityUnderEdit.address.city}
                      </div>
                      <div>
                        <b>State:</b> {tradeEntityUnderEdit.address.stateProvince}
                      </div>
                      <div>
                        <b>Postal:</b> {tradeEntityUnderEdit.address.postalCode}
                      </div>
                      <div>
                        <b>Country:</b> {tradeEntityUnderEdit.address.country}
                      </div>
                    </>
                  )}
                  {componentMode === Mode.EDIT && (
                    <>
                      <TextField
                        label="Name"
                        variant="standard"
                        size="small"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={tradeEntityUnderEdit.name}
                        onChange={(e) =>
                          setTradeEntityUnderEdit({
                            ...tradeEntityUnderEdit,
                            name: e.target.value,
                          })
                        }
                      />

                      <TextField
                        label="Address 1"
                        variant="standard"
                        size="small"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={tradeEntityUnderEdit.address.line1}
                        onChange={(e) =>
                          setTradeEntityUnderEdit({
                            ...tradeEntityUnderEdit,
                            address: {
                              ...tradeEntityUnderEdit.address,
                              line1: e.target.value,
                            },
                          })
                        }
                      />

                      <TextField
                        label="City"
                        variant="standard"
                        size="small"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={tradeEntityUnderEdit.address.city || ''}
                        onChange={(e) =>
                          setTradeEntityUnderEdit({
                            ...tradeEntityUnderEdit,
                            address: {
                              ...tradeEntityUnderEdit.address,
                              city: e.target.value,
                            },
                          })
                        }
                      />

                      <TextField
                        label="State"
                        variant="standard"
                        size="small"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={tradeEntityUnderEdit.address.stateProvince || ''}
                        onChange={(e) =>
                          setTradeEntityUnderEdit({
                            ...tradeEntityUnderEdit,
                            address: {
                              ...tradeEntityUnderEdit.address,
                              stateProvince: e.target.value,
                            },
                          })
                        }
                      />

                      <TextField
                        label="Postal Code"
                        variant="standard"
                        size="small"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={tradeEntityUnderEdit.address.postalCode || ''}
                        onChange={(e) =>
                          setTradeEntityUnderEdit({
                            ...tradeEntityUnderEdit,
                            address: {
                              ...tradeEntityUnderEdit.address,
                              postalCode: e.target.value,
                            },
                          })
                        }
                      />

                      <TextField
                        label="Country"
                        variant="standard"
                        size="small"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={tradeEntityUnderEdit.address.country || ''}
                        onChange={(e) =>
                          setTradeEntityUnderEdit({
                            ...tradeEntityUnderEdit,
                            address: {
                              ...tradeEntityUnderEdit.address,
                              country: e.target.value,
                            },
                          })
                        }
                      />

                      {!tradeEntityUnderEdit.tradeEntityConnection && (
                        <SmallGreenTextButton
                          text={`Add Trade Entity: "${tradeEntityUnderEdit.name}"`}
                          onClick={() => {
                            setTradeEntityIdForViewInModal(tradeEntity.tradeEntityConnection?.toString());
                            setAddOrEditTradeEntityOpen(true);
                          }}
                        />
                      )}
                      {tradeEntityUnderEdit.tradeEntityConnection && (
                        <SmallGreenTextButton
                          text={`Edit Trade Entity: "${tradeEntityUnderEdit.name}"`}
                          onClick={() => {
                            setTradeEntityIdForViewInModal(tradeEntity.tradeEntityConnection?.toString());
                            setAddOrEditTradeEntityOpen(true);
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Box>
          <AddOrEditTradeEntityModal
            open={addOrEditTradeEntityOpen}
            onClose={() => {
              setAddOrEditTradeEntityOpen(false);
            }}
            tradeEntityId={tradeEntityIDForViewingInModal}
            onTradeEntityCreatedOrModified={(tradeEntityId: string, action: TradeEntityAction) => {
              if (action === TradeEntityAction.CREATED) {
                // @ts-ignore
                // setTradeEntityUnderEdit()
                onTradeEntityChanged({ ...tradeEntityUnderEdit, tradeEntityConnection: tradeEntityId });
                setComponentMode(Mode.VIEW);
              }
              console.log(`performed action: ${action} on entity with id: ${tradeEntityId}`);
            }}
            draftStateTradeEntity={tradeEntityUnderEdit}
          />
        </div>
      )}
    </HoverState>
  );
}

interface TradeEntityAutocompleteProps {
  tradeEntity?: TradeEntity;
  setTradeEntity: (arg0: TradeEntity | null) => void;
}

export function TradeEntityAutocomplete({ tradeEntity, setTradeEntity }: TradeEntityAutocompleteProps) {
  const api = useAPI();
  const [tradeEntities, setTradeEntities] = useState<Array<TradeEntity>>([]);

  useEffect(() => {
    api
      .getAllTradeEntities()
      .then(({ data }) => {
        setTradeEntities(data);
      })
      .catch((err) => {
        console.error('error getting all trade entities', err);
      });
  }, []);

  return (
    <Autocomplete
      // sx={{ minWidth: 164 }}
      options={tradeEntities}
      value={tradeEntity}
      // disableClearable
      isOptionEqualToValue={(a, b) => {
        const aHasId = !!a._id?.toString();
        const bHadId = !!b._id?.toString();
        return Boolean(aHasId && bHadId && a._id!.toString() === b._id!.toString());
      }}
      onChange={(e, newVal) => {
        setTradeEntity(newVal);
      }}
      // So MUI can display/filter by text if user starts typing (though we're mostly hiding typed text):
      getOptionLabel={(option) => option.name}
      // Renders the dropdown items as colored chips:
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <div>{option.name}</div>
        </Box>
      )}
      // Renders the "input" area:
      renderInput={(params) => (
        <TextField
          {...params}
          // placeholder={placeholder}
          fullWidth
          sx={{
            backgroundColor: 'transparent',
            '& .MuiOutlinedInput-root': {
              padding: '4px 4px 4px 8px !important',
              fontSize: '12px',
              '& fieldset': {
                border: 'none',
                borderRadius: '8px',
              },
            },
            '& .MuiInputBase-input': {
              fontSize: '12px',
            },
          }}
        />
      )}
    />
  );
}

export interface SmallGreenTextButtonProps {
  text: string;
  onClick: () => void;
}

export function SmallGreenTextButton({ text, onClick }: SmallGreenTextButtonProps) {
  return (
    <Button
      size="small"
      variant="text"
      onClick={onClick}
      sx={{
        textTransform: 'none',
        padding: 0,
        minWidth: 'auto',
        color: 'green',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
          background: 'transparent',
        },
      }}
    >
      {text}
    </Button>
  );
}
