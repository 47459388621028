import * as d3 from 'd3';
import { useMemo } from 'react';

interface Props {
  axisLabel: string;
  categories: string[];
  height: number;
  showAxisLine?: boolean; // Toggle for axis line
  axisLabelFontSize: string;
}

export const CategoricalYAxis = ({
  axisLabel,
  categories = [],
  height,
  showAxisLine = false,
  axisLabelFontSize,
}: Props) => {
  // 1) Compute ticks
  const ticks = useMemo(() => {
    const yScale = d3.scaleBand().range([0, height]).domain(categories).padding(0.1);

    return categories.map((category) => ({
      category,
      yOffset: yScale(category)! + yScale.bandwidth() / 2,
    }));
  }, [categories, height]);

  const getTextWidth = (text: string, font = '16px Arial') => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return 0;
    context.font = font;
    return context.measureText(text).width;
  };

  const maxCategoryText = useMemo(() => {
    return categories.reduce((longest, category) => (category.length > longest.length ? category : longest), '');
  }, [categories]);

  const estimatedCategoryWidth = useMemo(() => {
    return getTextWidth(maxCategoryText, axisLabelFontSize); // Adjust font as needed
  }, [maxCategoryText]);

  return (
    <>
      {/* Optional axis line */}
      {showAxisLine && <path d={`M 0 0 V ${height}`} fill="none" stroke="#E5E5EF" />}

      {/* Tick labels */}
      {ticks.map(({ category, yOffset }) => (
        <g key={category} transform={`translate(0, ${yOffset})`}>
          <text
            fill="#525256"
            style={{
              fontSize: '13px',
              fontWeight: '500',
              textAnchor: 'end',
              dominantBaseline: 'middle',
            }}
            x={-10} // Move label left
          >
            {category}
          </text>
        </g>
      ))}

      {/* Axis label */}
      {axisLabel && (
        <text
          fill="#525256"
          textAnchor="middle"
          dominantBaseline="hanging"
          transform={`translate(-100, ${height / 2}) rotate(-90)`} // Adjusted for spacing
          style={{ fontSize: axisLabelFontSize, fontWeight: '600' }}
          y={-estimatedCategoryWidth + 70}
        >
          {axisLabel}
        </text>
      )}
    </>
  );
};
