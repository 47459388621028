import React from 'react';
import { Box, Card, Grid, TextField, Typography } from '@mui/material';
import ManageItems, { ManageItemsConfig } from '@/shared-components/ManageItems/ManageItems';
import {
  EntryManifestBolInfo,
  EntryManifestBolLineItem,
  EntryManifestContainerLineItem,
} from 'common/interfaces/entry';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { ManageItemsStyles } from '@/broker-app/pages/shipments/ManageItemsStyles';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
  header: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: '16px',
    paddingBottom: '2px',
    borderBottom: '1px solid #D1D1D1',
    marginBottom: '8px',
  },
  fieldLabel: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: 'black',
    marginRight: '16px',
  },
  fieldValue: {
    fontSize: '12px',
    color: '#525256',
    marginRight: '16px',
  },
  itemsContainer: {
    marginTop: '16px',
  },
};

// Configurations for BOL Line Items in ManageItems
const bolLineItemsConfig: ManageItemsConfig = {
  columns: [
    {
      header: 'Inbond No.',
      viewComponent: (item) => <>{item.inbondNo}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.inbondNo}
          onChange={(e) => setItem({ ...item, inbondNo: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Master SCAC',
      viewComponent: (item) => <>{item.masterScac}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.masterScac}
          onChange={(e) => setItem({ ...item, masterScac: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Master Bill #',
      viewComponent: (item) => <>{item.masterBillNumber}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.masterBillNumber}
          onChange={(e) => setItem({ ...item, masterBillNumber: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'House SCAC',
      viewComponent: (item) => <>{item.houseScac}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.houseScac}
          onChange={(e) => setItem({ ...item, houseScac: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          value={item.houseScac}
          onChange={(e) => setItem({ ...item, houseScac: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'House Bill #',
      viewComponent: (item) => <>{item.houseBillNumber}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.houseBillNumber}
          onChange={(e) => setItem({ ...item, houseBillNumber: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          value={item.houseBillNumber}
          onChange={(e) => setItem({ ...item, houseBillNumber: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Quantity',
      viewComponent: (item) => <>{item.quantity}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.quantity}
          onChange={(e) => setItem({ ...item, quantity: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          value={item.quantity}
          onChange={(e) => setItem({ ...item, quantity: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Units',
      viewComponent: (item) => <>{item.units}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.units}
          onChange={(e) => setItem({ ...item, units: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          value={item.units}
          onChange={(e) => setItem({ ...item, units: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
  ],
};

// Configurations for Container Line Items in ManageItems
const containerLineItemsConfig: ManageItemsConfig = {
  columns: [
    {
      header: 'Container #',
      viewComponent: (item) => <>{item.containerNumber}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.containerNumber}
          onChange={(e) => setItem({ ...item, containerNumber: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          value={item.containerNumber}
          onChange={(e) => setItem({ ...item, containerNumber: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Container Size',
      viewComponent: (item) => <>{item.containerSize}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.containerSize}
          onChange={(e) => setItem({ ...item, containerSize: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          value={item.containerSize}
          onChange={(e) => setItem({ ...item, containerSize: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Container Type',
      viewComponent: (item) => <>{item.containerType}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.containerType}
          onChange={(e) => setItem({ ...item, containerType: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          value={item.containerType}
          onChange={(e) => setItem({ ...item, containerType: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Seal Number',
      viewComponent: (item) => <>{item.sealNumber}</>,
      editComponent: (item, setItem) => (
        <TextField
          value={item.sealNumber}
          onChange={(e) => setItem({ ...item, sealNumber: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
      addComponent: (item, setItem) => (
        <TextField
          value={item.sealNumber}
          onChange={(e) => setItem({ ...item, sealNumber: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
  ],
};

interface EntryManifestBolInfoProps {
  entryManifestBolInfo: EntryManifestBolInfo;
  onEntryManifestBOLInfoChanged: (arg0: EntryManifestBolInfo) => void;
}

const EntryManifestBolInfoCard: React.FC<EntryManifestBolInfoProps> = ({
  entryManifestBolInfo,
  onEntryManifestBOLInfoChanged,
}) => {
  if (!entryManifestBolInfo) {
    return <div> no entry manifest bol info </div>;
  }

  return (
    <Card sx={styles.container}>
      <Typography sx={styles.header}>Manifest / BOL Info</Typography>
      <Grid container spacing={3} alignItems="center" sx={{ marginBottom: '16px' }}>
        <Grid item>
          <Typography sx={styles.fieldLabel}>Split Shipment & Non-AMS Bills</Typography>
          <Typography sx={styles.fieldValue}>{entryManifestBolInfo.splitShipmentAndOrNonAmsBills}</Typography>
        </Grid>
      </Grid>
      <Box sx={styles.itemsContainer}>
        <ManageItems<EntryManifestBolLineItem>
          manageItemsConfig={bolLineItemsConfig}
          items={entryManifestBolInfo.bolLineItems}
          getDefaultItem={() => ({
            inbondNo: '',
            masterScac: '',
            masterBillNumber: '',
            houseScac: '',
            houseBillNumber: '',
            subhouseBillNumber: '',
            quantity: '',
            units: '',
          })}
          onItemsUpdated={(bolLineItems) => {
            onEntryManifestBOLInfoChanged({ ...entryManifestBolInfo, bolLineItems });
          }}
          parentName={'Entry'}
          styles={ManageItemsStyles}
          itemName="Bill of Ladings"
          useDeleteConfirmationModal={true}
          parentLoadingStatus={LoadingStatus.NOT_LOADING}
        />
      </Box>
      <Box sx={styles.itemsContainer}>
        <ManageItems<EntryManifestContainerLineItem>
          manageItemsConfig={containerLineItemsConfig}
          items={entryManifestBolInfo.containerLineItems}
          getDefaultItem={() => ({
            containerNumber: '',
            containerSize: '',
            containerType: '',
            sealNumber: '',
            poNumber: '',
            poDescriptor: '',
            weight: '',
            weightUnit: '',
            quantity: '',
            units: '',
          })}
          onItemsUpdated={(containerLineItems) => {
            onEntryManifestBOLInfoChanged({ ...entryManifestBolInfo, containerLineItems });
          }}
          itemName="Containers"
          parentName={'Entry'}
          useDeleteConfirmationModal={true}
          styles={ManageItemsStyles}
          parentLoadingStatus={LoadingStatus.NOT_LOADING}
        />
      </Box>
    </Card>
  );
};

export default EntryManifestBolInfoCard;
