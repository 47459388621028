import { EntryInvoice, EntryInvoiceLineItem } from 'common/interfaces/entry';
import React, { useContext, useState } from 'react';
import { CommercialInvoiceLineItem } from 'common/interfaces/documentParsing';
import ManageItems, { ManageItemsConfig } from '@/shared-components/ManageItems/ManageItems';
import { Box, Card, IconButton, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { EntryPrepTabContext } from '@/broker-app/pages/shipments/EntryPrepTab';
import { DatePickerWithEditMode, TextFieldWithEditMode } from '@/broker-app/pages/shipments/EntryPrepMisc';
import { ManageItemsStyles } from '@/broker-app/pages/shipments/ManageItemsStyles';
import { EntryInvoiceTradeEntity } from '@/broker-app/pages/shipments/EntryInvoiceTradeEntity';

interface Props {
  entryInvoice: EntryInvoice;
  onEntryInvoiceChanged: (arg0: EntryInvoice) => void;
}

export default function EntryInvoiceView({ entryInvoice, onEntryInvoiceChanged }: Props) {
  const { loadingStatus, setLoadingStatus } = useContext(EntryPrepTabContext);
  const [modifyMode, setModifyMode] = useState<{ [key: string]: boolean }>({
    importer: false,
    supplier: false,
    manufacturer: false,
    invoiceNumber: false,
    invoiceDate: false,
  });

  const handleEditClick = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: true }));
  };

  const handleMouseEnter = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: true }));
  };

  const handleMouseLeave = (field: string) => {
    setModifyMode((prev) => ({ ...prev, [field]: false }));
  };

  const renderCommercialInvoiceField = (field: string, displayValue: string) => {
    const isHovered = modifyMode[field];

    return (
      <div
        onMouseEnter={() => handleMouseEnter(field)}
        onMouseLeave={() => handleMouseLeave(field)}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div style={{ flex: 1 }}>{displayValue}</div>
        {isHovered && (
          <IconButton
            onClick={() => setModifyMode((prev) => ({ ...prev, [field]: true }))}
            size="small"
            sx={{ padding: '0px', marginLeft: '2px' }}
          >
            <EditIcon style={{ fontSize: '14px' }} />
          </IconButton>
        )}
      </div>
    );
  };

  return (
    <Card>
      <Box style={styles.parsedViewContainer}>
        <div
          style={{
            display: 'flex',
            color: 'black',
            gap: '32px',
            marginBottom: '24px',
          }}
        >
          <div style={{ fontSize: '12px' }}>
            <b>Invoice Number</b>
            <div style={{ color: '#525256' }}>
              <TextFieldWithEditMode
                value={entryInvoice.invoiceNumber}
                onSaveClicked={(invoiceNumber) => {
                  onEntryInvoiceChanged({ ...entryInvoice, invoiceNumber });
                }}
              />
            </div>

            <br />
            <b>Invoice Date</b>
            <div style={{ color: '#525256' }}>
              <DatePickerWithEditMode
                value={entryInvoice.invoiceDate}
                onSaveClicked={(invoiceDate) => {
                  if (invoiceDate) {
                    onEntryInvoiceChanged({ ...entryInvoice, invoiceDate });
                  } else {
                    console.error('no invoice date');
                  }
                }}
              />
            </div>
          </div>

          <div style={{ display: 'flex', gap: '16px', fontSize: '12px' }} className={'supplier-manufacturer-section'}>
            <EntryInvoiceTradeEntity
              tradeEntityTitle={'Supplier'}
              tradeEntity={entryInvoice.supplier}
              onTradeEntityChanged={(supplier) => {
                onEntryInvoiceChanged({ ...entryInvoice, supplier });
              }}
            />
            <EntryInvoiceTradeEntity
              tradeEntityTitle={'Manufacturer'}
              tradeEntity={entryInvoice.manufacturer}
              onTradeEntityChanged={(manufacturer) => {
                onEntryInvoiceChanged({ ...entryInvoice, manufacturer });
              }}
            />
          </div>
        </div>

        <ManageItems<EntryInvoiceLineItem>
          manageItemsConfig={manageEntryInvoiceLineItemsConfig}
          items={entryInvoice.lineItems}
          getDefaultItem={() => ({
            poNumber: '',
            sku: '',
            description: '',
            materialComposition: '',
            unitQuantity: '',
            unitValue: '',
            currencyCode: '',
            totalValue: '',
            countryOfOrigin: '',
            additionalProperties: {},
            containerNumber: '',
            htsno: '',
            unitWeight: '',
            netWeight: '',
            grossWeight: '',
            isConsolidated: false,
          })}
          itemName=""
          styles={ManageItemsStyles}
          // useDynamicColumnHeader={true}
          useDeleteConfirmationModal={true}
          onItemsUpdated={(items) => {
            onEntryInvoiceChanged?.({ ...entryInvoice, lineItems: items });
          }}
          parentLoadingStatus={loadingStatus}
        />
        <div
          style={{
            fontWeight: 'bold',
            color: 'black',
            fontSize: '12px',
            padding: '6px',
            marginTop: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            border: '1px solid lightgrey',
            borderRadius: '4px',
          }}
        >
          <div style={{ paddingRight: '32px', borderRight: '1px solid lightgrey' }}>Total</div>
          <div>{entryInvoice.totalValue}</div>
        </div>
      </Box>
    </Card>
  );
}

const styles = {
  parsedViewContainer: {
    alignItems: 'center',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
  parsedViewHeader: {
    marginBottom: '2px',
    marginTop: '0px',
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
  },
  parsedViewSubheader: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
    color: '#525256',
  },
  parsedViewSummarySection: {
    display: 'flex',
    width: '100%',
    fontSize: '12px',
    marginTop: '16px',
    gap: '24px',
  },
  parsedViewSummarySectionColumn: {
    flex: 1,
    position: 'relative' as 'relative',
  },
  parsedViewSummarySectionColumnHeader: {
    fontWeight: 'bold',
    color: 'black',
  },
  fieldContainer: {
    fontSize: '12px',
    color: '#525256',
    position: 'relative' as 'relative',
    display: 'inline-block',
  },
  editIconButton: {
    position: 'absolute' as 'absolute',
    right: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '16px',
  },
  importerAddressSpan: {
    marginRight: '3px',
    whiteSpace: 'nowrap',
  },
  parsedViewSubheaderContent: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const manageEntryInvoiceLineItemsConfig: ManageItemsConfig = {
  columns: [
    {
      header: 'PO #',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.poNumber}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.poNumber}
          onChange={(e) => setItem({ ...item, poNumber: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Container #',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.containerNumber}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.containerNumber}
          onChange={(e) => setItem({ ...item, containerNumber: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'SKU',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.sku}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.sku}
          onChange={(e) => setItem({ ...item, sku: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Description',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.description}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.description}
          onChange={(e) => setItem({ ...item, description: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'HTS',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.htsno}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.htsno}
          onChange={(e) => setItem({ ...item, htsno: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Quantity',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.unitQuantity}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.unitQuantity}
          onChange={(e) => setItem({ ...item, unitQuantity: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Unit Value',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.unitValue}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.unitValue}
          onChange={(e) => setItem({ ...item, unitValue: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Total Value',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.totalValue}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.totalValue}
          onChange={(e) => setItem({ ...item, totalValue: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'COO',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.countryOfOrigin}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.countryOfOrigin}
          onChange={(e) => setItem({ ...item, countryOfOrigin: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Net Wt.',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.netWeight}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.netWeight}
          onChange={(e) => setItem({ ...item, netWeight: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
    {
      header: 'Gross Wt.',
      viewComponent: (item: CommercialInvoiceLineItem) => <>{item.grossWeight}</>,
      editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
        <TextField
          value={item.grossWeight}
          onChange={(e) => setItem({ ...item, grossWeight: e.target.value })}
          fullWidth
          size="small"
          variant="standard"
          sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
        />
      ),
    },
  ],
};
