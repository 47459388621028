import HoverState from '@/shared-components/HoverState';
import React, { useState } from 'react';
import { Mode } from 'common/interfaces/business';
import { AutocompleteProps, IconButton, MenuItem, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { CSSObject } from '@mui/system';
import ImportalDatePicker from '@/shared-components/ImportalDatePicker/ImportalDatePicker';
import { formatMMMDDYYYYDate } from '@/utils/activePlanUtils';
import Autocomplete from '@mui/material/Autocomplete';

interface TextFieldWithEditModeProps {
  value: string;
  onSaveClicked: (arg0: string) => void;
  viewModeProps?: CSSObject;
  viewElementOverride?: React.JSX.Element;
}

export function TextFieldWithEditMode({
  value,
  onSaveClicked,
  viewModeProps,
  viewElementOverride,
}: TextFieldWithEditModeProps) {
  const [valueUnderEdit, setValueUnderEdit] = useState(value);

  const [mode, setMode] = useState(Mode.VIEW);

  const handleSave = () => {
    onSaveClicked(valueUnderEdit);
    setMode(Mode.VIEW);
  };

  return (
    <HoverState>
      {(isHovered, bindHover) => (
        <div style={{ display: 'flex', flex: 1, ...viewModeProps }} {...bindHover}>
          {mode === Mode.VIEW && (
            <>{value ? <span>{viewElementOverride ? viewElementOverride : value}</span> : <span> EMPTY </span>}</>
          )}

          {mode === Mode.EDIT && (
            <TextField
              variant="standard"
              InputProps={{ style: { fontSize: 12 } }}
              value={valueUnderEdit}
              onChange={(e) => {
                setValueUnderEdit(e.target.value);
              }}
            ></TextField>
          )}

          {isHovered && mode === Mode.VIEW && (
            <IconButton onClick={() => setMode(Mode.EDIT)} size="small" sx={{ padding: '0px', marginLeft: '2px' }}>
              <EditIcon style={{ fontSize: '14px' }} />
            </IconButton>
          )}
          {mode === Mode.EDIT && (
            <>
              <IconButton size="small" sx={{ padding: '0px', marginLeft: '2px' }} onClick={() => setMode(Mode.VIEW)}>
                <CloseIcon style={{ fontSize: '14px' }} />
              </IconButton>
              <IconButton onClick={handleSave} size="small" sx={{ padding: '0px', marginLeft: '2px' }}>
                <CheckIcon style={{ fontSize: '14px' }} />
              </IconButton>
            </>
          )}
        </div>
      )}
    </HoverState>
  );
}

interface DatePickerWithEditModeProps {
  value?: Date;
  onSaveClicked: (newValue?: Date) => void;
  viewModeProps?: CSSObject;
}

export function DatePickerWithEditMode({ value, onSaveClicked, viewModeProps }: DatePickerWithEditModeProps) {
  // Convert the incoming string to a Date or null.
  const [valueUnderEdit, setValueUnderEdit] = useState<Date | null>(value ? new Date(value) : null);

  const [mode, setMode] = useState<Mode>(Mode.VIEW);

  const handleSave = () => {
    // Convert the Date back to a string or any format you need
    if (valueUnderEdit) {
      onSaveClicked(valueUnderEdit);
    } else {
      // If no date, pass back empty or handle as you see fit
      onSaveClicked(undefined);
    }
    setMode(Mode.VIEW);
  };

  return (
    <HoverState>
      {(isHovered, bindHover) => (
        <div style={{ display: 'flex', flex: 1, ...viewModeProps }} {...bindHover}>
          {/* VIEW mode */}
          {mode === Mode.VIEW && <span>{value ? formatMMMDDYYYYDate(value.toString()) : 'EMPTY'}</span>}

          {/* EDIT mode */}
          {mode === Mode.EDIT && (
            <ImportalDatePicker
              label=""
              date={valueUnderEdit}
              setDate={setValueUnderEdit}
              sx={{ '& .MuiInputBase-input': { fontSize: 12 } }}
            />
          )}

          {/* Icons in VIEW mode (only show if hovered) */}
          {isHovered && mode === Mode.VIEW && (
            <IconButton onClick={() => setMode(Mode.EDIT)} size="small" sx={{ padding: '0px', marginLeft: '2px' }}>
              <EditIcon style={{ fontSize: '14px' }} />
            </IconButton>
          )}

          {/* Icons in EDIT mode (cancel / save) */}
          {mode === Mode.EDIT && (
            <>
              <IconButton size="small" sx={{ padding: '0px', marginLeft: '2px' }} onClick={() => setMode(Mode.VIEW)}>
                <CloseIcon style={{ fontSize: '14px' }} />
              </IconButton>
              <IconButton onClick={handleSave} size="small" sx={{ padding: '0px', marginLeft: '2px' }}>
                <CheckIcon style={{ fontSize: '14px' }} />
              </IconButton>
            </>
          )}
        </div>
      )}
    </HoverState>
  );
}

export interface SelectWithEditModeProps {
  value?: any;
  onSaveClicked: (arg0: any) => void;
  options: Array<any>; // hmm...
  optionToDisplayText: Record<any, string>;
  viewModeProps?: CSSObject;
}

export function SelectWithEditMode({
  value,
  onSaveClicked,
  options,
  optionToDisplayText,
  viewModeProps,
}: SelectWithEditModeProps) {
  // Convert the incoming string to a Date or null.
  const [valueUnderEdit, setValueUnderEdit] = useState<any | undefined>(value);

  const [mode, setMode] = useState<Mode>(Mode.VIEW);

  const handleSave = () => {
    // Convert the Date back to a string or any format you need
    if (valueUnderEdit) {
      onSaveClicked(valueUnderEdit);
    } else {
      // If no date, pass back empty or handle as you see fit
      onSaveClicked(undefined);
    }
    setMode(Mode.VIEW);
  };

  return (
    <HoverState>
      {(isHovered, bindHover) => (
        <div style={{ display: 'flex', flex: 1, ...viewModeProps }} {...bindHover}>
          {/* VIEW mode */}
          {mode === Mode.VIEW && <span>{optionToDisplayText[value] || 'Not Set'}</span>}

          {/* EDIT mode */}
          {mode === Mode.EDIT && (
            <TextField
              variant="standard"
              select
              InputProps={{ style: { fontSize: 12 } }}
              value={valueUnderEdit}
              onChange={(e) => {
                setValueUnderEdit(e.target.value);
              }}
            >
              {options.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {optionToDisplayText[option]}
                </MenuItem>
              ))}
            </TextField>
          )}

          {/* Icons in VIEW mode (only show if hovered) */}
          {isHovered && mode === Mode.VIEW && (
            <IconButton onClick={() => setMode(Mode.EDIT)} size="small" sx={{ padding: '0px', marginLeft: '2px' }}>
              <EditIcon style={{ fontSize: '14px' }} />
            </IconButton>
          )}

          {/* Icons in EDIT mode (cancel / save) */}
          {mode === Mode.EDIT && (
            <>
              <IconButton size="small" sx={{ padding: '0px', marginLeft: '2px' }} onClick={() => setMode(Mode.VIEW)}>
                <CloseIcon style={{ fontSize: '14px' }} />
              </IconButton>
              <IconButton onClick={handleSave} size="small" sx={{ padding: '0px', marginLeft: '2px' }}>
                <CheckIcon style={{ fontSize: '14px' }} />
              </IconButton>
            </>
          )}
        </div>
      )}
    </HoverState>
  );
}

export interface AutocompleteWithEditModeProps<T> {
  value: T | null;
  onSaveClicked: (newValue: T | null) => void;
  options: T[];
  getOptionLabel?: (option: T) => string;
  viewModeProps?: CSSObject;
  autocompleteProps?: Omit<
    AutocompleteProps<T, false, false, false>,
    'value' | 'onChange' | 'options' | 'renderInput' | 'getOptionLabel'
  >;
  viewElementOverride?: (arg0: T | null) => React.JSX.Element;
}

export function AutocompleteWithEditMode<T>({
  value,
  onSaveClicked,
  options,
  getOptionLabel,
  viewModeProps,
  autocompleteProps,
  viewElementOverride,
}: AutocompleteWithEditModeProps<T>) {
  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const [valueUnderEdit, setValueUnderEdit] = useState<T | null>(value);

  const defaultGetOptionLabel = (option: T) => String(option);

  const handleSave = () => {
    onSaveClicked(valueUnderEdit);
    setMode(Mode.VIEW);
  };

  const handleCancel = () => {
    // Revert edits back to original value
    setValueUnderEdit(value);
    setMode(Mode.VIEW);
  };

  return (
    <HoverState>
      {(isHovered, bindHover) => (
        <div style={{ display: 'flex', flex: 1, ...viewModeProps }} {...bindHover}>
          {mode === Mode.VIEW ? (
            /* ---------------------- VIEW MODE ---------------------- */
            <>
              <span>{value ? (viewElementOverride ?? getOptionLabel ?? defaultGetOptionLabel)(value) : 'Not Set'}</span>

              {/* Show the "Edit" icon only on hover */}
              {isHovered && (
                <IconButton onClick={() => setMode(Mode.EDIT)} size="small" sx={{ padding: '0px', marginLeft: '4px' }}>
                  <EditIcon style={{ fontSize: '14px' }} />
                </IconButton>
              )}
            </>
          ) : (
            /* ---------------------- EDIT MODE ---------------------- */
            <>
              <Autocomplete<T, false, false, false>
                // Spread in user-supplied props but let ours override
                {...autocompleteProps}
                // Required MUI autocomplete props
                options={options}
                value={valueUnderEdit}
                onChange={(_, newValue) => setValueUnderEdit(newValue)}
                getOptionLabel={getOptionLabel ?? defaultGetOptionLabel}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      style: { fontSize: 12 },
                    }}
                  />
                )}
                sx={{
                  width: 200,
                  '& .MuiInputBase-input': { fontSize: 12 },
                  ...(autocompleteProps?.sx || {}),
                }}
              />

              {/* Cancel icon */}
              <IconButton size="small" sx={{ padding: '0px', marginLeft: '4px' }} onClick={handleCancel}>
                <CloseIcon style={{ fontSize: '14px' }} />
              </IconButton>

              {/* Save icon */}
              <IconButton onClick={handleSave} size="small" sx={{ padding: '0px', marginLeft: '4px' }}>
                <CheckIcon style={{ fontSize: '14px' }} />
              </IconButton>
            </>
          )}
        </div>
      )}
    </HoverState>
  );
}
