// UseQuery.tsx
// based on https://www.robinwieruch.de/react-hooks-fetch-data/
import { useEffect, useState } from 'react';

type UseQueryArgs<T> = {
  queryKey: unknown[];
  queryFn: () => Promise<T>;
  initialData: T;
};

export function useQuery<T>({ queryFn, queryKey, initialData }: UseQueryArgs<T>) {
  const [data, setData] = useState<T>(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await queryFn();
        if (isMounted) setData(result);
      } catch (error) {
        if (isMounted) {
          setIsError(true);
          console.error('UseQuery error:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [...queryKey]);

  return { data, isLoading, isError };
}
