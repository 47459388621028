import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { AluminumExclusion } from 'common/interfaces/complianceSummary';

export interface AluminumModalProps {
  row: any;
  open: boolean;
  onClose: (e?: any) => void;
  exclusions: AluminumExclusion[];
}

export default function AluminumModal(props: AluminumModalProps) {
  const { onClose, open, row, exclusions } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="contact-us-dialog-container">
      <Dialog maxWidth="lg" onClose={handleClose} open={open}>
        <div>
          <div>
            <div className="header-question">
              <Typography>Aluminum Tariff Exclusions</Typography>
            </div>
            <div className="pga-description-subheader">Exclusions from aluminum tariffs under Section 232</div>
            <IconButton
              onClick={handleClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'grey',
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="pga-description-title">Applicable Products</div>
          <div className="pga-description-subheader">
            {exclusions.length > 0
              ? exclusions.map((product, index) => (
                  <div key={index}>
                    {index + 1}. {product.description}
                  </div>
                ))
              : 'No exclusions available.'}
          </div>
          <div className="pga-questions-container">
            <div>Questions? We're here - set up a time with one of our experts.</div>
            <div className="next-button-container">
              <a
                style={{ textDecoration: 'none' }}
                target="_blank"
                href="https://calendly.com/alex-0rfp/30min"
                rel="noreferrer"
              >
                <Button sx={{ minWidth: '200px' }} variant="contained" className="next-button-green">
                  Set up a 1x1
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
