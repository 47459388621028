import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import WelcomeHeader from '@/shared-components/WelcomeHeader/WelcomeHeader';

export default function ResetEmailConfirmation() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: 'black',
      }}
    >
      {/* Left side: Logo + Confirmation Text */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {/* Logo Container */}
        <Container sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src="IMPORTAL-FUTURE.png" width="218" height="38" alt="logo" />
        </Container>

        {/* Confirmation Message */}
        <Box
          sx={{
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            pr: '22%',
            pl: '22%',
            color: 'white',
            // For smaller screens, reduce padding
            [theme.breakpoints.down('md')]: {
              pr: '8%',
              pl: '8%',
            },
            [theme.breakpoints.down('sm')]: {
              pr: '5%',
              pl: '5%',
            },
          }}
        >
          <WelcomeHeader>
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
              Awesome! We just sent you an email.
            </Typography>
          </WelcomeHeader>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Keep a look out—it will contain a link to update your password.
          </Typography>
        </Box>
      </Box>

      {/* Right side: Cargo Image (hidden on small screens) */}
      <Box
        sx={{
          flex: 1,
          overflow: 'hidden',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <img
          src="cargo_in_ocean.png"
          alt="Cargo in ocean"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
          }}
        />
      </Box>
    </Box>
  );
}
