//DashboardPage.tsx

import { useAPI } from '@/api/APIContext';
import DataImportLoader, { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import ImportalBarChart from '@/components/reporting/ImportalBarChart';
import ImportalHistogram from '@/components/reporting/ImportalHistogram';
import ImportalPieChart from '@/components/reporting/ImportalPieChart';
import ImportalStackedBarChart from '@/components/reporting/ImportalStackedBarChart';
import { useQuery } from '@/custom-hooks/UseQuery';
import ImportalAutocomplete from '@/shared-components/ImportalAutocomplete/ImportalAutocomplete';
import ImportalFilter from '@/shared-components/ImportalFilter/ImportalFilter';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalReportCard from '@/shared-components/ImportalReportCard/ImportalReportCard';
import ImportalSearch from '@/shared-components/ImportalSearch/ImportalSearch';
import { Add } from '@mui/icons-material';
import { Box, Chip, CircularProgress, styled, Typography, useTheme } from '@mui/material';
import { ColumnConfig, ImportalTableDataType, TableConfig } from 'common/interfaces/importalTable';
import { ReportChartType } from 'common/interfaces/reports';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const StyledChip = styled(Chip)(() => ({
  backgroundColor: '#77A8F6',
  color: 'white',
  borderRadius: '10px!important',
  fontWeight: '700',
  padding: '8px',
  fontSize: '12px',
  '& .MuiChip-deleteIcon': {
    color: 'white',
    fontSize: '18px',
  },
}));

// can create a DashboardPages.tsx to generalize this to multiple dashboards and pass different dashboards to render

interface DashboardPageProps {
  dashboardData: any;
}

export default function DashboardPage({ dashboardData }: DashboardPageProps) {
  const api = useAPI();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  const rerunReport = useCallback(
    async (report) => {
      if (!report) return;

      const now = new Date();
      const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

      if (!report.lastRun) {
        console.log(`Re-running report: ${report._id} (No lastRun date found)`);
      } else {
        const lastRunDate = new Date(report.lastRun);
        if (isNaN(lastRunDate.getTime())) {
          console.warn(`Invalid lastRun date for report ${report._id}`);
          return;
        }
        if (lastRunDate >= twentyFourHoursAgo) {
          console.log(`Skipping re-run for report ${report._id}: Last run was within 24 hours.`);
          return;
        }
        console.log(`Re-running report: ${report._id}`);
      }

      try {
        await api.runReport(report._id.toString());
        console.log(`Report ${report._id} re-run successfully.`);
      } catch (error) {
        console.error(`Failed to re-run report ${report._id}:`, error);
      }
    },
    [api]
  );

  const fetchReports = useCallback(async () => {
    if (!Array.isArray(dashboardData?.reports)) {
      setLoadingStatus(LoadingStatus.SUCCESS);
      setSuccessText('No reports to display.');
      return [];
    }

    interface ReportExists {
      exists: boolean;
    }

    const reportPromises = dashboardData.reports.map(async (reportId: string) => {
      try {
        const existsResponse = await api.checkReportExists(reportId, startDate, endDate);
        const reportExists: ReportExists = existsResponse.data as ReportExists;

        let reportToFetchId: string = reportId;
        if (!reportExists.exists) {
          const childReportResponse = await api.createChildReport(reportId, startDate, endDate);
          reportToFetchId = childReportResponse.data._id.toString();
          await api.runReport(reportToFetchId);
        } else {
          const reportIdResponse = await api.getReportId(reportId, startDate, endDate);
          reportToFetchId = reportIdResponse.data._id;
        }

        const reportResponse = await api.getReport(reportToFetchId);

        if (!endDate) {
          await rerunReport(reportResponse.data);
        }
        const reportDataResponse = await api.getReportData(reportToFetchId);

        return {
          id: reportToFetchId,
          chartConfig: reportResponse.data.reportConfig?.chartConfig,
          data: reportDataResponse.data,
        };
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
        return { id: reportId, error: errorMessage };
      }
    });

    const results = await Promise.allSettled(reportPromises);

    const successfulReports = results
      .filter((res) => res.status === 'fulfilled')
      .map((res) => (res as PromiseFulfilledResult<any>).value);

    const reportDataIsNonEmpty = successfulReports.some((report) => report.data && report.data.length > 0);

    if (reportDataIsNonEmpty) {
      setLoadingStatus(LoadingStatus.SUCCESS);
      setSuccessText('Successfully fetched chart data.');
    } else {
      setLoadingStatus(LoadingStatus.SUCCESS);
      setSuccessText('No data available for the selected date range.');
    }

    const failedReports = results.filter((res) => res.status === 'rejected');
    if (failedReports.length) {
      console.warn('Some reports failed to load:', failedReports);
    }

    return successfulReports;
  }, [api, dashboardData, rerunReport, startDate, endDate]);

  const {
    data: reportData,
    isLoading: isReportDataLoading,
    isError: isReportDataError,
  } = useQuery({
    queryKey: ['reportData', dashboardData, startDate, endDate],
    queryFn: fetchReports,
    initialData: [],
  });

  // ----- Options for Action Buttons (including "View Report") -----
  const handleViewReport = (reportId: string) => {
    navigate(`./${reportId}`);
    console.log(`Viewing report with ID: ${reportId}`);
  };

  const handleEdit = useCallback((id) => {
    console.log('Edit report with id:', id);
    // Implement edit logic here...
  }, []);

  const handleDelete = useCallback((id) => {
    console.log('Delete report with id:', id);
    // Implement delete logic here...
  }, []);

  const createMenuOptions = (reportId: string) => [
    { label: 'Edit', onClick: () => handleEdit(reportId) },
    { label: 'Delete', onClick: () => handleDelete(reportId) },
  ];

  // Helper: choose the correct chart component based on chartType
  const getChartComponent = useCallback((chartType) => {
    switch (chartType) {
      case ReportChartType.BAR:
        return ImportalBarChart;
      case ReportChartType.STACKED_BAR:
        return ImportalStackedBarChart;
      case ReportChartType.PIE:
        return ImportalPieChart;
      case ReportChartType.HISTOGRAM:
        return ImportalHistogram;
      default:
        return null;
    }
  }, []);

  const dataMap = useMemo(() => {
    if (!reportData) return {};
    return reportData.reduce(
      (acc, { id, data }) => {
        acc[id] = data;
        return acc;
      },
      {} as Record<string, any>
    );
  }, [reportData]);

  const chunkedReports = useMemo(() => {
    if (!reportData) return [];
    const chunks: Array<{ id: string; chartConfig: any }[]> = [];
    for (let i = 0; i < reportData.length; i += 2) {
      chunks.push(reportData.slice(i, i + 2).map(({ id, chartConfig }) => ({ id, chartConfig })));
    }
    return chunks;
  }, [reportData]);

  // ----- Define Columns for Filtering -----
  const [columns] = useState<ColumnConfig[]>([
    {
      name: 'category',
      displayName: 'Category',
      isVisible: true,
      sortable: true,
      filterable: true,
      dataType: 'string',
    },
    {
      name: 'date',
      displayName: 'Import Date',
      isVisible: true,
      sortable: true,
      filterable: true,
      dataType: ImportalTableDataType.DATE_STRING,
    },
  ]);

  // ----- Define Table Configuration -----
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    columns: columns,
    filterConfig: {},
    sortConfig: [],
    subKeyForData: '',
  });

  // ----- Define Selected Filtering Column and Values -----
  const [selectedFilteringColumn, setSelectedFilteringColumn] = useState<ColumnConfig | null>(null);
  const [selectedFilteringValues, setSelectedFilteringValues] = useState<any[]>([]);

  // ----- Add Filter Chip -----
  const handleAddFilterChip = (columnName: string, displayName: string, values: Array<any>) => {
    const newFilterConfig = {
      ...tableConfig.filterConfig,
      [columnName]: {
        allowedValues: values,
        columnName: columnName,
        displayName: displayName,
      },
    };
    setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
  };

  // ----- Delete Filter Chip -----
  const handleDeleteFilterChip = (columnName: string, value: string) => {
    const newFilterConfig = { ...tableConfig.filterConfig };

    if (value === '') {
      delete newFilterConfig[columnName];
    } else {
      const filteredValues = newFilterConfig[columnName].allowedValues.filter((val: any) => val !== value);
      if (filteredValues.length === 0) {
        delete newFilterConfig[columnName];
      } else {
        newFilterConfig[columnName] = {
          ...newFilterConfig[columnName],
          allowedValues: filteredValues,
        };
      }
    }
    setStartDate('');
    setEndDate('');
    setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
  };

  // ----- Local Search State for ImportalSearch -----
  const [searchQuery, setSearchQuery] = useState('');

  // Handler for ImportalSearch changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    // You can also incorporate search logic here if you want to filter data
    // setFilteredData(...) etc.
  };

  useEffect(() => {
    const filterConfig = tableConfig.filterConfig.date;
    if (
      filterConfig?.allowedValues &&
      Array.isArray(filterConfig.allowedValues) &&
      filterConfig.allowedValues.length >= 2
    ) {
      const { allowedValues } = filterConfig;

      const newStartDate = moment(allowedValues[0]).format('MM/DD/YYYY');
      const newEndDate = moment(allowedValues[allowedValues.length - 1]).format('MM/DD/YYYY');

      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  }, [tableConfig?.filterConfig?.date]);

  const renderFilterChips = () => {
    return Object.keys(tableConfig.filterConfig).map((columnName) => {
      const filterConfig = tableConfig.filterConfig[columnName];
      // const defaultFilter =
      //   predefinedViews.find((view) => view === tableConfig)?.filterConfig[columnName]?.allowedValues || [];

      const isDateColumn =
        filterConfig.columnName &&
        tableConfig.columns.some(
          (col) => col.name === filterConfig.columnName && col.dataType === ImportalTableDataType.DATE_STRING
        );

      if (isDateColumn && filterConfig.allowedValues.length > 1) {
        // Single chip for a date range
        const startDate = moment(filterConfig.allowedValues[0]).format('MM/DD/YYYY');
        const endDate = moment(filterConfig.allowedValues[filterConfig.allowedValues.length - 1]).format('MM/DD/YYYY');
        return (
          <Chip
            key={`${columnName}-date-range`}
            label={`${filterConfig.displayName || filterConfig.columnName}: ${startDate} - ${endDate}`}
            onDelete={() => handleDeleteFilterChip(columnName, '')} // pass empty string to remove entire date range
            sx={{
              backgroundColor: '#77A8F6',
              color: 'white',
              borderRadius: '10px!important',
              fontWeight: '700',
              padding: '8px',
              fontSize: '12px',
              '& .MuiChip-deleteIcon': {
                color: 'white',
                fontSize: '18px',
              },
            }}
          />
        );
      }
    });
  };

  const [sortedAndFilteredData, setSortedAndFilteredData] = useState<any[]>([]);
  const theme = useTheme();

  if (isReportDataLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress sx={{ color: theme.palette.primary.main }} size={35} />
        <Typography variant="body1" sx={{ mt: 2, fontSize: '1.1rem', color: '#525256' }}>
          Loading reports...
        </Typography>
      </Box>
    );
  }

  if (isReportDataError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Typography variant="body1" sx={{ fontSize: '1.1rem', color: '#525256' }}>
          Error loading reports
        </Typography>
      </Box>
    );
  }

  const renderReportCard = (report: { id: string; chartConfig: any }, chartData: any) => {
    const { id, chartConfig } = report;
    const ChartComponent = getChartComponent(chartConfig.chartType);
    const isBlurred = chartConfig.chartName === 'Duties and Fees Spend By Product';

    if (isBlurred) {
      return (
        <ImportalReportCard
          title={chartConfig.chartName}
          menuOptions={createMenuOptions(id)}
          style={{ position: 'relative' }}
        >
          <div
            style={{
              minHeight: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#333',
              fontSize: '18px',
              fontWeight: 'bold',
              height: '100%',
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <div>
                <img height={80} width={80} src="../logo-transparent.png" alt="logo" />
              </div>
              <div style={{ marginTop: '-12px' }}>This feature is coming soon...</div>
            </div>
          </div>
        </ImportalReportCard>
      );
    } else {
      return (
        <ImportalReportCard
          title={chartConfig.chartName}
          viewFullReport={true}
          handleViewFullReport={() => handleViewReport(id)}
          menuOptions={createMenuOptions(id)}
        >
          {ChartComponent ? (
            <ChartComponent chartConfig={chartConfig} data={chartData} />
          ) : (
            <div>Unsupported chart type.</div>
          )}
        </ImportalReportCard>
      );
    }
  };

  return (
    <>
      {/* ----- Top Section: ImportalAutocomplete, Filter, Filter Chips, and Search ----- */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <div style={{ flex: 1, display: 'flex', gap: '6px', alignItems: 'end' }}>
          {/* Autocomplete */}
          <ImportalAutocomplete
            options={columns}
            value={selectedFilteringColumn}
            onChange={(event, value) => {
              setSelectedFilteringColumn(value as ColumnConfig | null);
              setSelectedFilteringValues([]);
            }}
            placeholder="Filter by column..."
          />

          {/* ImportalFilter */}
          <ImportalFilter
            selectedFilteringColumn={selectedFilteringColumn}
            selectedFilteringValues={selectedFilteringValues}
            setSelectedFilteringColumn={setSelectedFilteringColumn}
            setSelectedFilteringValues={setSelectedFilteringValues}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            sortedAndFilteredData={sortedAndFilteredData}
            handleAddFilterChip={handleAddFilterChip}
          />

          {/* Filter Chips */}
          <div style={{ overflowY: 'auto', alignSelf: 'center', maxHeight: '40px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'end', columnGap: '4px', rowGap: '2px' }}>
              {renderFilterChips()}
            </div>
          </div>
        </div>

        {/* Search & "Create New Report" Button */}
        <div style={{ display: 'flex', alignItems: 'end', gap: '8px' }}>
          <ImportalSearch value={searchQuery} onChange={handleSearchChange} placeholder="Search..." />

          <ImportalPrimaryButton
            style={{ width: '200px', borderRadius: '10px', fontSize: '14px' }}
            onClick={() => console.log('Create New Report')}
            text="Create New Report"
            endIcon={<Add />}
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
        {chunkedReports.map((row, rowIndex) => (
          <div key={rowIndex} style={{ display: 'flex', width: '100%', gap: '24px' }}>
            {row.map((report) => (
              <div key={report.id} style={{ flex: 1 }}>
                {renderReportCard(report, dataMap[report.id] ?? [])}
              </div>
            ))}
          </div>
        ))}
      </div>
      <DataImportLoader
        loadingState={{ loadingStatus }}
        loadingText={loadingText}
        successText={successText}
        errorText={errorText}
      />
    </>
  );
}
