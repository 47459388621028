import ImportalPage from '@/shared-components/ImportalPage/ImportalPage';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import React, { useEffect, useState } from 'react';
import ImportalTable from '@/shared-components/ImportalTable/ImportalTable';
import { TableConfig } from 'common/interfaces/importalTable';
import { TradeEntity } from 'common/interfaces/tradeEntity';
import { useAPI } from '@/api/APIContext';
import AddOrEditTradeEntityModal from '@/shared-components/TradeEntities/AddOrEditTradeEntityModal';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { IconButton, TableCell, TableRow } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import * as _ from 'lodash';

export default function TradeEntitiesPage() {
  const api = useAPI();
  const [tradeEntities, setTradeEntities] = useState<Array<TradeEntity>>([]);

  const [addOrEditTradeEntityOpen, setAddOrEditTradeEntityOpen] = useState(false);
  const [tradeEntityIDForViewingInModal, setTradeEntityIDForViewingInModal] = useState<string | undefined>();

  const [tradeEntitiesTableConfig, setTradeEntitiesTableConfig] = useState<TableConfig>({
    columns: [
      {
        displayName: 'Name',
        name: 'name',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'Role',
        name: 'tradeEntityRole',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'MID',
        name: 'manufacturerId',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'Address',
        name: 'address.line1',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'City',
        name: 'address.city',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'State',
        name: 'address.stateProvince',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'Postal',
        name: 'address.postalCode',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
      {
        displayName: 'Country',
        name: 'address.country',
        dataType: 'string',
        sortable: true,
        filterable: false,
        isVisible: true,
      },
    ],
    sortConfig: [],
    filterConfig: {},
  });

  useEffect(() => {
    api
      .getAllTradeEntities()
      .then(({ data }) => {
        setTradeEntities(data);
      })
      .catch((err) => {
        console.error('error getting all trade entities', err);
      });
  }, [api]);

  return (
    <>
      <ImportalPage
        header={'Trade Entities'}
        subheader={'Involved Parties / Cargo Release Network'}
        loadingStatus={LoadingStatus.NOT_LOADING}
        loadingText={''}
        successText={''}
        errorText={''}
      >
        <div className="shipments-table-container">
          <ImportalTable
            data={tradeEntities}
            hasRowExpand={false}
            useSettings={false}
            rowGenerator={(row: any, index: number) => (
              <TradeEntityRow
                key={`row-${index}`}
                onClickEdit={(tradeEntityId) => {
                  setTradeEntityIDForViewingInModal(tradeEntityId);
                  setAddOrEditTradeEntityOpen(true);
                }}
                onClickDelete={(tradeEntityId) => {}}
                tradeEntity={row}
                tableConfig={tradeEntitiesTableConfig}
              />
            )}
            tableConfig={tradeEntitiesTableConfig}
            setTableConfig={setTradeEntitiesTableConfig}
            topRightElement={<></>}
          ></ImportalTable>
        </div>
      </ImportalPage>

      <AddOrEditTradeEntityModal
        open={addOrEditTradeEntityOpen}
        tradeEntityId={tradeEntityIDForViewingInModal}
        onClose={() => {
          setAddOrEditTradeEntityOpen(false);
        }}
        onTradeEntityCreatedOrModified={() => {}}
      />
    </>
  );
}

interface TradeEntityRowProps {
  tradeEntity: TradeEntity;
  onClickEdit: (arg0: string) => void;
  onClickDelete: (arg0: string) => void;
  tableConfig: TableConfig;
}

function TradeEntityRow({ tradeEntity, onClickEdit, onClickDelete, tableConfig }: TradeEntityRowProps) {
  const activeUser = useActiveUser();

  const [isHovered, setHovered] = useState(false); // <= Add new state for hover

  return (
    <React.Fragment key={tradeEntity._id!.toString()}>
      <TableRow
        key={tradeEntity._id!.toString()}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {tableConfig.columns.map((column) => {
          return (
            <TableCell key={column.name} className={'importal-table-cell'}>
              {_.get(tradeEntity, column.name)}
            </TableCell>
          );
        })}

        <TableCell>
          <div style={{ display: 'flex' }}>
            <IconButton
              style={{ opacity: isHovered ? '1.0' : '0.0' }}
              id="edit-from-row"
              onClick={() => onClickEdit(tradeEntity._id!.toString())}
            >
              <CreateIcon />
            </IconButton>
            {/*<IconButton*/}
            {/*    style={{opacity: isHovered ? '1.0' : '0.0'}}*/}
            {/*    onClick={() => onClickDelete(tradeEntity._id!.toString())}*/}
            {/*>*/}
            {/*    <DeleteIcon/>*/}
            {/*</IconButton>*/}
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
