import LightTooltip from '@/components/tooltip-component/TooltipComponent';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Card, CardContent, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import './CargoValue.css';

interface Props {
  cargoValue?: string;
  onCargoValueSet: (cargoValue?: string) => void;
  onNext: () => void;
  onBack: () => void;
}

export default function CargoValue({ cargoValue, onCargoValueSet, onNext, onBack }: Props) {
  const theme = useTheme();

  const isNumber = (str) => /^[0-9]+(\.[0-9]{2})?$/.test(str);

  const handleCargoValue = (e) => {
    let cargoValueNumber = e.target.value;
    if (cargoValueNumber.includes(',')) {
      cargoValueNumber = cargoValueNumber.replaceAll(',', '');
    }
    if (isNumber(cargoValueNumber) && cargoValueNumber !== '') {
      cargoValueNumber = new Intl.NumberFormat('en-US').format(cargoValueNumber);
    }
    if (cargoValueNumber.includes(',')) {
      cargoValueNumber = cargoValueNumber.replaceAll(',', '');
    }

    onCargoValueSet(cargoValueNumber);
  };

  const inputIsValid = (): boolean => {
    return Boolean(cargoValue) && isNumber(cargoValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputIsValid()) {
        onNext();
      }
    }
  };

  return (
    <div className="card-container">
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <div className="header-question">
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: '36px',
                marginTop: '15px',
              }}
            >
              What's the value of your cargo?
            </Typography>
          </div>
          <div className="unlading-descriptive-text">
            United States Customs is interested in the
            <LightTooltip title="Your cargo value i.e. amount paid is used to calculate duty and is required to be reported to U.S. Customs and Border Protection upon entry.">
              <div className="tooltip-container">
                <b className="amount-paid">amount paid</b>
              </div>
            </LightTooltip>
            for the goods currently being imported, rounding up to the nearest dollar.
            <LightTooltip title="If you have any questions regarding the valuation of your product, feel free to reach out via the Help left side menu button and we can walk you through it.">
              <div className="tooltip-container">
                <InfoIcon sx={{ fontSize: '16px' }} />
              </div>
            </LightTooltip>
          </div>
          <div className="cargo-value-text-field">
            <TextField
              required
              fullWidth
              id="cargo-value"
              label=""
              onChange={handleCargoValue}
              value={cargoValue || ''}
              name="cargo-value"
              autoComplete="off"
              InputProps={{
                startAdornment: <InputAdornment position="start">$ (USD)</InputAdornment>,
              }}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          </div>
          <div className="next-button-container">
            <Button
              fullWidth
              className="back-button-text"
              startIcon={<KeyboardArrowLeftIcon />}
              variant="text"
              onClick={onBack}
            >
              Back
            </Button>
            <Button
              type="submit"
              onClick={onNext}
              fullWidth
              variant="contained"
              className="next-button-green"
              endIcon={<KeyboardArrowRightIcon />}
              disabled={!inputIsValid()}
            >
              Next
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
