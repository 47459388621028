import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Dialog, DialogContent, IconButton, MenuItem, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAPI } from '@/api/APIContext';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalSecondaryButton from '@/shared-components/ImportalSecondaryButton/ImportalSecondaryButton';
import { AxiosError } from 'axios';
import {
  EmptyTradeEntity,
  TradeEntity,
  TradeEntityRole,
  TradeEntityRoleToDescription,
} from 'common/interfaces/tradeEntity';
import { Mode } from 'common/interfaces/business';

export enum TradeEntityAction {
  CREATED = 'CREATED',
  MODIFIED = 'MODIFIED',
}

type ValidationErrors = string[];

interface Props {
  open: boolean;
  onClose: () => void;
  tradeEntityId?: string; // if present => edit mode
  onTradeEntityCreatedOrModified: (id: string, action: TradeEntityAction) => void;
  draftStateTradeEntity?: Partial<TradeEntity>;
}

export default function AddOrEditTradeEntityModal({
  open,
  onClose,
  tradeEntityId,
  onTradeEntityCreatedOrModified,
  draftStateTradeEntity,
}: Props) {
  const api = useAPI();

  // MODE: Are we adding a new trade entity or editing an existing one?
  const [mode, setMode] = useState<Mode>(tradeEntityId ? Mode.EDIT : Mode.ADD);

  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>([]);

  // The TradeEntity we are modifying or creating
  const [tradeEntityUnderEdit, setTradeEntityUnderEdit] = useState<TradeEntity | undefined>();

  useEffect(() => {
    // Decide mode based on whether tradeEntityId is given
    if (!tradeEntityId) {
      setMode(Mode.ADD);
      // Create a brand-new "empty" tradeEntity for the form
      if (draftStateTradeEntity) {
        setTradeEntityUnderEdit(draftStateTradeEntity as TradeEntity);
      } else {
        setTradeEntityUnderEdit(EmptyTradeEntity);
      }
    } else {
      setMode(Mode.EDIT);
      setLoading(true);
      api
        .getTradeEntityWithId(tradeEntityId)
        .then(({ data }) => {
          setTradeEntityUnderEdit(data);
          setLoading(false);
        })
        .catch((err) => {
          console.error('error getting trade entity for add/edit modal', err);
          setLoading(false);
        });
    }
    setValidationErrors([]);
    setIsEdited(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeEntityId, open]);

  // If you want to do a quick validity check of your trade entity, you can do so here:
  const [tradeEntityIsValid, setTradeEntityIsValid] = useState(false);
  useEffect(() => {
    // Very simple check: must exist with a name
    setTradeEntityIsValid(
      !!tradeEntityUnderEdit?.name && !!tradeEntityUnderEdit?.tradeEntityRole && !!tradeEntityUnderEdit?.address?.line1
    );
  }, [tradeEntityUnderEdit]);

  /**
   * Called when a top-level field changes, e.g. `name`, `dunsOrFirmsCode`, `email`, etc.
   */
  const onFieldChange = (fieldName: keyof TradeEntity, newValue: any) => {
    if (!tradeEntityUnderEdit) return;
    const updated = { ...tradeEntityUnderEdit, [fieldName]: newValue };
    setTradeEntityUnderEdit(updated);
    setIsEdited(true);
  };

  /**
   * Called when an address sub-field changes, e.g. `address.city`.
   */
  const onAddressFieldChange = (subFieldName: keyof TradeEntity['address'], newValue: any) => {
    if (!tradeEntityUnderEdit) return;
    const updated = {
      ...tradeEntityUnderEdit,
      address: {
        ...tradeEntityUnderEdit.address,
        [subFieldName]: newValue,
      },
    };
    setTradeEntityUnderEdit(updated);
    setIsEdited(true);
  };

  const onSubmit = async () => {
    if (!tradeEntityUnderEdit) return;
    setLoading(true);

    try {
      if (mode === Mode.ADD) {
        // Create new
        const { data } = await api.createTradeEntity(tradeEntityUnderEdit);
        onTradeEntityCreatedOrModified(data._id, TradeEntityAction.CREATED);
        // After creation, we can either close or reset:
        onClose();
      } else {
        // Update existing
        if (!tradeEntityId) return; // safety
        await api.updateTradeEntityWithId(tradeEntityId, tradeEntityUnderEdit);
        onTradeEntityCreatedOrModified(tradeEntityId, TradeEntityAction.MODIFIED);
        onClose();
      }
    } catch (err) {
      console.error(`error on ${mode === Mode.ADD ? 'creating' : 'updating'} trade entity`, err);
      if ((err as AxiosError).response?.data) {
        //@ts-ignore
        const { errors } = err.response.data;
        setValidationErrors(errors || ['An unknown error occurred.']);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') {
          // Do not close if user clicks outside (optional; your choice)
          return;
        }
        onClose();
      }}
    >
      <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Top close button */}
      <IconButton
        aria-label="close"
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'grey',
        }}
        onClick={onClose}
      >
        <CloseIcon sx={{ fontSize: '24px' }} />
      </IconButton>

      {/* Title */}
      <div
        style={{
          marginTop: '20px',
          marginBottom: '24px',
          color: '#525256',
          lineHeight: '28px',
          fontWeight: 700,
          fontSize: '24px',
          paddingLeft: '24px',
          paddingRight: '24px',
        }}
      >
        {mode === Mode.ADD ? 'Add' : 'Edit'} Trade Entity
      </div>

      <DialogContent>
        {validationErrors?.length > 0 && (
          <ul style={{ color: 'red', margin: 0, marginBottom: '10px' }}>
            {validationErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}

        {/* Role */}
        <TextField
          label="Role"
          select
          value={tradeEntityUnderEdit?.tradeEntityRole ?? ''}
          onChange={(e) => onFieldChange('tradeEntityRole', e.target.value)}
          margin="normal"
          size="small"
          fullWidth
        >
          {Object.values(TradeEntityRole).map((role) => (
            <MenuItem key={role} value={role}>
              {role} ({TradeEntityRoleToDescription[role] ?? ''})
            </MenuItem>
          ))}
        </TextField>

        {/* Name */}
        <TextField
          label="Name"
          value={tradeEntityUnderEdit?.name ?? ''}
          onChange={(e) => onFieldChange('name', e.target.value)}
          margin="normal"
          size="small"
          fullWidth
        />

        {/* Manufacturer ID */}
        <TextField
          label="Manufacturer ID"
          value={tradeEntityUnderEdit?.manufacturerId ?? ''}
          onChange={(e) => onFieldChange('manufacturerId', e.target.value)}
          margin="normal"
          size="small"
          fullWidth
        />

        {/* Email */}
        <TextField
          label="Email"
          type="email"
          value={tradeEntityUnderEdit?.email ?? ''}
          onChange={(e) => onFieldChange('email', e.target.value)}
          margin="normal"
          size="small"
          fullWidth
        />

        {/* Address fields */}
        <TextField
          label="Address Line 1"
          value={tradeEntityUnderEdit?.address?.line1 ?? ''}
          onChange={(e) => onAddressFieldChange('line1', e.target.value)}
          margin="normal"
          size="small"
          fullWidth
        />

        <TextField
          label="City"
          value={tradeEntityUnderEdit?.address?.city ?? ''}
          onChange={(e) => onAddressFieldChange('city', e.target.value)}
          margin="normal"
          size="small"
          fullWidth
        />
        <TextField
          label="State/Province"
          value={tradeEntityUnderEdit?.address?.stateProvince ?? ''}
          onChange={(e) => onAddressFieldChange('stateProvince', e.target.value)}
          margin="normal"
          size="small"
          fullWidth
        />
        <TextField
          label="Postal Code"
          value={tradeEntityUnderEdit?.address?.postalCode ?? ''}
          onChange={(e) => onAddressFieldChange('postalCode', e.target.value)}
          margin="normal"
          size="small"
          fullWidth
        />
        <TextField
          label="Country"
          value={tradeEntityUnderEdit?.address?.country ?? ''}
          onChange={(e) => onAddressFieldChange('country', e.target.value)}
          margin="normal"
          size="small"
          fullWidth
        />
      </DialogContent>

      {/* Footer actions */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
        <ImportalSecondaryButton onClick={onClose} text="Cancel" />
        <ImportalPrimaryButton
          style={{ marginLeft: '8px' }}
          text={mode === Mode.ADD ? 'Add' : 'Save'}
          disabled={(mode === Mode.EDIT && !isEdited) || !tradeEntityIsValid}
          onClick={onSubmit}
        />
      </div>
    </Dialog>
  );
}
