import React, { useRef, useState, useLayoutEffect } from 'react';
import { renderChartLabel } from '@/utils/reportingUtils';
import { ChartUnitType } from 'common/interfaces/reports';

interface ImportalChartTooltipProps {
  /** The x and y coordinates where the tooltip should be anchored. */
  x: number;
  y: number;
  /**
   * An optional label or category name to display.
   * If provided along with `value`, the tooltip will show: "label: value"
   */
  label?: string;
  /**
   * The numeric value to display. If `label` is also provided, both will be combined.
   */
  value?: number;
  /**
   * If `value` is provided, you can specify its unit
   * (e.g., ChartUnitType.Percent) to format the display.
   */
  unit?: ChartUnitType;
}

/**
 * A self-contained tooltip component that:
 * 1. Measures the text content via getBBox().
 * 2. Dynamically adjusts the tooltip rectangle size so text is never cut off.
 *
 * By default, if `label` and `value` are provided, it will render in the format:
 * "label: valueWithUnit"
 */
export function ImportalChartTooltip({ x, y, label, value, unit }: ImportalChartTooltipProps) {
  const textRef = useRef<SVGTextElement>(null);
  const [textWidth, setTextWidth] = useState(0);

  // Build the text to display
  let displayText = '';
  if (label && typeof value === 'number') {
    displayText = `${label}: ${renderChartLabel(value, unit ?? ChartUnitType.Number)}`;
  } else if (!label && typeof value === 'number') {
    displayText = renderChartLabel(value, unit ?? ChartUnitType.Number);
  } else {
    displayText = `${label}: ${value}`;
  }

  useLayoutEffect(() => {
    if (textRef.current) {
      // Measure the <text> element to get its width
      const bbox = textRef.current.getBBox();
      setTextWidth(bbox.width);
    }
  }, [displayText]);

  // We'll add some horizontal padding so the text isn't touching the edges
  const paddingX = 8;
  const tooltipWidth = textWidth + paddingX * 2;
  const tooltipHeight = 25; // Enough for single-line text w/ some vertical padding

  return (
    <g transform={`translate(${x}, ${y})`} style={{ pointerEvents: 'none' }}>
      {/*
        Render text once, in place, but hidden until we measure.
        The textAnchor="middle" means the text is centered around x=0.
      */}
      <text ref={textRef} x={0} y={-8} fontSize="12px" fill="white" textAnchor="middle" style={{ opacity: 0 }}>
        {displayText}
      </text>

      {/* The background rect, placed around the measured text width */}
      <rect x={-tooltipWidth / 2} y={-tooltipHeight} width={tooltipWidth} height={tooltipHeight} rx={4} fill="#666" />

      {/* Now render the text again, visible */}
      <text x={0} y={-8} fontSize="12px" fill="white" textAnchor="middle">
        {displayText}
      </text>
    </g>
  );
}
