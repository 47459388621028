import { Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CustomsClearance from '@/components/customs-clearance/CustomsClearance';
import HtsClassification from '@/components/hts-classification/HtsClassification';
import ShipmentTable from '@/components/home-component/ShipmentTable';
import './HomeComponent.css';
import FullOnBoardingModal from '@/components/full-onboarding-modal/FullOnBoardingModal';
import LightTooltip from '@/components/tooltip-component/TooltipComponent';
import ClassificationsTable from '@/pages/hts-classifications/ConsumerClassificationsTable';
import { Add, FactCheck } from '@mui/icons-material';
import { AppContext } from '@/App';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '@/api/APIContext';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import { activePlanUtils, planIsExhaustedForFeature } from '@/utils/activePlanUtils';
import { FeatureName } from 'common/interfaces/plan';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { useActiveCustomsOnboarding } from '@/custom-hooks/customs-onboarding/CustomsOnboardingProvider';
import WarningAlert from '@/shared-components/WarningAlert/WarningAlert';
import ImportalCard from '@/shared-components/ImportalCard';
import InfoAlert from '@/components/info-alert/InfoAlert';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import useIsMobile from '@/custom-hooks/mobile-device/isMobileDevice';

export default function HomeComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { handleOpenPlansModal, handleOpenWalkthroughModal } = useContext(AppContext);
  const [openHtsClassifications, setOpenHtsClassifications] = useState(false);
  const [shipments, setShipments] = useState<any>([]);
  const [classifications, setClassifications] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [searchesWarning, setSearchesWarning] = useState(true);
  const [mobileAlert, setMobileAlert] = useState(false);
  const api = useAPI();
  const activeUser = useActiveUser();
  const activeBusiness = useActiveBusiness();
  const activePlan = useActivePlan();
  const activeCustomsOnboarding = useActiveCustomsOnboarding();
  const [showCustomsAlert, setShowCustomsAlert] = useState(true);
  const [userClosedAlert, setUserClosedAlert] = useState(false);

  const handleMobileChange = (isMobile) => {
    if (isMobile && !userClosedAlert) {
      setMobileAlert(true);
    } else {
      setMobileAlert(false);
    }
  };

  const isMobileDevice = useIsMobile(handleMobileChange);

  const closeMobileAlert = () => {
    setMobileAlert(false);
    setUserClosedAlert(true);
  };

  const renderWarning = (searchesWarning: boolean): ReactNode => {
    if (activePlan.isFreePlan) {
      return (
        <WarningAlert
          message={`You have used ${activePlanUtils(
            activePlan.metadata
          )} of 2 Compliance Tool searches for this month. Sit tight till next month or upgrade to Professional Plan for unlimited searches.`}
          onClose={handleCloseSearchesWarning}
          onActionClick={handleOpenPlansModal}
          actionText="Upgrade"
        />
      );
    } else if (activePlan.isNormalProPlan && activePlan.plan.status === 'trialing') {
      return (
        <WarningAlert
          message={`You're on a two-week free trial of the Professional Plan.`}
          onClose={handleCloseSearchesWarning}
          onActionClick={handleOpenWalkthroughModal}
          actionText="Need a walk through?"
        />
      );
    }
  };

  const handleLoading = (value) => {
    setIsLoading(value);
  };

  const handleCloseSearchesWarning = () => {
    setSearchesWarning(false);
  };

  const getShipments = useCallback(() => {
    handleLoading(true);
    api.getShipmentsForUser().then((response) => {
      setShipments(response.data.userShipments);
      handleLoading(false);
    });
  }, []);

  const refreshClassifications = () => {
    handleLoading(true);
    api
      .getUserClassifications()
      .then(({ data }) => {
        setClassifications(data);
        handleLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        handleLoading(false);
      });
  };

  const getClassifications = useCallback(() => {
    refreshClassifications();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const customsOnboarding = searchParams.get('customsOnboarding');
    if (customsOnboarding === 'true') {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [location]);

  useEffect(() => {
    getShipments();
    getClassifications();
  }, [getShipments, getClassifications]);

  const handleComplianceOneClick = () => {
    if (planIsExhaustedForFeature(FeatureName.SEARCHES, activePlan.metadata)) {
      handleOpenPlansModal();
    } else {
      navigate('compliance-one');
    }
  };

  const handleDutyCalculatorClick = () => {
    if (planIsExhaustedForFeature(FeatureName.SEARCHES, activePlan.metadata)) {
      handleOpenPlansModal();
    } else {
      navigate('duty-calculator');
    }
  };

  const handleHtsClassificationsClose = () => {
    refreshClassifications();
    setOpenHtsClassifications(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const theme = useTheme();

  const handleCustomsClearanceClick = (e) => {
    setOpen(true);
  };

  const handleHtsClassificationsClick = () => {
    setOpenHtsClassifications(true);
  };

  useEffect(() => {
    if (activeCustomsOnboarding.hasCompletedOnboarding) {
      setShowCustomsAlert(false);
    }
  }, [activeCustomsOnboarding.hasCompletedOnboarding]);

  const renderCustomsClearanceButton = () => {
    if (!activeCustomsOnboarding.hasCompletedOnboarding) {
      return (
        <LightTooltip
          title={
            activeCustomsOnboarding.hasCompletedOnboarding
              ? ''
              : 'Finish the customs onboarding steps for your business in order to get access to Customs Clearance'
          }
        >
          <div>
            <Button
              startIcon={<TaskRoundedIcon />}
              onClick={handleCustomsClearanceClick}
              disabled={true}
              variant="text"
              sx={{
                textTransform: 'none',
                backgroundColor: '#388E3C',
                color: '#FDFDFD',
              }}
              className="consultation-button"
            >
              Customs Clearance
            </Button>
          </div>
        </LightTooltip>
      );
    } else {
      return (
        <div>
          <Button
            startIcon={<TaskRoundedIcon />}
            onClick={handleCustomsClearanceClick}
            variant="text"
            sx={{
              textTransform: 'none',
              backgroundColor: '#388E3C',
              color: '#FDFDFD',
            }}
            className="consultation-button"
          >
            Customs Clearance
          </Button>
        </div>
      );
    }
  };

  const renderHtsClassificationButton = () => {
    return (
      <LightTooltip
        title={'Finish the customs onboarding steps for your business in order to get access to HTS Classification'}
        disableHoverListener={activeCustomsOnboarding.hasCompletedOnboarding}
      >
        <div>
          <Button
            startIcon={<LibraryBooksRoundedIcon />}
            onClick={handleHtsClassificationsClick}
            variant="text"
            disabled={!activeCustomsOnboarding.hasCompletedOnboarding}
            sx={{
              textTransform: 'none',
              backgroundColor: '#388E3C',
              color: '#FDFDFD',
            }}
            className="consultation-button"
          >
            HTS Classification
          </Button>
        </div>
      </LightTooltip>
    );
  };

  const renderMobileAlert = () => {
    return (
      <InfoAlert
        message={
          <div className="mobile-alert">
            <div className="mobile-alert-header">You're seeing a limited mobile version of Importal's app.</div>
            <br />
            <span>
              Check us out on your browser to get access to our full features: HTS Classifications, Customs Clearance,
              Product Library, and much more!
            </span>
          </div>
        }
        onClose={closeMobileAlert}
      />
    );
  };

  const renderAlertDetail = () => {
    return (
      <InfoAlert
        message="Before we can proceed with providing customs brokerage services for you, please go through our customs onboarding steps."
        onClose={() => setShowCustomsAlert(false)}
        actionText="Finish"
        onActionClick={() => setOpenModal(true)}
      />
    );
  };

  return (
    <div>
      <div className="main-page-header">
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: '26px',
          }}
        >
          Home
        </Typography>
      </div>
      <div className="universal-subheader">
        Hi {activeUser.user?.firstName}, welcome back! Here's your summary of all things in-transit.
      </div>
      {!mobileAlert && renderWarning(searchesWarning)}
      <div>
        {mobileAlert && renderMobileAlert()}
        {!activeCustomsOnboarding.hasCompletedOnboarding && showCustomsAlert && !isMobileDevice && renderAlertDetail()}
      </div>
      <div className="call-to-action-container">
        <Card>
          <CardContent>
            <div className="my-shipments-header">Compliance Tools</div>
            <div className="my-shipments-subheader">
              Need to calculate your costs? Our compliance tools do the dirty work.
            </div>
            <div className="compliance-buttons-container">
              <div className="next-button-container compliance-next-button">
                <Button
                  startIcon={<FactCheck />}
                  className="clear-compliance-buttons"
                  sx={{ width: '200px!important' }}
                  onClick={handleComplianceOneClick}
                >
                  Compliance One
                </Button>
              </div>
              <div className="next-button-container compliance-next-button">
                <Button
                  startIcon={<CalculateOutlinedIcon />}
                  className="clear-compliance-buttons"
                  sx={{ width: '200px!important' }}
                  onClick={handleDutyCalculatorClick}
                >
                  Duty Calculator
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
        {!isMobileDevice && (
          <Card>
            <CardContent>
              <div className="my-shipments-header">Import Services</div>
              <div className="my-shipments-subheader">Let's get your shipment from Point A to Point B.</div>
              <div className="import-services-container">
                <div>
                  <div>
                    {renderCustomsClearanceButton()}
                    {open && <CustomsClearance open={open} onClose={handleClose} getShipments={getShipments} />}
                  </div>
                </div>
                <div>
                  <div>
                    <div>{renderHtsClassificationButton()}</div>

                    {openHtsClassifications && (
                      <HtsClassification open={openHtsClassifications} onClose={handleHtsClassificationsClose} />
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
      {!isMobileDevice && (
        <div className="main-container">
          <div className="my-shipments-container">
            <div>
              <Card className="my-shipments-card">
                <CardContent sx={{ boxShadow: 'none' }}>
                  <div className="my-shipments-header">My Shipments</div>
                  <div className="my-shipments-subheader">At a glance</div>
                  <ShipmentTable rows={shipments} isLoading={isLoading} />
                </CardContent>
              </Card>
            </div>
            <ImportalCard title={'My HTS Classifications'} subtitle={'At a glance'}>
              <ClassificationsTable rows={classifications} isLoading={isLoading} />
            </ImportalCard>
            {openModal && <FullOnBoardingModal open={openModal} handleClose={handleCloseModal} />}
          </div>
        </div>
      )}
    </div>
  );
}
