import React, { useState } from 'react';
import { TextField, Grid, Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import { useAPI } from '@/api/APIContext';
import { AxiosError } from 'axios';
import ImportalSecondaryButton from '@/shared-components/ImportalSecondaryButton/ImportalSecondaryButton';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import { keyframes } from '@mui/system';

/**
 * Keyframe animation for cargo image (zoom and/or fade).
 */
const fadeZoom = keyframes`
  0% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(1.5);
  }
`;

export default function Login() {
  const navigate = useNavigate();
  const theme = useTheme();

  // This hook returns `true` for screens >= the 'sm' breakpoint
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const activeUser = useActiveUser();
  const api = useAPI();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignUpAccount = () => {
    navigate('/signup');
  };

  const handleForgotPasswordClick = () => {
    navigate('/forgot-password');
  };

  /**
   * Handle the form submission
   */
  const handleSubmit = () => {
    if (!email || !password) {
      setErrorText('Please enter your email and password');
      return;
    }
    loginUser({ email, password });
  };

  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  /**
   * Make API call to login
   */
  const loginUser = (loginData: { email: string; password: string }) => {
    handleLoading(true);
    api
      .login(loginData)
      .then(async (response) => {
        datadogRum.addAction('login', {});
        api.setToken(response.data.token);
        await activeUser.refreshUser();
        handleLoading(false);
        navigate('/dashboard');
      })
      .catch((error: AxiosError<any>) => {
        console.error('error logging in', error);

        // If the user must finish phone auth, redirect them
        if (error.response?.data?.error === 'User must complete authentication before login') {
          navigate('/authenticate-phone');
        }

        handleLoading(false);
        setErrorText(error.response?.data?.error?.message || 'Unable to log in');
      });
  };

  // Style object for labels
  const signupFormLabel: React.CSSProperties = {
    marginBottom: '7px',
    color: 'white',
    fontWeight: 700,
  };

  // Style for the "Sign up" secondary button
  const signupButtonStyle: React.CSSProperties = {
    color: 'white',
    textTransform: 'none',
    width: isSmUp ? '250px' : '100%',
  };

  // Style for the "Login" primary button
  const buttonStyle: React.CSSProperties = {
    textTransform: 'none',
    width: isSmUp ? '250px' : '100%',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: 'black',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      {/* LEFT SIDE: LOGO + FORM */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 4,
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src="IMPORTAL-FUTURE.png" width="218" height="38" alt="logo" />
        </Container>

        <Box
          sx={{
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            pr: '22%',
            pl: '22%',
            color: 'white',
            // Adjust padding on smaller breakpoints
            [theme.breakpoints.down('md')]: {
              pr: '8%',
              pl: '8%',
            },
            [theme.breakpoints.down('sm')]: {
              pr: '5%',
              pl: '5%',
            },
          }}
        >
          {/* FORM */}
          <Box
            component="form"
            noValidate
            // This allows hitting Enter to submit the form:
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            sx={{ mt: 3, width: '100%' }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box component="div" style={signupFormLabel}>
                  Email
                </Box>
                <TextField
                  fullWidth
                  id="email"
                  size="small"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  InputProps={{
                    style: { backgroundColor: 'white', fontSize: '14px' },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box component="div" style={signupFormLabel}>
                  Password
                </Box>
                <TextField
                  type="password"
                  fullWidth
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  size="small"
                  name="password"
                  InputProps={{
                    style: { backgroundColor: 'white', fontSize: '14px' },
                  }}
                />
              </Grid>
            </Grid>

            {/* FORGOT PASSWORD */}
            <Box
              sx={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '28px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 1,
              }}
              onClick={handleForgotPasswordClick}
            >
              Forgot Password?
            </Box>

            {/* ERROR MESSAGE */}
            <Box
              sx={{
                color: 'red',
                display: 'flex',
                justifyContent: 'center',
                fontSize: '13px',
                mt: 1,
              }}
            >
              {errorText}
            </Box>

            {/* BUTTONS */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                mt: 3,
                gap: { xs: 2, sm: 0 },
              }}
            >
              {/* Secondary (Sign Up) Button - non-submit */}
              <ImportalSecondaryButton text="Sign up" onClick={handleSignUpAccount} style={signupButtonStyle} />

              {/* Primary (Login) Button - type="submit" for Enter key submission */}
              <ImportalPrimaryButton
                type="submit"
                text="Login"
                disabled={isLoading}
                style={buttonStyle}
                onClick={() => {}}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* RIGHT SIDE: CARGO IMAGE (WITH ANIMATION)
          Hidden on smaller devices
      */}
      <Box
        sx={{
          flex: 1,
          overflow: 'hidden',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Box
          component="img"
          src="cargo_in_ocean.png"
          alt="Cargo in ocean"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
          }}
        />
      </Box>
    </Box>
  );
}
