import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Container, Box, Typography, Link, useTheme } from '@mui/material';
import { Check } from '@mui/icons-material';
import SignupForm from '@/pages/signup/SignupForm';

export default function SignUp() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [randomIndex, setRandomIndex] = useState(0);

  const testimonials = [
    {
      quote: 'Very intuitive and straightforward',
      source: 'Head of Operations, Growing apparel group',
    },
    {
      quote: 'I wish I had this tool my whole career.',
      source: 'Head of Production, Large apparel brand',
    },
    {
      quote:
        'Importal helps our team quickly and easily compare vendors for new product lines and the customs costs associated so we always know what our landed cost is before we buy.',
      source: 'VP of Supply Chain, Growing apparel company',
    },
    {
      quote: "I can't do my job without this tool.",
      source: 'Operations Director, Freight forwarding company',
    },
    {
      quote:
        'Importal helps our team quickly and easily compare vendors for new product lines and the customs costs associated so we always know what our landed cost is before we buy.',
      source: 'VP of Supply Chain, Growing apparel company',
    },
    {
      quote: 'Great tool for helping us accurately project our duty liability for outstanding purchase orders.',
      source: 'Operations Manager, Growing apparel company',
    },
  ];

  useEffect(() => {
    setRandomIndex(getRandomIndex(testimonials.length));
  }, []);

  function getRandomIndex(arrayLength: number) {
    return Math.floor(Math.random() * arrayLength);
  }

  const handleExistingAccount = () => {
    navigate('/login');
  };

  // Helper to render the testimonial
  const renderTestimonialQuote = (testimonial: any) => {
    if (!testimonial) return null;
    return (
      <>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
          }}
        >
          "{testimonial.quote}"
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontStyle: 'italic',
            fontWeight: 600,
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)',
          }}
        >
          - {testimonial.source}
        </Typography>
      </>
    );
  };

  // Inline style for the overlay container:
  const overlayContainerStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: 'white',
    padding: '32px',
    maxWidth: '80%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '12px',
    boxSizing: 'border-box',
  };

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: 'black',
      }}
    >
      {/* Left side: Signup */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src="IMPORTAL-FUTURE.png" width="218" height="38" alt="Importal Logo" />
        </Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            pr: '22%',
            pl: '22%',
            color: 'white',
            // For smaller screens, let's reduce padding so it doesn't look weird
            [theme.breakpoints.down('md')]: {
              pr: '5%',
              pl: '5%',
            },
          }}
        >
          <Box sx={{ width: '100%', textAlign: 'center', mb: 1 }}>
            <Typography variant="body2">
              <Link
                onClick={handleExistingAccount}
                sx={{
                  cursor: 'pointer',
                  color: '#fff',
                  textDecoration: 'underline',
                  fontWeight: 700,
                  fontSize: '14px',
                  ':hover': {
                    color: '#f5f5f5',
                    textDecoration: 'none',
                  },
                }}
              >
                Already have an account? Sign in
              </Link>
            </Typography>
          </Box>
          <SignupForm />
        </Box>
      </Box>

      {/* Right side: Cargo Image with overlay for testimonials/features
          Hidden on screens smaller than 'md'. */}
      <Box
        sx={{
          flex: 1,
          position: 'relative',
          overflow: 'hidden',
          display: { xs: 'none', md: 'block' }, // hide on xs/sm
        }}
      >
        <img
          src="cargo_in_ocean.png"
          alt="Cargo in ocean"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'block',
          }}
        />

        {/* OVERLAY: Testimonials + Key Features */}
        <div style={overlayContainerStyle}>
          {/* Random Testimonial */}
          <Box sx={{ mb: 3 }}>{renderTestimonialQuote(testimonials[randomIndex])}</Box>

          {/* Key Features */}
          <Typography sx={{ fontWeight: '600' }} variant="h5" gutterBottom>
            Key Features
          </Typography>
          <Box sx={{ textAlign: 'left', margin: '0 auto', maxWidth: 400 }}>
            <FeatureRow text="General Duty Rates" />
            <FeatureRow text="Merchandise Processing Fee" />
            <FeatureRow text="Harbor Maintenance Fee" />
            <FeatureRow text="China 301 Rates" />
            <FeatureRow text="China 301 Exclusions" />
            <FeatureRow text="Cotton Fee" />
            <FeatureRow text="Import Licenses" />
            <FeatureRow text="...and much more!" />
          </Box>
        </div>
      </Box>
    </div>
  );
}

function FeatureRow({ text }: { text: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography sx={{ fontWeight: 600 }} variant="body2">
        {text}
      </Typography>
      <Check sx={{ color: 'green' }} />
    </Box>
  );
}
