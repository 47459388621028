import ImportalTable, { ImportalTableHandles } from '@/shared-components/ImportalTable/ImportalTable';
import { ColumnConfig, ImportalTableDataType, TableConfig } from 'common/interfaces/importalTable';
import { columnConfigsFromData } from 'common/utilities/importalTable';
import jsonata from 'jsonata';
import { forwardRef, useEffect, useState } from 'react';

export interface Props {
  columnConfig?: Array<{ name: string; displayName: string; dataType?: ImportalTableDataType | string }>;
  data: any[];
  jsonataExpression?: string;
}

const ReportTable = forwardRef<ImportalTableHandles, Props>(({ data, columnConfig, jsonataExpression }, ref) => {
  const applyJsonataTransformation = async (data: any[], jsonataExpression?: string) => {
    if (!jsonataExpression || jsonataExpression.length === 0) return data;
    try {
      const expression = jsonata(jsonataExpression);
      const transformedData = await expression.evaluate(data);
      return transformedData ?? [];
    } catch (error) {
      console.error('JSONata transformation failed:', error);
      return data; // If JSONata fails, return original data
    }
  };

  const [transformedData, setTransformedData] = useState<any[]>(data);

  useEffect(() => {
    const transformData = async () => {
      const newData = await applyJsonataTransformation(data, jsonataExpression);
      setTransformedData(newData);
    };

    transformData();
  }, [data, jsonataExpression]);

  const addSortAndFilterToColumns = (
    columns: Array<{ name: string; displayName: string; dataType?: ImportalTableDataType | string }>
  ): ColumnConfig[] => {
    return columns.map((column) => ({
      name: column.name,
      displayName: column.displayName,
      dataType: column.dataType || 'string',
      sortable: true,
      filterable: true,
      isVisible: true,
    }));
  };

  const createTableConfig = () => {
    return {
      // @ts-ignore
      columns: addSortAndFilterToColumns(columnConfig ?? columnConfigsFromData(transformedData)),
      sortConfig: [],
      filterConfig: {},
    };
  };

  const [reportTableConfig, setReportTableConfig] = useState<TableConfig>(createTableConfig);

  useEffect(() => {
    const tableConfig = createTableConfig();
    setReportTableConfig(tableConfig);
  }, [transformedData, columnConfig]);

  // all keys below the first level MUST not be object
  const transformDataForReport = (data: any[]) => {
    return data.map((datum) =>
      Object.keys(datum).reduce((cleanedDatum, key) => {
        cleanedDatum[key] = typeof datum[key] === 'object' ? JSON.stringify(datum[key]) : datum[key];
        return cleanedDatum;
      }, {})
    );
  };

  return (
    <ImportalTable
      ref={ref}
      tableConfig={reportTableConfig}
      setTableConfig={setReportTableConfig}
      data={transformDataForReport(transformedData)}
      embeddedStyling={true}
      useSettings={false}
    />
  );
});

export default ReportTable;
