import { Alert, CircularProgress, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';

export interface LoadingState {
  loadingStatus: LoadingStatus;
  error?: any;
}

export enum LoadingStatus {
  NOT_LOADING = 'NOT_LOADING',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface Props {
  loadingState: LoadingState;
  loadingText: string;
  successText: string;
  errorText?: string;
}

const snackbarStyles = {
  '& .MuiPaper-root': { borderRadius: '20px' },
};

const alertStyles = {
  width: '100%',
  borderRadius: 8,
  fontSize: '12px',
  padding: '6px 16px',
  minHeight: 'unset',
  display: 'flex',
  alignItems: 'center',
};

const DataImportLoader = ({ loadingState, loadingText, successText, errorText }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
  }, [loadingState.loadingStatus]);

  const renderErrorMessage = () => {
    if (typeof loadingState.error === 'string') {
      return loadingState.error || errorText || 'Error';
    }
    if (loadingState.error && typeof loadingState.error === 'object') {
      return loadingState.error.message || errorText || 'Error';
    }
    return errorText || 'Error';
  };

  const renderSnackbar = ({
    isOpen,
    severity,
    icon,
    text,
    autoHideDuration,
  }: {
    isOpen: boolean;
    severity: 'success' | 'error' | 'info';
    icon?: React.ReactNode;
    text: string;
    autoHideDuration: number | null;
  }) => (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={() => setIsOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={snackbarStyles}
    >
      <Alert
        severity={severity}
        icon={icon}
        variant="filled"
        sx={{
          ...alertStyles,
          ...(severity === 'success' && { fontWeight: 600 }),
          ...(severity === 'error' && { fontWeight: 600 }),
          ...(severity === 'info' && { backgroundColor: '#c0dcc2!important', color: '#525256!important' }),
        }}
      >
        {text}
      </Alert>
    </Snackbar>
  );

  return (
    <>
      {loadingState.loadingStatus === LoadingStatus.LOADING &&
        renderSnackbar({
          isOpen,
          severity: 'info',
          icon: <CircularProgress color="inherit" size={20} />,
          text: loadingText,
          autoHideDuration: null,
        })}

      {loadingState.loadingStatus === LoadingStatus.SUCCESS &&
        renderSnackbar({
          isOpen,
          severity: 'success',
          text: successText,
          autoHideDuration: 3000,
        })}

      {loadingState.loadingStatus === LoadingStatus.ERROR &&
        renderSnackbar({
          isOpen,
          severity: 'error',
          text: renderErrorMessage(),
          autoHideDuration: 3000,
        })}
    </>
  );
};

export default DataImportLoader;
