import React from 'react';
import { errorMessageMap } from '@/utils/errorMessages';
import InfoAlert from '@/components/info-alert/InfoAlert';
import WarningAlert from './WarningAlert/WarningAlert';

const renderProductLibraryNotifications = (
  notifications,
  setCloseInfoAlert,
  setCloseWarningAlert,
  closeInfoAlert,
  closeWarningAlert,
  onWarningAlertActionClick
) => {
  // Keep this utility function the same.
  const getFormattedMessage = (errorCode, quantity) => {
    const messageTemplate = errorMessageMap[errorCode] || errorCode;

    const templateData = {
      plural: quantity > 1 ? 's' : '',
      verb: quantity > 1 ? 'are' : 'is',
      verbHasHave: quantity > 1 ? 'have' : 'has',
    };

    const message = messageTemplate
      .replace('{quantity}', quantity)
      .replace('{plural}', templateData.plural)
      .replace('{verb}', templateData.verb)
      .replace('{verbHasHave}', templateData.verbHasHave);

    return message;
  };

  // 1) Treat all these codes as "warnings."
  const warningNotifications = notifications.filter(
    (n) =>
      n.errorCode === 'FEB_2025_CHINA_TARIFF' ||
      n.errorCode === 'MARCH_2025_CHINA_TARIFF' ||
      n.errorCode === 'APRIL_2025_RECIPROCAL_TARIFF'
  );

  // 2) Everything else remains "info notifications."
  const infoNotifications = notifications.filter(
    (n) =>
      n.errorCode !== 'FEB_2025_CHINA_TARIFF' &&
      n.errorCode !== 'MARCH_2025_CHINA_TARIFF' &&
      n.errorCode !== 'APRIL_2025_RECIPROCAL_TARIFF'
  );

  // Same logic for combining multiple info messages into one InfoAlert.
  const infoMessage = infoNotifications
    .map((notification) => getFormattedMessage(notification.errorCode, notification.total))
    .join(', ');

  // 3) Tally how many products fall under China vs. Reciprocal tariffs.
  let totalChinaTariffs = 0;
  let totalReciprocalTariffs = 0;

  warningNotifications.forEach((wn) => {
    if (wn.errorCode === 'FEB_2025_CHINA_TARIFF' || wn.errorCode === 'MARCH_2025_CHINA_TARIFF') {
      totalChinaTariffs += wn.total;
    } else if (wn.errorCode === 'APRIL_2025_RECIPROCAL_TARIFF') {
      totalReciprocalTariffs += wn.total;
    }
  });

  // 4) Construct one combined warning message.
  let warningMessage = '';
  if (totalChinaTariffs > 0 && totalReciprocalTariffs > 0) {
    warningMessage = `${totalChinaTariffs} product${
      totalChinaTariffs === 1 ? '' : 's'
    } are subject to the Trump administration's recently announced February and March Additional China Tariffs, and April Reciprocal Tariffs.`;
  } else if (totalChinaTariffs > 0 && totalReciprocalTariffs === 0) {
    warningMessage = `${totalChinaTariffs} product${
      totalChinaTariffs === 1 ? '' : 's'
    } are subject to the Trump administration's recently announced February and March Additional China Tariffs.`;
  } else if (totalChinaTariffs === 0 && totalReciprocalTariffs > 0) {
    warningMessage = `${totalReciprocalTariffs} product${
      totalReciprocalTariffs === 1 ? '' : 's'
    } are subject to the Trump administration's recently announced April Reciprocal Tariffs.`;
  }

  return (
    <div>
      {/* Render the WarningAlert if we have any warning notifications and the user hasn't dismissed it. */}
      {warningNotifications.length > 0 && closeWarningAlert && (
        <WarningAlert
          message={warningMessage}
          onClose={() => setCloseWarningAlert(false)}
          actionText="See which ones"
          // Pass along all warning notifications, or handle them however you like.
          onActionClick={() => onWarningAlertActionClick(warningNotifications)}
        />
      )}

      {/* Keep the info alert exactly as it was. */}
      {infoNotifications.length > 0 && closeInfoAlert && (
        <InfoAlert message={infoMessage} onClose={() => setCloseInfoAlert(false)} />
      )}
    </div>
  );
};

export default renderProductLibraryNotifications;
