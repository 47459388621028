import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ADDModal.css';

export interface SimpleDialogProps {
  row: any;
  open: boolean;
  onClose: (e?: any) => void;
}

export default function ADDModal(props: SimpleDialogProps) {
  const { onClose, open, row } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="contact-us-dialog-container">
      <Dialog maxWidth="lg" onClose={handleClose} open={open}>
        <div>
          <div>
            <div className="header-question">
              <Typography>Anti-Dumping Duties</Typography>
            </div>
            <div className="pga-description-subheader">
              The fair trade enforcement tariffs against foreign 'dumping'
            </div>
            <IconButton
              onClick={handleClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'grey',
              }}
            >
              <CloseIcon sx={{ fontSize: '24px' }} />
            </IconButton>
          </div>
          <div className="pga-description-title">About the rates</div>
          <div className="pga-description-subheader">
            The rates shown are blanket rates for the entire country, but specific suppliers or shippers may have higher
            or lower rates. These duties can be expensive and affect your business for years, so understanding the risks
            is key. Talk to an <b>Importal Customs Broker</b> to check if your supplier’s rate differs and make sure
            you’re fully aware of any potential liabilities.
          </div>
          <div className="pga-questions-container">
            <div>Questions? We're here to help.</div>
            <div className="next-button-container">
              <a
                style={{ textDecoration: 'none' }}
                target="_blank"
                href="https://calendly.com/alex-0rfp/30min"
                rel="noreferrer"
              >
                <Button
                  sx={{ minWidth: '200px' }}
                  variant="contained"
                  className="next-button-green"
                  href="mailto:brokers@goimportal.com?subject=Inquiry:%20Anti-Dumping%20Duties%20on%20[Product%20Name]"
                >
                  Send us an email
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
