import * as React from 'react';
import Box from '@mui/material/Box';
import { Dialog, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImportalPrimaryButton from '../ImportalPrimaryButton/ImportalPrimaryButton';

const FeatureWalkthroughModal = ({ open, handleClose }) => {
  return (
    <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
      <IconButton
        onClick={handleClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'grey',
        }}
      >
        <CloseIcon sx={{ fontSize: '24px' }} />
      </IconButton>
      <div className="header-question">
        <Typography>Walkthrough of our features</Typography>
      </div>
      <div style={{ color: 'grey', fontSize: '14px', marginBottom: '16px', marginTop: '8px' }}>
        Use our product library, compliance tools, and brokerage services right through our app.
      </div>
      <Box style={{ padding: '8px' }} height="500px">
        <iframe
          title="Feature Walkthrough"
          src="https://app.storylane.io/share/dbirqvrf18cb"
          width="100%"
          height="100%"
          style={{ padding: '2px', border: 'none', borderRadius: '8px' }}
          allowFullScreen
        />
      </Box>
      <div className="next-button-container">
        <ImportalPrimaryButton text="Close" onClick={handleClose} />
      </div>
    </Dialog>
  );
};

export default FeatureWalkthroughModal;
