import { Route, Routes } from 'react-router-dom';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { BrokerClassificationPage } from '@/broker-app/pages/hts-classifications/BrokerClassificationPage';
import { useTheme } from '@mui/material';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { useAPI } from '@/api/APIContext';
import { Classification } from 'common/interfaces/classification';
import ClassificationsTable from '@/broker-app/pages/hts-classifications/BrokerClassificationsTable';
import ImportalPage from '@/shared-components/ImportalPage/ImportalPage';

export interface IBrokerClassificationsContext {
  refreshClassifications: () => void;
  loadingStatus?: LoadingStatus;
  loadingText?: string;
  successText?: string;
  errorText?: string;
  setLoadingStatus: (status: LoadingStatus) => void;
  setLoadingText: (text: string) => void;
  setSuccessText: (text: string) => void;
  setErrorText: (text: string) => void;
}

export const BrokerClassificationsContext = createContext<IBrokerClassificationsContext>({
  refreshClassifications: () => {},
  loadingStatus: LoadingStatus.NOT_LOADING,
  loadingText: '',
  successText: '',
  errorText: '',
  setLoadingStatus: () => {},
  setLoadingText: () => {},
  setSuccessText: () => {},
  setErrorText: () => {},
});

export function BrokerClassifications() {
  const theme = useTheme();
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);

  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  const [classifications, setClassifications] = useState<Classification[]>([]);

  const refreshClassifications = useCallback(() => {
    setIsLoading(true);
    api
      .getAllClassifications()
      .then(({ data }) => {
        setClassifications(data);
      })
      .catch((err) => {
        console.error('error getting classifications for broker table');
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    refreshClassifications();
  }, []);

  return (
    <>
      <BrokerClassificationsContext.Provider
        value={{
          refreshClassifications,
          loadingStatus,
          loadingText,
          successText,
          errorText,
          setLoadingStatus,
          setLoadingText,
          setErrorText,
          setSuccessText,
        }}
      >
        <Routes>
          <Route
            index
            element={
              <>
                <ImportalPage
                  header={<>HTS Classifications</>}
                  subheader="View and manage HTS classifications"
                  loadingStatus={loadingStatus}
                  loadingText={loadingText}
                  successText={successText}
                  errorText={errorText}
                >
                  <div className="shipments-table-container">
                    <ClassificationsTable rows={classifications} isLoading={isLoading}></ClassificationsTable>
                  </div>
                </ImportalPage>
              </>
            }
          />
          <Route path="/*" element={<BrokerClassificationPage />} />
        </Routes>
      </BrokerClassificationsContext.Provider>
    </>
  );
}
