import { renderChartLabel } from '@/utils/reportingUtils';
import { ChartUnitType } from 'common/interfaces/reports';
import * as d3 from 'd3';
import { useMemo, useState } from 'react';
import { ImportalChartTooltip } from './ImportalChartTooltip';

interface ImportalHistogramBarsProps {
  width: number;
  height: number;
  categories: { category: string; value: number }[];
  range: number[];
  highlightedValue?: string;
  customGridLines?: number[];
  unit: ChartUnitType;
  rounded?: boolean;
}

interface HoverState {
  index: number;
  x: number;
  y: number;
  label: string;
  value: number;
}

/**
 * Helper to generate a path for a rectangle with only the top corners rounded.
 *
 * @param x - The x position of the rectangle (local coordinate).
 * @param y - The y position (top) of the rectangle.
 * @param width - The width of the rectangle.
 * @param height - The height of the rectangle.
 * @param radius - The radius for the top corners.
 */
function getRoundedTopRectPath(x: number, y: number, width: number, height: number, radius: number): string {
  const r = Math.min(radius, width / 2);
  return `
    M ${x} ${y + r}
    Q ${x} ${y} ${x + r} ${y}
    H ${x + width - r}
    Q ${x + width} ${y} ${x + width} ${y + r}
    V ${y + height}
    H ${x}
    Z
  `;
}

export const ImportalHistogramBars = ({
  width = 1,
  height = 1,
  categories = [],
  range = [10, 290],
  highlightedValue,
  customGridLines,
  unit,
  rounded = false,
}: ImportalHistogramBarsProps) => {
  const [hoverState, setHoverState] = useState<HoverState | null>(null);

  // 1) Grid Lines
  const yAxisTicks = useMemo(() => {
    if (!customGridLines) return [];
    const yScale = d3.scaleLinear().domain(range).range([0, height]);
    return customGridLines.map((tick) => ({
      value: tick,
      yPos: height - yScale(tick),
    }));
  }, [height, range, customGridLines]);

  // 2) Bars
  const histoBars = useMemo(() => {
    const xScale = d3
      .scaleBand()
      .domain(categories.map((d) => d.category))
      .range([0, width])
      .padding(0.2);

    const yScale = d3.scaleLinear().domain(range).range([0, height]);
    const maxBarWidth = 50;

    return categories.map((d) => {
      const bandwidth = xScale.bandwidth();
      const clampedBarWidth = Math.min(bandwidth, maxBarWidth);
      const barXCenter = xScale(d.category)! + bandwidth / 2;

      return {
        barHeight: yScale(d.value),
        barWidth: clampedBarWidth,
        barX: barXCenter - clampedBarWidth / 2,
        barValue: d.value,
        category: d.category,
        isHighlighted: d.category === highlightedValue,
      };
    });
  }, [width, height, categories, range, highlightedValue]);

  return (
    <>
      {/* Grid Lines */}
      {yAxisTicks.map((tick) => (
        <line
          key={tick.value}
          x1={0}
          y1={tick.yPos}
          x2={width}
          y2={tick.yPos}
          stroke="#E5E5EF"
          strokeDasharray="8,8"
          strokeWidth={2}
        />
      ))}

      {/* Bars */}
      {histoBars.map((bar, index) => {
        const { barHeight, barWidth, barX, barValue, category, isHighlighted } = bar;
        const fillColor = isHighlighted ? '#388e3c' : '#388e3c';

        return (
          <g
            key={index}
            transform={`translate(${barX}, ${height - barHeight})`}
            onMouseEnter={() => {
              const tooltipX = barX + barWidth / 2;
              const tooltipY = height - barHeight - 8;
              setHoverState({ index, x: tooltipX, y: tooltipY, label: category, value: barValue });
            }}
            onMouseLeave={() => setHoverState(null)}
          >
            {rounded && barHeight >= 4 ? (
              <path d={getRoundedTopRectPath(0, 0, barWidth, barHeight, 8)} fill={fillColor} />
            ) : (
              <path
                d={`
                  M 0,${barHeight}
                  L 0,0
                  L ${barWidth},0
                  L ${barWidth},${barHeight}
                  Z
                `}
                fill={fillColor}
              />
            )}
          </g>
        );
      })}

      {/* Render the tooltip above the bars */}
      {hoverState && (
        <ImportalChartTooltip x={hoverState.x} y={hoverState.y + 15} value={hoverState.value} unit={unit} />
      )}
    </>
  );
};
