import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { useEffect, useState } from 'react';
import * as ls from 'local-storage';
import { Box, Button, styled, Typography, useTheme } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import CheckIcon from '@mui/icons-material/Check';
import './DocumentsAndSignature.css';
import { CustomsOnboarding, CustomsOnboardingSteps } from 'common/interfaces/customsOnboarding';
import { useAPI } from '@/api/APIContext';
import { useActiveCustomsOnboarding } from '@/custom-hooks/customs-onboarding/CustomsOnboardingProvider';
import { Document, DocumentType } from 'common/interfaces/document';
import { BusinessWithDocuments } from 'common/interfaces/business';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Input = styled('input')({
  display: 'none',
});

interface Props {
  handleEvent: () => void;
  handleBack: () => void;
  handleLoading: (boolean) => void;
  updateCustomsOnboarding: (businessId: string, customsOnboarding: CustomsOnboarding) => void;
  customsOnboarding?: CustomsOnboarding;
}

export default function DocumentsAndSignature({
  handleEvent,
  handleBack,
  handleLoading,
  updateCustomsOnboarding,
  customsOnboarding,
}: Props) {
  const theme = useTheme();
  const activeBusiness = useActiveBusiness();
  const activeCustomsOnboarding = useActiveCustomsOnboarding();

  const api = useAPI();
  const [hasCompletedPoa, setHasCompletedPoa] = useState(false);

  useEffect(() => {
    if (!activeCustomsOnboarding.customsOnboarding?.step) return;
    if (
      [CustomsOnboardingSteps.CUSTOMS_INFO, CustomsOnboardingSteps.SIGN_POA].includes(
        activeCustomsOnboarding.customsOnboarding?.step
      )
    ) {
      setValue(1);
    }
  }, [customsOnboarding]);

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [esignUrl, setEsignUrl] = useState('');

  const renderPhotoIdButton = (photoIdFileUploaded) => {
    if (!photoIdFileUploaded) {
      return (
        <div className="photo-id-button-container">
          <Button
            fullWidth
            variant="outlined"
            endIcon={<BadgeIcon />}
            component="label"
            sx={{
              minWidth: '150px',
              fontSize: '16px',
              textTransform: 'none',
              '& .MuiButton-endIcon': {
                marginLeft: '6px!important',
              },
            }}
          >
            Upload
            <Input type="file" accept="*" hidden onChange={handleFileUploadPhoto} />
          </Button>
        </div>
      );
    } else {
      return (
        <div className="confirmation-container">
          <Typography sx={{ color: theme.palette.primary.main, fontSize: '16px' }}>Thanks, we got it.</Typography>
          <CheckCircleIcon sx={{ color: theme.palette.primary.main, fontSize: '24px', marginLeft: '5px' }} />
        </div>
      );
    }
  };

  const photoIdIsUploaded = (): boolean => {
    return !!activeBusiness.business?.documents.some((document) => document.type === DocumentType.PHOTO_ID);
  };

  const poaIsSigned = (): boolean => {
    return !!activeBusiness.business?.documents.some((document) => document.type === DocumentType.POA);
  };

  const updateBusiness = (updatedBusiness: BusinessWithDocuments) => {
    const businessId = activeBusiness.business?._id?.toString();
    if (businessId) {
      api
        .updateBusiness(businessId, updatedBusiness)
        .then(() => {
          activeBusiness.refreshBusiness();
        })
        .catch((err) => {
          console.error('error updating business in customs onboarding');
          console.error(err);
          throw err;
        });
    }
  };

  const handleFileUploadPhoto = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    const newDocument = {
      type: DocumentType.PHOTO_ID,
    } as Document;
    api
      .createDocument(newDocument, file)
      .then(({ data }) => {
        updateCustomsOnboarding(activeBusiness.business!._id!.toString(), {
          step: CustomsOnboardingSteps.SIGN_POA,
        } as CustomsOnboarding);
        saveDocumentToBusiness(data);
      })
      .catch((err) => {
        console.error('error uploading photo id');
        console.error(err);
      });
  };

  function extractNames(fullName) {
    const parts = fullName.trim().split(/\s+/); // Split the name by whitespace, handling any amount of extra spaces
    if (parts.length === 1) {
      return { firstName: parts[0], lastName: '' }; // Handle cases where only one name part is provided
    }

    const firstName = parts[0];
    const lastName = parts.slice(1).join(' '); // Join the remaining parts as the last name

    return { firstName, lastName };
  }

  const longPollForPOASigned = async () => {
    let poaDocumentDelivered = false;
    do {
      await new Promise((resolve) => setTimeout(resolve, 3000)); // wait 3 seconds
      const businessId = activeBusiness.business!._id!.toString();
      poaDocumentDelivered = ls.get(`poa-signed-for-business-${businessId}`);
    } while (!poaDocumentDelivered);
    setHasCompletedPoa(true);
  };

  useEffect(() => {
    longPollForPOASigned();
  }, []);

  const handleEsign = (business, authorizedSigner) => {
    api
      .createEsign({
        userFirstName: extractNames(authorizedSigner.fullName).firstName,
        userLastName: extractNames(authorizedSigner.fullName).lastName,
        userEmail: authorizedSigner.email,
        userFullName: authorizedSigner.fullName,
        businessName: business.name,
        address: business.address,
        ein: business.ein,
        signeeTitle: authorizedSigner.title,
        businessStructure: business.businessStructure,
        additionalState: business.additionalState,
        redirect_url: `${window.location.origin}/EsignSuccess`,
      })
      .then((response) => {
        setEsignUrl(response.data.esignUrl);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSignPoaNext = () => {
    updateCustomsOnboarding(activeBusiness.business!._id!.toString(), {
      step: CustomsOnboardingSteps.CUSTOMS_INFO,
    } as CustomsOnboarding);
    handleEvent();
  };

  useEffect(() => {
    if (customsOnboarding?.authorizedSigner && value === 1) {
      handleEsign(activeBusiness.business, customsOnboarding.authorizedSigner);
    }
  }, [customsOnboarding, value]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handlePhotoIdNext = () => {
    updateCustomsOnboarding(activeBusiness.business!._id!.toString(), {
      step: CustomsOnboardingSteps.SIGN_POA,
    } as CustomsOnboarding);
    setValue(1);
  };

  const saveDocumentToBusiness = (document: Document) => {
    const updatedBusiness: BusinessWithDocuments = {
      ...(activeBusiness.business as BusinessWithDocuments),
      documents: [...(activeBusiness.business?.documents || []), document],
    };
    updateBusiness(updatedBusiness);
  };

  const renderTabLabelPOA = () => {
    return (
      <>
        <div className="tab-label-container">
          <div className="tab-label-text">Sign Our POA</div>
          {customsOnboarding?.step === CustomsOnboardingSteps.CUSTOMS_INFO ? (
            <div>
              <CheckIcon sx={{ color: 'green' }} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  const renderTabLabelPhotoId = () => {
    return (
      <>
        <div className="tab-label-container">
          <div className="tab-label-text">Photo ID</div>
          {customsOnboarding?.step &&
          [CustomsOnboardingSteps.CUSTOMS_INFO, CustomsOnboardingSteps.SIGN_POA].includes(customsOnboarding?.step) ? (
            <div>
              <CheckIcon sx={{ color: 'green' }} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  const isNextButtonEnabled = () => {
    return hasCompletedPoa;
  };

  const renderNextButton = () => {
    return (
      <div className="product-image-next-container">
        <div className="next-button-container">
          <Button fullWidth className="back-button-text" variant="text" onClick={handleBack}>
            Back
          </Button>
          <Button
            type="submit"
            onClick={handlePhotoIdNext}
            fullWidth
            variant="text"
            disabled={!photoIdIsUploaded()}
            sx={{ minWidth: '220px' }}
            className="next-button-green"
            endIcon={<KeyboardArrowRightIcon />}
          >
            Next: Sign our POA
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab sx={{ textTransform: 'none' }} label={renderTabLabelPhotoId()} {...a11yProps(0)} />
            <Tab sx={{ textTransform: 'none' }} label={renderTabLabelPOA()} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className="photo-id-content-container">
            <div className="business-info-header">
              <Typography>Photo ID</Typography>
              <div className="my-shipments-subheader">Upload a copy of your government-issued Photo ID</div>
            </div>
            <div className="upload-documents-container">{renderPhotoIdButton(photoIdIsUploaded())}</div>
          </div>
          {renderNextButton()}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="business-info-header">
            <Typography>Sign our Power of Attorney</Typography>
          </div>
          <div className="my-shipments-subheader">
            We've pre-filled most of your business information based on what we have on file
          </div>
          <div className="poa-iframe-container">
            <div className="poa-iframe">
              {poaIsSigned() ? (
                <iframe src={'/EsignSuccess'} width="100%" style={{ border: 'none', minHeight: '300px' }} />
              ) : (
                <iframe src={esignUrl} width="100%" style={{ border: 'none', minHeight: '300px' }} />
              )}
            </div>
          </div>
          <div className="next-button-container">
            <Button fullWidth className="back-button-text" variant="text" onClick={() => setValue(0)}>
              Back
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="next-button-green"
              onClick={handleSignPoaNext}
              endIcon={<KeyboardArrowRightIcon />}
              disabled={!isNextButtonEnabled()}
            >
              Next
            </Button>
          </div>
        </CustomTabPanel>
      </Box>
    </>
  );
}
