import { useAPI } from '@/api/APIContext';
import { useBetaFeatures } from '@/custom-hooks/beta-feature/BetaFeatureContext';
import ReportChart from '@/pages/reports/ReportChart';
import ReportTable from '@/pages/reports/ReportTable';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalReportCard from '@/shared-components/ImportalReportCard/ImportalReportCard';
import { ImportalTableHandles } from '@/shared-components/ImportalTable/ImportalTable';

import FloatingTooltip from '@/shared-components/FloatingTooltip/FloatingTooltip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import SyncIcon from '@mui/icons-material/Sync';
import { IconButton, MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import { Report, ReportChartType } from 'common/interfaces/reports';
import React, { useEffect, useState } from 'react';

export interface Props {
  report: Report;
  onAdvancedClicked: (report: Report) => void;
}

export default function ReportDefault({ report, onAdvancedClicked }: Props) {
  const api = useAPI();
  const betaFeatures = useBetaFeatures();

  const [reportData, setReportData] = useState<any[]>([]);
  const importalTableRef = React.createRef<ImportalTableHandles>();

  const getReportData = () => {
    api
      .getReportData(report._id.toString())
      .then(({ data: reportData }) => {
        setReportData(reportData);
      })
      .catch((err) => {
        console.error('error getting report data');
        console.error(err);
      });
  };

  useEffect(() => {
    getReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Menu handlers
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Action handlers
  const onClickReRunReport = () => {
    api
      .runReport(report._id.toString())
      .then(() => {})
      .catch((err) => {
        console.error('error running report');
        console.error(err);
      });
  };

  const onClickDownloadReportData = async () => {
    if (!importalTableRef.current) return;

    const tableConfig = importalTableRef.current.getTableConfig();
    try {
      const response = await api.downloadReportData(report._id.toString(), tableConfig);
      const data = response.data;
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${report.name || 'report'}.csv`;
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  const onClickDeleteReport = () => {
    api
      .deleteReport(report._id.toString())
      .then(() => {
        // TODO: refresh or remove this report from a list in parent
      })
      .catch((err) => {
        console.error('error deleting report');
        console.error(err);
      });
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px', marginBottom: '16px' }}>
        <FloatingTooltip title="Re-run Report" placement="top">
          <IconButton aria-label="rerun-report" onClick={onClickReRunReport} sx={{ color: '#525256' }}>
            <SyncIcon sx={{ fontSize: '25px' }} />
          </IconButton>
        </FloatingTooltip>
        <FloatingTooltip title="Download Report" placement="top">
          <IconButton aria-label="download-report" onClick={onClickDownloadReportData} sx={{ color: '#525256' }}>
            <DownloadIcon sx={{ fontSize: '25px' }} />
          </IconButton>
        </FloatingTooltip>
        <ImportalPrimaryButton
          style={{ width: '130x', borderRadius: '8px', height: '40px', marginLeft: '5px', fontSize: '13px' }}
          onClick={(e) => e && handleMenuClick(e)}
          text="Actions"
          endIcon={<ArrowDropDownIcon />}
        />
      </div>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            onClickDeleteReport();
            handleMenuClose();
          }}
        >
          Delete Report
        </MenuItem>
        {betaFeatures && (
          <MenuItem
            onClick={() => {
              onAdvancedClicked(report);
              handleMenuClose();
            }}
          >
            Advanced View
          </MenuItem>
        )}
      </Menu>
      {report.reportConfig?.chartConfig && (
        <>
          {report.reportConfig.chartConfig.chartType !== ReportChartType.NO_CHART && (
            <div>
              <ImportalReportCard title="" viewFullReport={false}>
                <ReportChart chartConfig={report.reportConfig.chartConfig} data={reportData} />
              </ImportalReportCard>
              <br />
            </div>
          )}
          <ReportTable
            ref={importalTableRef}
            data={reportData}
            columnConfig={report.reportConfig.columns}
            jsonataExpression={report.reportConfig.dataTransformationExpression}
          />
        </>
      )}
    </>
  );
}
