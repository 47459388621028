import { useAPI } from '@/api/APIContext';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import ImportalPage from '@/shared-components/ImportalPage/ImportalPage';
import { Report } from 'common/interfaces/reports';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ReportDefault from './ReportDefault';

export default function ReportPage() {
  const { reportId } = useParams<{ reportId: string }>();
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [report, setReport] = useState<Report>();
  const location = useLocation();
  const api = useAPI();
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const reportId = pathParts[pathParts.length - 1];
    if (!reportId) return;
    setLoadingText('Loading report...');
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getReport(reportId)
      .then(({ data }) => {
        setReport(data);
        setSuccessText('Successfully retrieved report details');
        setLoadingStatus(LoadingStatus.SUCCESS);
      })
      .catch((err) => {
        console.error('error getting report');
        console.error(err);
        setErrorText('Error retrieving report details');
        setLoadingStatus(LoadingStatus.ERROR);
      });

    return () => {
      document.title = 'Importal';
    };
  }, [api, reportId]);

  function renderReportSubheader(report: Report) {
    const chartDesc = report.reportConfig?.chartConfig?.chartDescriptor ?? 'No chart descriptor';
    const lastRunDate = report.lastRun ? moment(report.lastRun.toString()).format('MMMM Do, YYYY.') : 'N/A';
    const startDate = report.reportConfig?.dateRangeConfig?.startDate
      ? moment(report.reportConfig.dateRangeConfig.startDate).format('MMMM Do, YYYY')
      : null;
    const endDate = report.reportConfig?.dateRangeConfig?.endDate
      ? moment(report.reportConfig.dateRangeConfig.endDate).format('MMMM Do, YYYY')
      : null;

    return `${chartDesc} Last ran on ${lastRunDate} \n ${startDate ? `Date Range: ${startDate}` : ''} ${endDate ? `- ${endDate}` : ''}`;
  }

  return (
    <ImportalPage
      loadingStatus={loadingStatus}
      loadingText={loadingText}
      successText={successText}
      errorText={errorText}
      header={report?.name}
      /* If `report` exists, we show the subheader. Otherwise, show an empty string. */
      subheader={report ? renderReportSubheader(report) : ''}
    >
      {report && <ReportDefault report={report} onAdvancedClicked={() => {}} />}
    </ImportalPage>
  );
}
