export const getEntryNumericSuffix = (refNumber) => {
  if (refNumber == null || refNumber === '') {
    return NaN; // Handle null or empty string case
  }
  const numericSuffix = parseInt(refNumber.split('-')[1]);
  return isNaN(numericSuffix) ? NaN : numericSuffix; // Handle invalid numeric suffix case
};

export const defaultEntryTableSort = (a, b) => {
  const suffixA = getEntryNumericSuffix(a.refNumber);
  const suffixB = getEntryNumericSuffix(b.refNumber);

  // Handle NaN cases
  if (isNaN(suffixA) && isNaN(suffixB)) {
    return 0;
  } else if (isNaN(suffixA)) {
    return 1;
  } else if (isNaN(suffixB)) {
    return -1;
  }

  // Sort UNLD prefix reference numbers by suffix numerically
  if (a.refNumber.startsWith('UNLD') && b.refNumber.startsWith('UNLD')) {
    return suffixA - suffixB;
  }

  // Sort PORT prefix reference numbers by suffix numerically
  if (a.refNumber.startsWith('PORT') && b.refNumber.startsWith('PORT')) {
    return suffixA - suffixB;
  }

  // UNLD prefix entries come first
  return a.refNumber.startsWith('UNLD') ? -1 : 1;
};

export const buildEntryNumberNetchbUrl = (entryNumber: string): string => {
  // Extract parts of the entry number
  const filerCode = entryNumber.slice(0, 3);
  const entryNo = entryNumber.slice(4, -2);

  const netchbBaseURL = process.env.REACT_APP_NETCHB_URL || 'https://www.netchb.com';

  // Construct the URL
  return `${netchbBaseURL}/app/entry/viewEntry.do?filerCode=${filerCode}&entryNo=${entryNo}`;
};
