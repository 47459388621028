import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '@/api/APIContext';
import { formatPhoneNumber } from '@/utils/formatPhoneNumber';
import WelcomeHeader from '@/shared-components/WelcomeHeader/WelcomeHeader';
import SignUpFormFields from './SignUpFormFields';
import { handleFormChange, handlePhoneNumberChange, handleTermsConditionsChange, registerUser } from './formHandlers';
import './SignupForm.css';

export default function SignupForm() {
  const navigate = useNavigate();
  const api = useAPI();
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [termsConditions, setTermsConditions] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailError, setEmailError] = useState('');
  const [hidePassword, setHidePassword] = useState(true);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userToSubmit = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      phone: formatPhoneNumber(data.get('phone')),
      password: data.get('password'),
    };

    registerUser(api, userToSubmit, setIsLoading, setErrorText, navigate);
  };

  return (
    <Box sx={{ color: 'white' /* or any styling you want */ }}>
      <SignUpFormFields
        handleFormChange={(event) => handleFormChange(event, setEmailError)}
        handleSubmit={handleSubmit}
        handlePhoneNumberChange={(event) => handlePhoneNumberChange(event, setPhoneNumber)}
        handleTermsConditionsChange={(event) => handleTermsConditionsChange(event, setTermsConditions)}
        setHidePassword={setHidePassword}
        hidePassword={hidePassword}
        phoneNumber={phoneNumber}
        emailError={emailError}
        isLoading={isLoading}
        termsConditions={termsConditions}
        errorText={errorText}
      />
    </Box>
  );
}
