import { LegendPositionType } from 'common/interfaces/reports';
import * as d3 from 'd3';
import React from 'react';

interface ImportalChartLegendProps {
  keys: string[];
  colorScale: d3.ScaleOrdinal<string, string>;
  legendPosition: LegendPositionType;
  styles?: React.CSSProperties; // Renamed prop for custom styles
}

export function ImportalChartLegend({
  keys,
  colorScale,
  legendPosition,
  styles = {}, // Default to an empty object
}: ImportalChartLegendProps) {
  const isHorizontalLegend = legendPosition === LegendPositionType.TOP || legendPosition === LegendPositionType.BOTTOM;

  const ulStyle: React.CSSProperties = {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: isHorizontalLegend ? 'row' : 'column',
    flexWrap: isHorizontalLegend ? 'wrap' : 'nowrap',
    ...styles, // Merge custom styles with default styles
    color: '#525256',
    fontSize: '13px',
  };

  return (
    <ul aria-hidden="true" role="presentation" style={ulStyle}>
      {keys.map((d, i) => (
        <li
          key={`legend-${i}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: isHorizontalLegend ? '12px' : '0px',
            marginBottom: '8px',
          }}
        >
          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: colorScale(d),
              marginRight: '8px',
              fontSize: '8px',
              color: '#525256',
            }}
          />
          {d}
        </li>
      ))}
    </ul>
  );
}
