import React, { useEffect, useState } from 'react';
import { Box, Card, IconButton, Typography } from '@mui/material';
import { AutocompleteWithEditMode, TextFieldWithEditMode } from '@/broker-app/pages/shipments/EntryPrepMisc';
import { EntryImporterInformation } from 'common/interfaces/entry';
import { useAPI } from '@/api/APIContext';
import { Link, LinkOff } from '@mui/icons-material';
import { Types } from 'mongoose';

export interface EntryImporterInformationCardProps {
  entryImporterInformation: EntryImporterInformation;
  onEntryImporterInformationChanged: (arg0: EntryImporterInformation) => void;
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
  header: {
    fontWeight: 'bold' as const,
    color: 'black',
    fontSize: '16px',
    paddingBottom: '2px',
    borderBottom: '1px solid #D1D1D1',
    marginBottom: '8px',
  },
  fieldLabel: {
    fontWeight: 'bold' as const,
    fontSize: '12px',
    color: 'black',
    marginRight: '16px',
  },
  fieldValue: {
    fontSize: '12px',
    color: '#525256',
  },
  sectionContainer: {
    marginBottom: '24px',
    flex: 1,
  },
  businessRow: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'baseline',
    marginBottom: '8px',
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'baseline',
    marginBottom: '8px',
  },
};

interface ImporterOrConsignee {
  name?: string;
  ein: string;
  business?: Types.ObjectId;
}

export default function EntryImporterInformationCard({
  entryImporterInformation,
  onEntryImporterInformationChanged,
}: EntryImporterInformationCardProps) {
  const api = useAPI();

  const [loading, setLoading] = useState(false);
  const [importerOfRecordOptions, setImporterOfRecordOptions] = useState<Array<ImporterOrConsignee>>([]);

  const [importerIsFreeText, setImporterIsFreeText] = useState(false);
  const [consigneeIsFreeText, setConsigneeIsFreeText] = useState(false);

  useEffect(() => {
    setImporterIsFreeText(!Boolean(entryImporterInformation.importerOfRecord?.business));
    setConsigneeIsFreeText(!Boolean(entryImporterInformation.consignee?.business));
  }, [entryImporterInformation]);

  useEffect(() => {
    api
      .getAllBusinesses()
      .then(({ data }) => {
        const simplifiedBusinesses = data.map((business) => ({
          name: business.name,
          ein: business.ein || '',
          business: business._id,
        }));
        setImporterOfRecordOptions(simplifiedBusinesses);
      })
      .catch((err) => {
        console.error('error getting businesses for Importer Information Card', err);
      });
  }, []);

  return (
    <Card sx={styles.container}>
      <Typography sx={styles.header}>Entity Info / Importer Information</Typography>

      {/* If entryImporterInformation is empty or null */}
      {!entryImporterInformation && <div> no cargo release parties info </div>}

      {entryImporterInformation && (
        <Box style={{ display: 'flex' }}>
          <Box sx={styles.sectionContainer}>
            <div style={{ display: 'flex' }}>
              <Typography sx={styles.fieldLabel} style={{ fontSize: '14px', marginBottom: '8px' }}>
                Importer Of Record
              </Typography>
              <IconButton
                sx={{
                  paddingTop: '0px',
                  paddingLeft: '0px',
                }}
                onClick={() => {
                  setImporterIsFreeText(!importerIsFreeText);
                }}
              >
                {importerIsFreeText ? <LinkOff sx={{ fontSize: '14px' }} /> : <Link sx={{ fontSize: '14px' }} />}
              </IconButton>
            </div>

            <Box sx={styles.businessRow}>
              <Typography sx={styles.fieldLabel}>{importerIsFreeText ? 'EIN:' : 'Business:'}</Typography>
              {importerIsFreeText && (
                <TextFieldWithEditMode
                  value={entryImporterInformation.importerOfRecord?.ein || ''}
                  onSaveClicked={(ein) => {
                    onEntryImporterInformationChanged({
                      ...entryImporterInformation,
                      //@ts-ignore
                      importerOfRecord: {
                        ein: ein,
                      },
                    });
                  }}
                  viewModeProps={{ fontSize: '12px' }}
                />
              )}
              {!importerIsFreeText && (
                <AutocompleteWithEditMode<ImporterOrConsignee>
                  value={entryImporterInformation.importerOfRecord || null}
                  options={importerOfRecordOptions}
                  getOptionLabel={(option) => `${option.name}`}
                  onSaveClicked={(importerOfRecord) => {
                    if (importerOfRecord) {
                      onEntryImporterInformationChanged({
                        ...entryImporterInformation,
                        importerOfRecord,
                      });
                    } else {
                      const updatedImporterInformation = { ...entryImporterInformation };
                      delete updatedImporterInformation['importerOfRecord'];
                      onEntryImporterInformationChanged(updatedImporterInformation);
                    }
                  }}
                  autocompleteProps={{
                    renderOption: (props, option: ImporterOrConsignee) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    ),
                  }}
                  viewModeProps={styles.fieldValue}
                  viewElementOverride={(value) => (
                    <>
                      {!entryImporterInformation.importerOfRecord?.business && (
                        <div style={{ fontSize: '12px' }}>
                          {entryImporterInformation.importerOfRecord?.name || 'Not Set'}
                        </div>
                      )}
                      {entryImporterInformation.importerOfRecord?.business && (
                        <a
                          style={{ color: 'inherit', fontSize: '12px' }}
                          target="_blank"
                          href={`/broker/dashboard/businesses/${entryImporterInformation.importerOfRecord?.business}`}
                        >
                          {entryImporterInformation.importerOfRecord?.name}
                        </a>
                      )}
                    </>
                  )}
                />
              )}
            </Box>
            {!importerIsFreeText && (
              <Box sx={styles.businessRow}>
                <Typography sx={styles.fieldLabel}>EIN:</Typography>
                <div style={{ fontSize: '12px' }}>{entryImporterInformation.importerOfRecord?.ein}</div>
              </Box>
            )}
          </Box>

          {/* CONSIGNEE SECTION */}
          <Box sx={styles.sectionContainer}>
            <div style={{ display: 'flex' }}>
              <Typography sx={styles.fieldLabel} style={{ fontSize: '14px', marginBottom: '8px' }}>
                Consignee
              </Typography>
              <IconButton
                sx={{
                  paddingTop: '0px',
                  paddingLeft: '0px',
                }}
                onClick={() => {
                  setConsigneeIsFreeText(!consigneeIsFreeText);
                }}
              >
                {consigneeIsFreeText ? <LinkOff sx={{ fontSize: '14px' }} /> : <Link sx={{ fontSize: '14px' }} />}
              </IconButton>
            </div>

            <Box sx={styles.businessRow}>
              <Typography sx={styles.fieldLabel}>{consigneeIsFreeText ? 'EIN:' : 'Business:'}</Typography>
              {consigneeIsFreeText && (
                <TextFieldWithEditMode
                  value={entryImporterInformation.consignee?.ein || ''}
                  onSaveClicked={(ein) => {
                    onEntryImporterInformationChanged({
                      ...entryImporterInformation,
                      //@ts-ignore
                      consignee: {
                        ein: ein,
                      },
                    });
                  }}
                  viewModeProps={{ fontSize: '12px' }}
                />
              )}
              {!consigneeIsFreeText && (
                <AutocompleteWithEditMode<ImporterOrConsignee>
                  value={entryImporterInformation.consignee || null}
                  options={importerOfRecordOptions}
                  getOptionLabel={(option) => `${option.name}`}
                  onSaveClicked={(consignee) => {
                    if (consignee) {
                      onEntryImporterInformationChanged({
                        ...entryImporterInformation,
                        consignee,
                      });
                    } else {
                      const updatedImporterInformation = { ...entryImporterInformation };
                      delete updatedImporterInformation['consignee'];
                      onEntryImporterInformationChanged(updatedImporterInformation);
                    }
                  }}
                  autocompleteProps={{
                    renderOption: (props, option: ImporterOrConsignee) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    ),
                  }}
                  viewModeProps={styles.fieldValue}
                  viewElementOverride={(value) => (
                    <>
                      {!entryImporterInformation.consignee?.business && (
                        <div style={{ fontSize: '12px' }}>{entryImporterInformation.consignee?.name || 'Not Set'}</div>
                      )}
                      {entryImporterInformation.consignee?.business && (
                        <a
                          style={{ color: 'inherit', fontSize: '12px' }}
                          target="_blank"
                          href={`/broker/dashboard/businesses/${entryImporterInformation.consignee?.business}`}
                        >
                          {entryImporterInformation.consignee?.name}
                        </a>
                      )}
                    </>
                  )}
                />
              )}
            </Box>
            {!consigneeIsFreeText && (
              <Box sx={styles.businessRow}>
                <Typography sx={styles.fieldLabel}>EIN:</Typography>
                <div style={{ fontSize: '12px' }}>{entryImporterInformation.consignee?.ein}</div>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Card>
  );
}
