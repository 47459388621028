import React from 'react';
import { Box, CircularProgress, Grid, InputAdornment, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';

interface SignUpFormFieldsProps {
  handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handlePhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTermsConditionsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setHidePassword: (hide: boolean) => void;
  hidePassword: boolean;
  phoneNumber: string;
  emailError: string;
  isLoading: boolean;
  termsConditions: boolean;
  errorText: string;
}

// Style object for white background, black text, and white label
const signupFormFieldStyle = {
  // Make the input background white
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    '& fieldset': {
      // Optionally change border color. E.g. black or some other color for better contrast
      borderColor: 'white',
    },
    '&:hover fieldset': {
      // Hover border color
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      // Focus border color
      borderColor: 'white',
    },
  },

  // The typed text is black
  '& .MuiInputBase-input': {
    color: 'black',
  },

  // The label text is white
  '& .MuiFormLabel-root': {
    color: 'white',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: 'white',
  },
};

const SignUpFormFields: React.FC<SignUpFormFieldsProps> = ({
  handleFormChange,
  handleSubmit,
  handlePhoneNumberChange,
  handleTermsConditionsChange,
  setHidePassword,
  hidePassword,
  phoneNumber,
  emailError,
  isLoading,
  termsConditions,
  errorText,
}) => {
  // Determine if the submit button should be disabled
  const isSubmitDisabled = isLoading || !termsConditions;

  return (
    <Box component="form" onChange={handleFormChange} onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <div className="sign-up-and-features-container">
        <div className="sign-up-container">
          <div className="name-row">
            <div className="signup-form-field-container">
              <div className="business-form-field-label">
                <Typography sx={{ fontWeight: '600', color: 'white' }}>First Name</Typography>
              </div>
              <div>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  size="small"
                  id="firstName"
                  autoFocus
                  sx={signupFormFieldStyle}
                />
              </div>
            </div>
            <div className="signup-form-field-container">
              <div className="business-form-field-label">
                <Typography sx={{ fontWeight: '600', color: 'white' }}>Last Name</Typography>
              </div>
              <div>
                <TextField
                  required
                  fullWidth
                  size="small"
                  id="lastName"
                  name="lastName"
                  autoComplete="family-name"
                  sx={signupFormFieldStyle}
                />
              </div>
            </div>
          </div>

          <div className="email-password-row">
            <div className="signup-form-field-container">
              <div className="business-form-field-label">
                <Typography sx={{ fontWeight: '600', color: 'white' }}>Email</Typography>
              </div>
              <div>
                <TextField
                  error={!!emailError}
                  helperText={emailError}
                  required
                  fullWidth
                  size="small"
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  sx={signupFormFieldStyle}
                />
              </div>
            </div>
          </div>
          <div className="email-password-row">
            <div className="signup-form-field-container">
              <div className="business-form-field-label">
                <Typography sx={{ fontWeight: '600', color: 'white' }}>Password</Typography>
              </div>
              <TextField
                type={hidePassword ? 'password' : 'text'}
                required
                fullWidth
                size="small"
                id="password"
                name="password"
                autoComplete="password"
                sx={signupFormFieldStyle}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={hidePassword ? 'Show Password' : 'Hide Password'}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setHidePassword(!hidePassword)}
                          edge="end"
                        >
                          {hidePassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="email-password-row">
            <div className="signup-form-field-container">
              <div className="business-form-field-label">
                <Typography sx={{ fontWeight: '600', color: 'white' }}>Business Phone</Typography>
              </div>
              <div>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  type="tel"
                  id="phone"
                  size="small"
                  onChange={handlePhoneNumberChange}
                  value={phoneNumber}
                  autoComplete="business-phone"
                  sx={signupFormFieldStyle}
                />
              </div>
            </div>
          </div>
          <div className="terms-conditions-container">
            <input type="checkbox" id="acceptTerms" onChange={handleTermsConditionsChange} checked={termsConditions} />
            <Typography sx={{ fontSize: '12px', color: 'white' }}>
              By signing up for an account, you agree to our{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.importal.com/terms-of-use">
                terms and conditions
              </a>{' '}
              and{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.importal.com/privacy-policy">
                privacy policy.
              </a>
            </Typography>
          </div>

          <Grid container justifyContent="center">
            {!isSubmitDisabled && (
              <ImportalPrimaryButton
                type="submit"
                text="Sign Up"
                onClick={() => handleSubmit}
                disabled={isSubmitDisabled}
                endIcon={isLoading ? <CircularProgress color="success" size={20} /> : null}
                style={{
                  marginTop: '24px',
                  fontWeight: 700,
                  width: '100%',
                }}
              />
            )}
            {isLoading && <CircularProgress color="success" size={24} sx={{ marginTop: '24px' }} />}
          </Grid>

          {errorText && (
            <Grid className="error-text" sx={{ color: 'red', mt: 2 }}>
              {errorText}
            </Grid>
          )}
        </div>
      </div>
    </Box>
  );
};

export default SignUpFormFields;
