import * as d3 from 'd3';
import { useMemo } from 'react';

interface Props {
  axisLabel: string;
  categories: string[];
  displayCategories?: string[];
  width: number;
}

export const CategoricalXAxis = ({ axisLabel, categories = [], displayCategories, width }: Props) => {
  // 1) Compute ticks: each tick is centered in its band
  const ticks = useMemo(() => {
    const xScale = d3.scaleBand().range([0, width]).domain(categories).padding(0.1);

    return categories.map((category) => ({
      category,
      xOffset: xScale(category)! + xScale.bandwidth() / 2,
    }));
  }, [categories, width]);

  return (
    <>
      {/* A simple axis line from (0,0) to (width,0) */}
      <path d={`M 0 0 H ${width}`} fill="none" stroke="#E5E5EF" />

      {/* Tick labels, centered in each band */}
      {ticks.map(({ category, xOffset }, index) => (
        <g key={category} transform={`translate(${xOffset}, 0)`}>
          <text
            fill="#525256"
            style={{
              fontSize: '13px',
              textAnchor: 'middle',
              // Move the label down from y=0 by ~16px
              dominantBaseline: 'hanging',
            }}
            y={16}
          >
            {displayCategories?.[index] ?? category}
          </text>
        </g>
      ))}

      {/* Axis label, centered below the ticks */}
      <text
        fill="#525256"
        textAnchor="middle"
        dominantBaseline="hanging"
        // Position in the middle horizontally, and ~40px below 0
        x={width / 2}
        y={40}
        style={{ fontSize: '14px', fontWeight: '600' }}
      >
        {axisLabel}
      </text>
    </>
  );
};
