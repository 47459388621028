import { useAPI } from '@/api/APIContext';
import CountryOfOrigin from '@/shared-components/ComplianceTools/CountryOfOrigin';
import FirstStepContainer from '@/shared-components/ComplianceTools/FirstStepContainer';
import ModeOfTransportation from '@/shared-components/ComplianceTools/ModeOfTransportation';
import { QuantityTotals } from '@/shared-components/ComplianceTools/QuantityTotals';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import { Code } from 'common/interfaces/code';
import { Input, TransportationType, WeightUnit } from 'common/interfaces/complianceSummary';
import { ISFDetail, Shipment, ShipmentWithDocuments } from 'common/interfaces/shipment';
import { getUnitOfMeasureForCode, quantityDataRequiredForDuty } from 'common/utilities/quantityDataRequiredForDuty';
import React, { useState } from 'react';
import ImportDocument from '../import-documents/ImportDocument';
import ISFDetails from '../isf-details/ISFDetails';
import ReferenceNumber from '../reference-number/ReferenceNumber';
import BackdropLoader from '../snackbar-loader/SnackbarLoader';
import './CustomsClearance.css';

const steps = [
  'Do you have a reference number?',
  'Select Transportation Type',
  'HTS code',
  'Country of Origin',
  'ISF Details',
  'Import Documents',
  'Ready, set, clear!',
];

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  getShipments: () => void;
}

export default function CustomsClearance(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const api = useAPI();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  //@ts-ignore
  const initialShipment: Shipment = {
    input: {
      htsno: '',
      countryOfOrigin: '',
    },
  };
  const [customsClearance, setCustomsClearance] = useState<ShipmentWithDocuments>(initialShipment);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  function handleNext() {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  }

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const submitCustomsClearance = () => {
    api
      .createShipment(customsClearance)
      .then(() => {
        handleComplete();
      })
      .catch((err) => {
        console.error('error creating shipment');
        console.error(err);
      });
  };

  const getSteps = (): React.JSX.Element[] => {
    return [
      <ReferenceNumber
        referenceNumber={customsClearance.referenceNumber || ''}
        onReferenceNumberSet={(referenceNumber?: string) => {
          setCustomsClearance({ ...customsClearance, referenceNumber });
        }}
        onNext={handleComplete}
      />,
      <ModeOfTransportation
        modeOfTransportation={
          customsClearance.input?.transportationType && TransportationType[customsClearance.input.transportationType]
        }
        onModeOfTransportationSelected={(tranportationType: TransportationType) => {
          setCustomsClearance({
            ...customsClearance,
            input: {
              ...customsClearance.input,
              transportationType: tranportationType,
            } as Input,
          });
        }}
        onNext={handleComplete}
        onBack={handleBack}
      />,
      <FirstStepContainer
        htsCode={customsClearance.htsClassification}
        setHTSCode={(htsCode?: Code) => {
          const quantityInfo = {};
          if (htsCode && quantityDataRequiredForDuty(htsCode)) {
            // @ts-ignore
            quantityInfo.totalUnitsMeasureOfQuantity = getUnitOfMeasureForCode(htsCode)!;
          }

          setCustomsClearance({
            ...customsClearance,
            input: {
              ...customsClearance.input,
              htsno: htsCode?.htsno || '',
              // @ts-ignore (ignoring string to decimal)
              totals: {
                ...customsClearance.input?.totals,
                ...quantityInfo,
              },
            },
            htsClassification: htsCode,
          });
        }}
        totalWeight={customsClearance.input?.totals?.totalWeight?.toString()}
        onTotalWeightSelected={(totalWeight?: string) => {
          setCustomsClearance({
            ...customsClearance,
            input: {
              ...customsClearance.input,
              totals: {
                ...customsClearance.input?.totals,
                // @ts-ignore (ignoring string to decimal)
                totalWeight: totalWeight,
                totalWeightUnits: WeightUnit.KG,
              },
            },
          });
        }}
        quantityInfo={customsClearance.input?.totals}
        onQuantityInfoSelected={(quantityInfo?: QuantityTotals) => {
          console.log('is this interfereing?');
          setCustomsClearance({
            ...customsClearance,
            input: {
              ...customsClearance.input,
              // @ts-ignore (ignoring string to decimal)
              totals: {
                ...customsClearance.input?.totals,
                ...quantityInfo,
              },
            },
          });
        }}
        onNext={handleComplete}
      />,
      <CountryOfOrigin
        selectedCountryCode={customsClearance.input?.countryOfOrigin}
        onCountryCodeSelected={(countryCode?: string) => {
          setCustomsClearance({
            ...customsClearance,
            input: {
              ...customsClearance.input,
              countryOfOrigin: countryCode || '',
            } as Input,
          });
        }}
        onNext={handleComplete}
        onBack={handleBack}
      />,
      <ISFDetails
        isfDetails={customsClearance.isfDetails}
        onISFDetailsSelected={(isfDetails?: ISFDetail) => {
          setCustomsClearance({ ...customsClearance, isfDetails: isfDetails });
        }}
        onNext={handleComplete}
        onBack={handleBack}
      />,
      <ImportDocument
        customsClearanceDocuments={customsClearance.documents}
        // onSetCustomsClearanceDocuments={setCustomsClearance({})}
        onSubmit={submitCustomsClearance}
      />,
      <FinalStep handleEvent={handleComplete} />,
    ];
  };

  return (
    <>
      {allStepsCompleted() ? (
        onClose()
      ) : (
        <Dialog className="mainDialog" maxWidth="lg" fullWidth={true} onClose={onClose} open={open}>
          <DialogTitle>
            Customs Clearance
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="step-title-container">
              <div>
                Step {activeStep + 1}/ {getSteps().length}
              </div>
            </div>
            {(activeStep !== 0 || isLoading) && <BackdropLoader isLoading={isLoading} />}
            {getSteps()[activeStep]}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

function FinalStep({ handleEvent }) {
  const theme = useTheme();

  return (
    <Box>
      <div className="header-question">
        <Typography>Ready, set, clear!</Typography>
      </div>
      <div className="unlading-descriptive-text">
        Thanks for requesting customs clearance, you should see your shipment on the dashboard and shipments pages. One
        of our import professionals will reach out shortly with next steps.
      </div>
      <div className="next-button-container">
        <Button fullWidth variant="contained" onClick={() => handleEvent()} className="next-button-green">
          Close
        </Button>
      </div>
    </Box>
  );
}
